import { faBoxesStacked, faCoins, faFileInvoice, faHandHoldingDollar, faHome, faBiohazard, faLayerGroup, faPeopleGroup, faTrailer, faUser, faCircleMinus, faProjectDiagram, faClipboardList, faSheetPlastic, faGears, faScrewdriverWrench, faFileMedical, faClipboardCheck, faRectangleXmark, faFilePen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategoryIcon from '@mui/icons-material/Category';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useTranslation } from "react-i18next";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import SecurityIcon from '@mui/icons-material/Security';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PublicIcon from '@mui/icons-material/Public';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import PercentIcon from '@mui/icons-material/Percent';
import GroupIcon from '@mui/icons-material/Group';
import CarRentalIcon from '@mui/icons-material/CarRental';
import PaymentsIcon from '@mui/icons-material/Payments';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import EmailIcon from '@mui/icons-material/Email';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import TranslateIcon from '@mui/icons-material/Translate';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import KeyIcon from '@mui/icons-material/Key';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import InventoryIcon from '@mui/icons-material/Inventory';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import BadgeIcon from '@mui/icons-material/Badge';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import LockIcon from '@mui/icons-material/Lock';
import GavelIcon from '@mui/icons-material/Gavel';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import StraightenIcon from '@mui/icons-material/Straighten';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MediationIcon from '@mui/icons-material/Mediation';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import MenuIcon from '@mui/icons-material/Menu';
import AssignmentIcon from '@mui/icons-material/Assignment';

interface resourceInterface {
    component?: string;
    icon?: JSX.Element;
    translation: string;
}

const ResourceEntities = () => {
    const [t] = useTranslation("global");

    const resourcesMenu = new Map<String, resourceInterface>([
        ["Inicio", {
            component: "/home", icon: <FontAwesomeIcon icon={faHome} />, translation: t("generic.menu.home")
        }],
        ["Home", {
            component: "/home", icon: <FontAwesomeIcon icon={faHome} />, translation: t("generic.menu.home")
        }],
        ["ConfigurationMenu", { icon: <FontAwesomeIcon icon={faScrewdriverWrench} />, translation: t("generic.menu.menu-configuration") }],
        ["Menu", { icon: <MenuIcon />, translation: t("generic.menu.menu") }],
        ["SideBarMenu", { icon: <MenuIcon />, translation: t("generic.menu.menu") }],
        ["EntyCompanies", { component: "/companies", icon: <BusinessIcon />, translation: t("generic.menu.companies") }],
        ["EntyUsers", { component: "/users", icon: <FontAwesomeIcon icon={faUser} />, translation: t("generic.menu.users") }],
        ["SalesMenu", { icon: <AttachMoneyIcon />, translation: t("generic.menu.menu-sales") }],
        ["ItemsMenu", { icon: <FontAwesomeIcon icon={faBoxesStacked} />, translation: t("generic.menu.items") }],
        ["Application", { icon: <PhonelinkSetupIcon />, translation: t("generic.menu.menu-application") }],
        ["ApplicationMenu", { icon: <PhonelinkSetupIcon />, translation: t("generic.menu.menu-application") }],
        ["EntyItems", { component: "/items", icon: <ListAltIcon />, translation: t("generic.menu.enty-items") }],
        ["EntyItemTypes", { component: "/itemtypes", icon: <CategoryIcon />, translation: t("generic.menu.enty-item-types") }],
        ["EntyItemFamilies", { component: "/itemfamilies", icon: <FontAwesomeIcon icon={faLayerGroup} />, translation: t("generic.menu.enty-item-families") }],
        ["CustomersMenu", { icon: <FontAwesomeIcon icon={faPeopleGroup} />, translation: t("generic.menu.customers") }],
        ["EntyCustomers", { component: "/customers", icon: <FontAwesomeIcon icon={faPeopleGroup} />, translation: t("generic.menu.enty-customers") }],
        ["PurchasesMenu", { icon: <ShoppingCartIcon />, translation: t("generic.menu.menu-purchases") }],
        ["SuppliersMenu", { icon: <LocalShippingIcon />, translation: t("generic.menu.suppliers") }],
        ["EntySuppliers", { component: "/suppliers", icon: <LocalShippingIcon />, translation: t("generic.menu.enty-suppliers") }],
        ["EntyWarehouses", { component: "/warehouses", icon: <WarehouseIcon />, translation: t("generic.menu.enty-warehouses") }],
        ["WarehousesMenu", { icon: <WarehouseIcon />, translation: t("generic.menu.enty-warehouses") }],
        ["SecurityMenu", { icon: <SecurityIcon />, translation: t("generic.menu.menu-security") }],
        ["GeneralMenu", { icon: <DisplaySettingsIcon />, translation: t("generic.menu.menu-general") }],
        ["LocationsGeneralMenu", { icon: <LocationOnIcon />, translation: t("generic.menu.locations") }],
        ["EntyCountries", { component: "/countries", icon: <PublicIcon />, translation: t("generic.menu.enty-countries") }],
        ["EntyStates", { component: "/states", icon: <MapIcon />, translation: t("generic.menu.enty-states") }],
        ["EntyCities", { component: "/cities", icon: <LocationCityIcon />, translation: t("generic.menu.enty-cities") }],
        ["EntyCurrencies", { component: "/currencies", icon: <FontAwesomeIcon icon={faCoins} />, translation: t("generic.menu.enty-currencies") }],
        ["EntyTaxes", { component: "/taxes", icon: <PercentIcon />, translation: t("generic.menu.enty-taxes") }],
        ["EntyPaymentModes", { component: "/paymentmodes", icon: <PaymentsIcon />, translation: t("generic.menu.enty-paymentmodes") }],
        ["EntyPaymentMethods", { component: "/paymentmethods", icon: <PaymentIcon />, translation: t("generic.menu.enty-paymentmethods") }],
        ["EntyCFDIsUsage", { component: "/cfdi", icon: <ReceiptIcon />, translation: t("generic.menu.enty-cfdis") }],
        ["EntyGroups", { component: "/groups", icon: <GroupIcon />, translation: t("generic.menu.enty-groups") }],
        ["EntyParameters", { component: "/parameters", icon: <FontAwesomeIcon icon={faGears} />, translation: t("generic.menu.enty-parameters") }],
        ["EntyConsecutives", { component: "/consecutives", icon: <MoveDownIcon />, translation: t("generic.menu.consecutives") }],
        ["EntySalesInvoices", { component: "/salesinvoices", icon: <ReceiptIcon />, translation: t("generic.menu.enty-sales-invoices") }],
        ["EntyProductServiceKeys", { component: "/productservicekeys", icon: <KeyIcon />, translation: t("generic.menu.productservicekeys") }],
        ["EntyUnits", { component: "/units", icon: <InventoryIcon />, translation: t("generic.menu.units") }],
        ["EntyTaxObjects", { component: "/taxobjects", icon: <RequestQuoteIcon />, translation: t("generic.menu.taxobjects") }],
        ["EntyCancellationReasons", { component: "/cancellationreasons", icon: <CancelScheduleSendIcon />, translation: t("generic.menu.cancellationreasons") }],
        ["EntyTaxRegimes", { component: "/taxregimes", icon: <BadgeIcon />, translation: t("generic.menu.enty-taxregimes") }],
        ["EntyChassis", { component: "/chassis", icon: <FontAwesomeIcon icon={faTrailer} />, translation: t("generic.menu.chassis") }],
        ["EntyTrailerTypes", { component: "/trailertypes", icon: <DirectionsBusIcon />, translation: t("generic.menu.trailertypes") }],
        ["FinancesGeneralMenu", { icon: <MonetizationOnIcon />, translation: t("generic.menu.menu-finances") }],
        ["SalesGeneralMenu", { icon: <FontAwesomeIcon icon={faHandHoldingDollar} />, translation: t("generic.menu.menu-sales") }],
        ["TransportationDocumentsGeneralMenu", { icon: <FontAwesomeIcon icon={faFileInvoice} />, translation: t("generic.menu.menu-transportationdocument") }],
        ["EntyCancelledSalesInvoices", { component: "/cancelledsalesinvoices", icon: <FontAwesomeIcon icon={faCircleMinus} />, translation: t("generic.menu.cancelledsalesinvoices") }],
        ["EntyVehicleConfigurations", { component: "/vehicleconfigurations", icon: <CarRentalIcon />, translation: t("generic.menu.vehicleconfigurations") }],
        ["EntyDriverTypes", { component: "/drivertypes", icon: <GroupIcon />, translation: t("generic.menu.drivertypes") }],
        ["EntyTransportations", { component: "/transportations", icon: <DirectionsBusIcon />, translation: t("generic.menu.transportations") }],
        ["EntyDrivers", { component: "/drivers", icon: <AirlineSeatReclineNormalIcon />, translation: t("generic.menu.drivers") }],
        ["EntyRetentions", { component: "/retentions", icon: <LockIcon />, translation: t("generic.menu.retentions") }],
        ["EntyINCOTERMs", { component: "/incoterms", icon: <GavelIcon />, translation: t("generic.menu.incoterms") }],
        ["EntyTariffFractions", { component: "/tarifffractions", icon: <PriceChangeIcon />, translation: t("generic.menu.tarifffractions") }],
        ["EntyPaymentConditions", { component: "/paymentconditions", icon: <EditCalendarIcon />, translation: t("generic.menu.enty-paymentconditions") }],
        ["EntyBankAccounts", { component: "/bankaccounts", icon: <AccountBalanceWalletIcon />, translation: t("generic.menu.bankaccounts") }],
        ["EntyCustomsKeyCodes", { component: "/customskeycodes", icon: <VpnKeyIcon />, translation: t("generic.menu.customskeycodes") }],
        ["EntyMeasureUnitConversions", { component: "/measureunitconversions", icon: <StraightenIcon />, translation: t("generic.menu.measureunitconversions") }],
        ["EntyPackagingTypes", { component: "/packagingtypes", icon: <Inventory2Icon />, translation: t("generic.menu.packagingtypes") }],
        ["EntyHazardousMaterialsKeys", { component: "/hazardousmaterialskeys", icon: <FontAwesomeIcon icon={faBiohazard} />, translation: t("generic.menu.hazardousmaterialskeys") }],
        ["ForeignTradesGeneralMenu", { icon: <PublicIcon />, translation: t("generic.menu.menu-foreigntrade") }],
        ["EntySalesInvoiceRelationshipTypes", { component: "/salesinvoicerelationshiptypes", icon: <MediationIcon />, translation: t("generic.menu.enty-salesinvoicerelationshiptypes") }],
        ["EntyReceiptsDetails", { component: "/receiptsdetails", icon: <ReceiptLongIcon />, translation: t("generic.menu.enty-receiptsdetails") }],
        ["EntyLanguages", { component: "/languages", icon: <TranslateIcon />, translation: t("generic.menu.languages") }],
        ["EntyMessagesMails", { component: "/messagesmails", icon: <EmailIcon />, translation: t("generic.menu.messagesmails") }],
        ["ProjectsMenu", { icon: <FontAwesomeIcon icon={faClipboardList} size="lg" />, translation: t("generic.menu.projects") }],
        ["EntyProjectTypes", { component: "/projecttypes", icon: <AccountTreeIcon />, translation: t("generic.menu.projecttypes") }],
        ["EntyProjects", { component: "/projects", icon: <ListAltIcon />, translation: t("generic.menu.enty-projects") }],
        ["EntyPurchaseInvoices", { component: "/purchaseinvoices", icon: <ReceiptIcon />, translation: t("generic.menu.enty-purchaseinvoices") }],
        ["EntyPaymentsDetails", { component: "/paymentsdetails", icon: <ReceiptLongIcon />, translation: t("generic.menu.enty-paymentsdetails") }],
        ["EntyAuthorizationPayments", { component: "/authorizationpayments", icon: <PriceCheckIcon />, translation: t("generic.menu.enty-authorizationpayments") }],
        ["EntyProjectsTemplate", { component: "/projectstemplate", icon: <FontAwesomeIcon icon={faSheetPlastic} size="lg" />, translation: t("generic.menu.entyc-projectstemplate") }],
        ["EntyResources", { component: "/resources", icon: <WidgetsIcon />, translation: t("generic.menu.resources") }],
        ["EntyDashboard", { component: "/dashboard", icon: <DashboardIcon />, translation: t("generic.menu.enty-dashboard") }],
        ["EntyOptionMenu", { component: "/optionmenu", icon: <MenuOpenIcon />, translation: t("generic.menu.optionsmenu") }],
        ["EntyProjectTaskDashboard", { component: "/projecttaskdashboard", icon: <AssignmentIcon />, translation: t("generic.menu.enty-projecttaskdashboard") }],
        ["EntyPermissions", { component: "/permissions", icon: <WorkspacePremiumIcon />, translation: t("generic.menu.enty-permissions") }],
        ["EntySalesPreorders", { component: "/salespreorders", icon: <FontAwesomeIcon icon={faFileMedical} />, translation: t("generic.menu.enty-salespreorders") }],
        ["EntyCancelledReceiptsDetails", { component: "/cancelledreceiptsdetails", icon: <FontAwesomeIcon icon={faRectangleXmark} />, translation: t("generic.menu.enty-cancelledreceiptsdetails") }],
        ["EntySalesOrders", { component: "/salesorders", icon: <FontAwesomeIcon icon={faClipboardCheck} />, translation: t("generic.menu.enty-salesorders") }],
        ["EntySalesShippingDocuments", { component: "/salesshippingdocuments", icon: <FontAwesomeIcon icon={faFilePen} />, translation: t("generic.menu.enty-salesshippingdocuments") }],
        ["EntyPurchasePreorders", { component: "/purchasepreorders", icon: <FontAwesomeIcon icon={faFileMedical} />, translation: t("generic.menu.enty-purchasepreorders") }],
        ["EntyPurchaseOrders", { component: "/purchaseorders", icon: <FontAwesomeIcon icon={faClipboardCheck} />, translation: t("generic.menu.enty-purchaseorders") }],
        ["EntyPurchaseShippingDocuments", { component: "/purchaseshippingdocuments", icon: <FontAwesomeIcon icon={faFilePen} />, translation: t("generic.menu.enty-purchaseshippingdocuments") }],
    ]);

    const GetResource = (resource: string) => {
        return resourcesMenu.get(resource);
    }

    return (
        GetResource
    )
}

export default ResourceEntities;