import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useEffect, useState } from "react";
import GenericPromises from "../../../../api/GenericPromises";
import { GridColDef, GridRenderCellParams, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { PurchaseInvoice } from "../../../../interfaces/Purchases/Invoices/purchaseInvoices";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { Header } from "../../../../components/Header";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import EmailIcon from '@mui/icons-material/Email';
import { useCompanyStore } from "../../../../store/AuthStore";
import { DialogSendEmails } from "../helpers/dialogSendEmails";
import { useGridPreferences } from "../../../../hooks/useGridPreferences";
import { usePurchaseInvoiceColumns } from "./columns";
import { useBase64 } from "../../../../hooks/useBase64";
import { useCurrencies } from "../../../../hooks/useCurrencies";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { useDates } from "../../../../hooks/useDates";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { ErrorDisplay } from "../../../../components/Commons/Error/ErrorDisplay";
import { error } from 'console';
import { AxiosError } from "axios";

export const TablePurchaseInvoices = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource } = GenericPromises();
  const { LoadPreferences } = useGridPreferences();
  const { utf8ToBase64 } = useBase64();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { TimeConverter } = useDates();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const { LoadExchangeRateHistory } = useCurrencies();
  const { columnsPurchaseinvoices } = usePurchaseInvoiceColumns();
  const { companyState } = useCompanyStore();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialogEmail, setOpenDialogEmail] = useState(false);
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [myPreferences, setMyPreferences] = useState({});
  const [purchaseInvoicesData, setPurchaseInvoicesData] = useState<GridRowsProp | undefined>(undefined);
  const [dataPurchaseInvoiceSelected, setDataPurchaseInvoiceSelected] = useState<GridRowsProp>([]);
  const [dataEmails, setDataEmails] = useState<any>([]);
  const [columns, setColumns] = useState<GridColDef<PurchaseInvoice>[]>(columnsPurchaseinvoices);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const invoiceDocumentStatusActiveId = 1;
  const invoiceDocumentStatusDeleteId = 2;
  const comboEnum = [
    { enum_id: 1, option_name: t("invoices.comboEnum.all"), route: `/purchaseinvoices` },
    { enum_id: 2, option_name: t("invoices.comboEnum.onlyactive"), route: `/purchaseinvoices?filter=${utf8ToBase64(`invoice_doc_status_id=${invoiceDocumentStatusActiveId}`)}` },
    { enum_id: 3, option_name: t("invoices.comboEnum.onlydelete"), route: `/purchaseinvoices?filter=${utf8ToBase64(`invoice_doc_status_id=${invoiceDocumentStatusDeleteId}`)}` },
  ];
  const [myEnumValue, setMyEnumValue] = useState(comboEnum[1]);

  const onSelectedRows = (rowsIds: any) => {
    let selectedPurchaseInvoices = rowsIds.map((element: number) => purchaseInvoicesData?.find((elementFind) => elementFind["purchase_invoice_id"] === element));
    setDataPurchaseInvoiceSelected(selectedPurchaseInvoices);
  };

  const onSendEmail = () => {
    let dataEmails: any = [];
    setDataEmails(dataPurchaseInvoiceSelected.forEach((element: any) => {
      let data = {
        data: element,
        status: (element.supplier_email) ? "Waiting" : "Error",
        status_description:
          (element.supplier_email) ? "generic.send_email.waiting" :
            "generic.send_email.no_email"
      }
      dataEmails.push(data)
    }));
    setDataEmails(dataEmails);
    setOpenDialogEmail(true);
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-purchaseinvoices-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'purchase_invoice_date' || element.field === 'date_mail_send') {
            element.headerName = t(`purchaseinvoices.fields.${element.field}`);
            element.valueGetter = function (params: GridValueGetterParams<PurchaseInvoice, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
          else {
            element.headerName = t(`generic.${element.field}`);
            element.valueGetter = function (params: GridValueGetterParams<PurchaseInvoice, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
        }
        else {
          if (element.field === 'total_amount' || element.field === 'total_company_currency_amount') {
            element.valueGetter = function (params: GridValueGetterParams<PurchaseInvoice, any, GridTreeNodeWithRender>) {
              return params.value ? setFormatNumberFromTable(params.value ?? 0) : undefined;
            }
          }
          else
            if (element.field === 'currency_code') { element.headerName = t("currencies.title-view"); }
            else if (element.field === 'total_company_currency_amount') {
              element.headerName = t("companies.configuration.fields.total_company_currency_amount");
              element.valueGetter = function (params: GridValueGetterParams<PurchaseInvoice, any, GridTreeNodeWithRender>) {
                return setFormatNumberFromTable(params.value ?? 0);
              }
            }
            else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
            else if (element.field === 'supplier_business_name') { element.headerName = t(`purchaseinvoices.fields.supplier_business_name`); }
            else if (element.field === 'project_name') {
              element.headerName = t(`projects.fields.project_name`);
              element.renderCell = (params: GridRenderCellParams<PurchaseInvoice, any, any, GridTreeNodeWithRender>) => {
                return <Button onClick={() => {
                  let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
                  window.open(`/projects/view?${stringConverted}`, '_blank');
                }}>
                  {params.row.project_name}
                </Button>
              }
            }
            else {
              element.headerName = t(`purchaseinvoices.fields.${element.field}`);
            }
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    setIsLoadingDataTable(false);
    GenericGetResource(myEnumValue.route)
      .then(async (response) => {
        await setPurchaseInvoicesData(response.data.data);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setHasError(true);
        showSnackBar(error.message, 'error');
      });
  }, [myEnumValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await LoadExchangeRateHistory()
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              if (error.response && error.response.status === 400) {
                showSnackBar(error.response.data, "error");
              } else {
                showSnackBar(error.message, "error");
              }
            } else {
              showSnackBar("An unexpected error occurred", "error");
            }
          });
        await GenericGetResource(comboEnum[1].route)
          .then(async (response) => {
            response && setPurchaseInvoicesData(response.data.data);
          })
          .catch((error) => {
            setErrorMessage(error.message);
            setHasError(true);
            showSnackBar(error.message, 'error');
          });
        loadColumnsOrder();
        setMyPreferences(LoadPreferences("grid-purchaseinvoices"));
      } catch (err: any) {
        setErrorMessage(err);
        setHasError(true);
        showSnackBar(err, 'error');
      }
    };
    document.title = `${companyState?.comercial_name} - ${t("purchaseinvoices.title")}`;
    fetchData();
  }, []);

  useEffect(() => {
    if (purchaseInvoicesData) {
      setDataLoaded(true);
      setIsLoadingDataTable(true);
    }
  }, [purchaseInvoicesData]);

  return (
    <>
      {!dataLoaded && !hasError && <Spinner />}
      {hasError &&
        <ErrorDisplay
          errorMessage={errorMessage}
        />
      }
      {dataLoaded && !hasError &&
        <>
          <Header
            size="sm"
            title={t("purchaseinvoices.title")}
            child={
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <PrimaryButton
                  variant='outlined'
                  onClick={() => { navigate("add", { state: { ...location.state } }) }}
                  size="small"
                >
                  {t("generic.buttons.add")}
                </PrimaryButton>
                <ButtonStyled
                  variant='outlined'
                  size="small"
                  className="mx-1"
                  sx={{ color: 'primary.main' }}
                  disabled={dataPurchaseInvoiceSelected.length === 0}
                  onClick={onSendEmail}
                >{t("generic.buttons.send_email")}
                  <EmailIcon sx={{ marginLeft: 1 }} />
                </ButtonStyled>
                <Autocomplete
                  size="small"
                  options={comboEnum}
                  defaultValue={comboEnum[1]}
                  getOptionLabel={(option) => option.option_name}
                  renderOption={(props, option) => (
                    <div key={option.enum_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.option_name}
                      </Box>
                    </div>
                  )}
                  sx={{ width: "20%", paddingRight: 1 }}
                  isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                  onChange={(_, values) => {
                    setMyEnumValue(values ?? comboEnum[1]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("generic.filter")}`}
                      variant="filled"
                      size="small"
                    />
                  )}
                  clearOnEscape={false}
                  clearIcon={null}
                />
              </Box>
            }
          />
          {isLoadingDataTable &&
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={purchaseInvoicesData ?? []}
              entityId={"purchase_invoice_id"}
              entity={`PurchaseInvoices`}
              preferences={myPreferences}
              namePreferences={"grid-purchaseinvoices"}
              nameOrderColumns={"grid-purchaseinvoices-columns"}
              selectRows={true}
              countColumns={["total_company_currency_amount"]}
              onRowSelectionModelChange={(rowsIds) => onSelectedRows(rowsIds)}
            />
          }
          <DialogSendEmails
            open={openDialogEmail}
            setOpen={setOpenDialogEmail}
            data={dataEmails}
            showSnackBar={showSnackBar}
          />
        </>
      }
      <SnackbarComponent />
    </>
  )

}
