import { Dispatch, SetStateAction, useState } from 'react'
import { InvoiceDocumentType } from '../../../interfaces/Sales/Invoices/salesinvoices'
import { Control, Controller, FieldErrors, UseFormGetValues } from 'react-hook-form'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { FormsErrors } from '../../../hooks/Forms/FormsErrors'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import GenericPromises from '../../../api/GenericPromises'
import { Supplier } from '../../../interfaces/Purchases/Catalogs/suppliers'
import { PurchaseInvoice } from '../../../interfaces/Purchases/Invoices/purchaseInvoices'
import { myStylePickers } from '../../../theme/buttons'
import { useCompanyStore } from '../../../store/AuthStore'

type InvoiceHeaderProps = {
  control: Control<PurchaseInvoice, any>,
  errors: FieldErrors<PurchaseInvoice>,
  isSupplierSelected?: boolean,
  comboSuppliers: Supplier[],
  comboInvoiceDocumentTypes: InvoiceDocumentType[],
  setMyConsecutive: Dispatch<SetStateAction<string>>,
  myConsecutive: string,
  isSelectedSupplierTextfield: boolean,
  setIsSelectedSupplierTextfield: Dispatch<SetStateAction<boolean>>,
  onPost: (data: PurchaseInvoice) => Promise<undefined>,
  getValues: UseFormGetValues<PurchaseInvoice>,
}

export const PurchaseInvoicesHeader = ({
  control,
  errors,
  isSupplierSelected,
  comboSuppliers,
  comboInvoiceDocumentTypes,
  setMyConsecutive,
  myConsecutive,
  isSelectedSupplierTextfield,
  setIsSelectedSupplierTextfield,
  onPost,
  getValues,
}: InvoiceHeaderProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePicker2, setOpenDatePicker2] = useState(false);
  const [openDatePicker3, setOpenDatePicker3] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const { companyState } = useCompanyStore();

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const GetAndSetConsecutive = async (consecutive_id?: number) => {
    if (consecutive_id) {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutive_id}`);
      setMyConsecutive((prev) => response.data.data[0].Consecutive);
    }
  }

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <Box className='custom-card-view w-25' sx={{ marginRight: 2 }}>
          <div>
            <Typography>{companyState?.business_name}</Typography>
            <Typography>{companyState?.rfc}</Typography>
            <Typography>{companyState?.phone_number}</Typography>
            <Typography>{companyState?.street}, {companyState?.city_name}, {companyState?.state_name}, {companyState?.country_name}</Typography>
          </div>
          <div>
            <Controller
              name="supplier_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={comboSuppliers}
                  getOptionLabel={(option) => option.business_name}
                  renderOption={(props, option: Supplier) => (
                    <div key={option.supplier_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {(option.comercial_name) ? option.comercial_name + " - " : ""} {option.business_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.supplier_id === value.supplier_id}
                  onChange={(_, values) => {
                    field.onChange(values?.supplier_id || null);
                    if (values?.supplier_id !== null && values?.supplier_id !== undefined) {
                      setIsSelectedSupplierTextfield(true);
                      onPost(getValues());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("suppliers.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.supplier_id}
                      helperText={GetError(errors.supplier_id?.type)}
                    />
                  )}
                  disabled={isSupplierSelected || isSelectedSupplierTextfield}
                />
              )}
            />
          </div>
        </Box>
        {/* Right Box*/}
        <div className=' d-flex flex-column w-75'>
          {/* First row */}
          <div className='d-flex w-100'>

            <div className='w-50'>
              <Controller
                name="purchase_invoice_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("purchaseinvoices.fields.purchase_invoice_name")}`}
                    ref={field.ref}
                    value={myConsecutive}
                    disabled={isSelectedSupplierTextfield}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.purchase_invoice_name}
                    helperText={GetError(errors.purchase_invoice_name?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="invoice_doc_type_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    options={comboInvoiceDocumentTypes}
                    getOptionLabel={(option) => option.name ?? ""}
                    disabled={isSelectedSupplierTextfield}
                    defaultValue={comboInvoiceDocumentTypes[0]}
                    renderOption={(props, option: InvoiceDocumentType) => (
                      <div key={option.invoice_doc_type_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
                    onChange={async (_, values) => {
                      await GetAndSetConsecutive(values?.consecutive_id);
                      field.onChange(values?.invoice_doc_type_id || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("invoicedocumenttypes.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.invoice_doc_type_id}
                        helperText={GetError(errors.invoice_doc_type_id?.type)}
                      />
                    )}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="purchase_invoice_date"
                control={control}
                rules={{}}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    ref={field.ref}
                    label={`${t("purchaseinvoices.fields.purchase_invoice_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.purchase_invoice_date,
                        helperText: GetError(errors.purchase_invoice_date?.type),
                      }
                    }
                    }
                    defaultValue={dayjs()}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedSupplierTextfield}
                  />
                }
              />
            </div>

          </div>
          {/* Second Row */}
          <div className='d-flex w-100 mt-2'>
            <div className="w-50">
              <Controller
                name="sales_order"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    disabled={isSelectedSupplierTextfield}
                    label={`${t("purchaseinvoices.fields.sales_order")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.sales_order}
                    helperText={GetError(errors.sales_order?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                  />
                )}
              />
            </div>
            <div className='w-50'>
              <Controller
                name="expiration_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker3}
                    onClose={() => setOpenDatePicker3(false)}
                    ref={field.ref}
                    label={`${t("purchaseinvoices.fields.expiration_date")}`}
                    onChange={field.onChange}
                    sx={{ width: "100%", paddingRight: 2 }}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker3(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.expiration_date,
                        helperText: GetError(errors.expiration_date?.type),
                      }
                    }}
                    format="DD/MM/YYYY"
                    disablePast
                    disabled={isSelectedSupplierTextfield}
                  />
                }
              />
            </div>
            <div className="w-50">
              <Controller
                name="supplier_sale_invoice_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker2}
                    onClose={() => setOpenDatePicker2(false)}
                    ref={field.ref}
                    label={`${t("purchaseinvoices.fields.supplier_sale_invoice_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker2(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.supplier_sale_invoice_date,
                        helperText: GetError(errors.supplier_sale_invoice_date?.type),
                      }
                    }}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedSupplierTextfield}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
