import Button, { ButtonProps } from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { Dispatch, SetStateAction,  } from "react";
import { Typography } from "@mui/material";

export const PrimaryButton = ({ ...props }) => {
  return (
    <Button {...props} sx={{
      ...props.sx,
      ':hover': {
        bgcolor: 'primary.main', // theme.palette.primary.main
        color: 'white',
      },
    }}>{props.children}</Button>
  )
}

export const ButtonStyled = ({ ...props }) => {
  return (
    <Button {...props} sx={{
      ...props.sx,
      ':hover': {
        bgcolor: 'primary.main', // theme.palette.primary.main
        color: 'white',
      },
      color: (props.variant === 'outlined') ? 'primary.main' : 'white'
    }}>{props.children}</Button>
  )
}

interface ColorPickerProps {
  color: string;
  setColor: Dispatch<SetStateAction<string>>
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, setColor }) => {

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    setColor(newColor);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <input
        type="color"
        value={color}
        onChange={handleColorChange}
        style={{
          width: "30px",
          height: "30px",
          border: "none",
          padding: "0",
          cursor: "pointer",
        }}
      />
      <Typography variant="body1" sx={{ marginTop: 0.5 }} gutterBottom>
        <b>{color}</b>
      </Typography>
    </div>
  );
};

export default ColorPicker;

interface ButtonLoadingProps extends ButtonProps {
  isLoading: boolean; 
}

export const ButtonLoading = ({ isLoading, ...props }: ButtonLoadingProps) => {
  return !isLoading ? (
    <Button
      {...props}
      sx={{
        ...props.sx,
        ':hover': {
          bgcolor: 'primary.main',
          color: 'white',
        },
        width: 'auto',
        color: 'primary.main',
      }}
    >
      {props.children}
    </Button>
  ) : (
    <LoadingButton
      {...props}
      loading
      sx={{
        ...props.sx,
        ':hover': {
          bgcolor: 'primary.main',
          color: 'white',
        },
        width: 'auto',
        color: 'primary.main',
      }}
    >
      {props.children}
    </LoadingButton>
  );
}

export const myStylePickers = {
  ...{
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: 'primary.light',
      '&:hover': {
        backgroundColor: 'primary.dark',
        color: 'white'
      },
      '&:focus': {
        backgroundColor: 'primary.dark',
        color: 'white'
      },
    },
  }
}