import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { AuthContext } from '../../../context/AuthContext';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../interfaces/Security/menu';
import { Currency } from '../../../interfaces/Commons/currencies';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import DataTable from '../../../components/Tables/GridTableMaterialUI/DataTable';
import { PrimaryButton } from '../../../theme/buttons';
import { Header } from '../../../components/Header';
import { UpdateCurrency } from './update';
import { AddCurrency } from './add';
import { usePermissions } from '../../../hooks/usePermissions';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';
import { useCurrencies } from '../../../hooks/useCurrencies';
import { useCompanyStore } from '../../../store/AuthStore';
import { AxiosError } from 'axios';

export const TableCurrencies = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { TimeConverter } = useDates();
  const { user } = useContext(AuthContext);
  const { companyState } = useCompanyStore();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [currenciesData, setCurrenciesData] = useState<GridRowsProp>([]);
  const [currencyPayload, setCurrencyPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const { LoadExchangeRateHistory } = useCurrencies();
  const [columnsCurrencies, setColumnsCurrencies] = useState<GridColDef<Currency>[]>([
    { field: 'currency_code', headerName: t("currencies.fields.currency_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_description', headerName: t("currencies.fields.currency_description"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_symbol', headerName: t("currencies.fields.currency_symbol"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'rate', headerName: t("exchangeratehistory.fields.rate"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        if (params.value && params.value.rate && params.value.rate > 0) {
          return params.value.rate;
        }
        return params.value;
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-currencies");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-currencies-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<Currency, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if ((element.field === 'rate')) {
            element.valueGetter = function (params: GridValueGetterParams<Currency, any, GridTreeNodeWithRender>) {
              if (params.value && params.value.rate && params.value.rate > 0) {
                return params.value.rate;
              }
              return params.value;
            }
          }
          else if (element.field !== 'last_update_user') {
            element.headerName = t(`currencies.fields.${element.field}`);
          }
          else {
            element.headerName = t(`currencies.fields.${element.field}`);
          }
        }
      }
      setColumnsCurrencies(myJson);
    }
  }

  const handleAddRow = (newRow: Currency) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setCurrenciesData((prevState) => [...currenciesData, myRow]);
  };

  const handleUpdateRow = (updateRow: Currency) => {
    if (currenciesData.length === 0) {
      return;
    }
    setCurrenciesData((prevRows) => {
      const rowToUpdateIndex = currenciesData.findIndex(e => e.currency_id === updateRow.currency_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (currenciesData.length === 0) {
      return;
    }
    setCurrenciesData((prevRows) => {
      const rowToDeleteIndex = currenciesData.findIndex(e => e.currency_id === deleteRow);
      return [
        ...currenciesData.slice(0, rowToDeleteIndex),
        ...currenciesData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  const handleUpdateValueRate = (newRate: number) => {
    let updateRow: any = currencyPayload;
    setCurrenciesData((prevRows) => {
      const rowToUpdateIndex = currenciesData.findIndex(e => e.currency_id === updateRow.currency_id);
      updateRow.rate = newRate;

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await LoadExchangeRateHistory()
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              if (error.response && error.response.status === 400) {
                showSnackBar(error.response.data, "error");
              } else {
                showSnackBar(error.message, "error");
              }
            } else {
              showSnackBar("An unexpected error occurred", "error");
            }
          });;

        const currenciesResponse = await GenericGetResource("/currencies");
        setCurrenciesData(currenciesResponse.data.data);

        const resourceResponse = await GetResourceByUrl(`/currencies`);
        setResourceScreen((prev) => resourceResponse);

        loadColumnsOrder();
        loadPreferences();
        setDataLoaded(true);
      } catch (error: any) {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      }
    };

    document.title = `${companyState?.comercial_name} - ${t("purchaseinvoices.title")}`;
    if (location.state !== null && location.state.method) methodUsed();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container'>
            <Header
              title={t("currencies.title")}
              child={
                <div className='d-flex flex-row-reverse my-1'>
                  {resourceScreen?.create === true &&
                    <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
                  }
                </div>
              }
            />

            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsCurrencies}
                setColumns={setColumnsCurrencies}
                data={currenciesData}
                entityId={"currency_id"}
                entity={`Currencies`}
                preferences={myPreferences}
                namePreferences={"grid-currencies"}
                nameOrderColumns={"grid-currencies-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setCurrencyPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateCurrency currencyPayload={currencyPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} handleUpdateValueRate={handleUpdateValueRate} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("currencies.title-view")} size='md' />}
          />
          <DialogEntity
            content={<AddCurrency setOpenDialog={setOpenDialogAdd} AddNewRow={handleAddRow} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("currencies.title-view")} size='md' />}
          />
          <SnackbarComponent />
        </>
      }
    </>
  )
}
