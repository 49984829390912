import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors, } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { TextField, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CompanyLocation } from '../../../../../interfaces/Security/companies';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { useFiles } from '../../../../../hooks/useFiles';
import { FileSQL } from '../../../../../interfaces/Commons/files';
import { ButtonStyled } from '../../../../../theme/buttons';

type TabCompanyLocationSignature = {
    control: Control<CompanyLocation, any>;
    errors: FieldErrors<CompanyLocation>;
    fileKey: FileSQL;
    setFileKey: Dispatch<SetStateAction<FileSQL>>;
    fileCer: FileSQL;
    setFileCer: Dispatch<SetStateAction<FileSQL>>;
}

export const AddSignatureTab = ({
    control,
    errors,
    fileCer,
    fileKey,
    setFileCer,
    setFileKey
}: TabCompanyLocationSignature) => {
    const [t] = useTranslation("global");
    const { GetError } = FormsErrors();
    const { DecodeFileSQLToBase64 } = useFiles()
    const [hiddenPassword, setHiddenPassword] = useState(false);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <>
            <div className='d-flex justify-content-center'>
                <div className='w-100'>

                    <div className="custom-card-row d-flex justify-content-between">

                        <div className="w-100">
                            <div className='d-flex pt-3 flex-column'>
                                {/* First row */}
                                <div className='d-flex'>
                                    <div className='w-50 d-flex'>
                                        <Controller
                                            name="fiscal_password"
                                            control={control}
                                            rules={{ maxLength: 100 }}
                                            render={({ field }) =>
                                                <TextField
                                                    InputProps={{
                                                        endAdornment: <IconButton children={hiddenPassword ? <VisibilityOffIcon /> : <VisibilityIcon />} onClick={() => { setHiddenPassword((prev) => (!hiddenPassword)) }} />
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    ref={field.ref}
                                                    type={`${hiddenPassword ? 'text' : 'password'}`}
                                                    value={field.value}
                                                    variant='filled'
                                                    label={t("companies.fields.fiscal_password")}
                                                    onChange={field.onChange}
                                                    error={!!errors.fiscal_password}
                                                    helperText={GetError(errors.fiscal_password?.type)}
                                                    sx={{ width: "100%", paddingRight: 2 }}
                                                    size='small'
                                                />
                                            }
                                        />
                                    </div>
                                    <div className='d-flex w-50 align-items-start'>
                                        <div className='w-100'>
                                            <div>
                                                <ButtonStyled component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                                    {t("companies.upload.key")}
                                                    <VisuallyHiddenInput type="file" accept=".key" onChange={async (e) => {
                                                        await DecodeFileSQLToBase64(e).then(async (response) => {
                                                            if (response.type_of_file == "application/x-iwork-keynote-sffkey") {
                                                                await setFileKey(response);
                                                            }
                                                        });
                                                    }
                                                    }
                                                    />
                                                </ButtonStyled>
                                                <div className='d-flex pt-3'>
                                                    {fileKey.data_file ?
                                                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{t("companies.upload.keyprivate") + fileKey.file_name}</Typography></label>
                                                        :
                                                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{(fileKey.file_name == undefined) ? "" : t("companies.upload.keyprivate") + fileKey.file_name}</Typography></label>
                                                    }
                                                </div>
                                            </div>
                                            <div className='mt-2'>
                                                <ButtonStyled component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                                    {t("companies.upload.cer")}
                                                    <VisuallyHiddenInput type="file" accept=".cer"
                                                        onChange={async (e) => {
                                                            await DecodeFileSQLToBase64(e).then(async (response) => {
                                                                if (response.type_of_file == "application/x-x509-ca-cert") {
                                                                    await setFileCer(response);
                                                                }
                                                            });
                                                        }
                                                        } />
                                                </ButtonStyled>
                                                <div className='d-flex pt-3'>
                                                    {fileCer.data_file ?
                                                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{t("companies.upload.certificate") + fileCer.file_name}</Typography></label>
                                                        :
                                                        <label className='fw-lighter overflow-hidden'><Typography fontSize={15} overflow={'hidden'}>{(fileCer.file_name == undefined) ? "" : t("companies.upload.certificate") + fileCer.file_name}</Typography></label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}