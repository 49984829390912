import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDates } from "../../../hooks/useDates";
import { useParamsFilter } from "../../../hooks/useParamsFilter";
import { useContext, useEffect, useState } from "react";
import { SalesPreorder, SalesPreorderDetail } from "../../../interfaces/Sales/Preorders/SalesPreorders";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Customer, CustomerLocation } from "../../../interfaces/Sales/Catalogs/Customers/customers";
import { Menuitem } from "../../../interfaces/Security/menu";
import GenericPromises from "../../../api/GenericPromises";
import { usePermissions } from "../../../hooks/usePermissions";
import { Autocomplete, Backdrop, Box, Button, CircularProgress, ListItemButton, Popover, TextField } from "@mui/material";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ButtonStyled, myStylePickers, PrimaryButton } from "../../../theme/buttons";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SalesPreorderTabs from "./salesPreorderTabs";
import { UpdateSalesPreorderHeader } from "./updateSalesPreorderHeader";
import { AnnotationsSalesPreorder } from "./Tabs/annotations";
import { DocumentsSalesPreorder } from "./Tabs/documents";
import { UpdateSalesPreordersDetails } from "./Tabs/updateDetails";
import { Currency } from "../../../interfaces/Commons/currencies";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";
import { AuthContext } from "../../../context/AuthContext";
import { UserCompanies } from "../../../interfaces/Security/userCompanies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { DialogProcessOrder } from "./components/DialogProcessOrder";
import { CompanyLocation } from "../../../interfaces/Security/companies";

export const ViewSalesPreorders = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { TimeConverter } = useDates();
  const { GetParamsFromBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const { GenericPutResource, GenericGetResource, GenericPostResource, GenericDeleteResource, GenericGetResourceGeneric, GenericPostAction } = GenericPromises();
  const { GetError } = FormsErrors();
  const [dataLoadedHeader, setDataLoadedHeader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [openDialogCopySalesPreorder, setOpenDialogCopySalesPreorder] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [disabledPostCopy, setDisabledPostCopy] = useState(false);
  const [isLoadingCustomerLocations, setIsLoadingCustomerLocations] = useState(false);
  const [hasDefaultCustomer, setHasDefaultCustomer] = useState(true);
  const [openDialogProcessOrder, setOpenDialogProcessOrder] = useState(false);
  const [hasSalesOrder, setHasSalesOrder] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [salesPreorderLocal, setSalesPreorderLocal] = useState<SalesPreorder>();
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [defaultCustomer, setDefaultCustomer] = useState<Customer>();
  const [comboCustomerLocations, setComboCustomerLocations] = useState<CustomerLocation[]>([]);
  const [defaultCustomerLocation, setDefaultCustomerLocation] = useState<CustomerLocation>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [resourceCopySalesPreorder, setResourceCopySalesPreorder] = useState<Menuitem>();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceScreenDetails, setResourceScreenDetails] = useState<Menuitem>();
  const [resourceScreenProcessOrder, setResourceScreenProcessOrder] = useState<Menuitem>();
  const [comboLocationsDelivery, setComboLocationsDelivery] = useState<CustomerLocation[]>([]);
  const [defaultLocationDelivery, setDefaultLocationDelivery] = useState<CustomerLocation>();
  const [resourceDelivery, setResourceDelivery] = useState<Menuitem>();
  const [comboCompanyLocations, setComboCompanyLocations] = useState<CompanyLocation[]>([]);
  const [defaultCompanyLocation, setDefaultCompanyLocation] = useState<CompanyLocation>();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    trigger
  } = useForm<SalesPreorder>({});

  const onSubmit: SubmitHandler<SalesPreorder> = (data) => onPut(data);

  const onPut = async (data: SalesPreorder) => {
    setLoadingPut(true);

    let myNewCustomer: Customer | undefined;
    let myCustomerLocation: CustomerLocation | undefined;
    let myCompanyLocation: CompanyLocation | undefined;
    if (data.customer_id !== salesPreorderLocal?.customer_id) {
      myNewCustomer = comboCustomers.find((item) => item.customer_id === data.customer_id);
      setIsLoadingCustomerLocations(true);
      let myCustomerLocationsResponse = await GenericGetResource(`/customerlocations/bycustomerid/${data.customer_id}`).catch((error) => {
        showSnackBar(error.message, 'error');
      });
      let myComboCustomerLocations: CustomerLocation[] = myCustomerLocationsResponse.data.data;
      setComboCustomerLocations(myComboCustomerLocations);
      setDefaultCustomerLocation(myComboCustomerLocations.find((item) => item.fiscal_address === true));
      myCustomerLocation = myComboCustomerLocations.find((item) => item.fiscal_address === true);
      let myComboDeliveryLocations = myComboCustomerLocations.filter((element) => element.delivery_address === true);
      setComboLocationsDelivery(myComboDeliveryLocations);
      let myDefaultLocationDelivery = myComboDeliveryLocations.find((item) => item.default_delivery_address === true)
      setDefaultLocationDelivery(myDefaultLocationDelivery);
      myCompanyLocation = await comboCompanyLocations.find((item: CompanyLocation) => item.company_location_id === data.company_location_id);
      setDefaultCompanyLocation(myCompanyLocation);
      await onPutDelivery(myDefaultLocationDelivery);
    }
    else {
      myCustomerLocation = await comboCustomerLocations.find((element: CustomerLocation) => element.customer_location_id === data.customer_location_id);
      let myCustomerLocationDelivery = await comboCustomerLocations.find((element: CustomerLocation) => element.customer_location_id === data.sales_delivery_addresses_id);
      await onPutDelivery(myCustomerLocationDelivery);
    }

    let myCurrency = await comboCurrencies.find((item: Currency) => item.currency_id === data.currency_id);

    // GET company info
    const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");

    var date = new Date();
    if (typeof data.sales_preorder_date == 'string') {
      date = TimeConverter(data.sales_preorder_date) ?? new Date();
    }
    else if (data.sales_preorder_date) {
      let currentDate = new Date();
      let invoiceDate = new Date(data.sales_preorder_date);
      invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
      date = invoiceDate;
    }

    let myData = {
      sales_preorder_name: data.sales_preorder_name,
      sales_preorder_date: date ?? new Date(data.sales_preorder_date ?? new Date()),
      purchase_order: data.purchase_order ?? null,
      customer_id: data.customer_id,
      customer_business_name: myCustomerLocation?.business_name ?? data?.customer_business_name,
      customer_comercial_name: data.customer_comercial_name,
      customer_rfc: myCustomerLocation?.customer_rfc ?? data?.customer_rfc,
      customer_street_name: myCustomerLocation?.street ?? (data.customer_street_name ?? null),
      customer_location_id: myCustomerLocation?.customer_location_id ?? (data.customer_location_id ?? null),
      customer_postal_code: myCustomerLocation?.postal_code ?? (data.customer_postal_code ?? null),
      customer_city_id: 0,
      customer_city_name: myCustomerLocation?.city_name ?? (data.customer_city_name ?? null),
      customer_state_id: 0,
      customer_state_name: myCustomerLocation?.state_name ?? (data.customer_state_name ?? null),
      customer_state_abbr: myCustomerLocation?.state_abbr ?? (data.customer_state_abbr ?? null),
      customer_country_id: 0,
      customer_country_name: myCustomerLocation?.country_name ?? (data.customer_country_name ?? null),
      customer_country_code: myCustomerLocation?.country_code ?? (data.customer_country_code ?? null),
      subtotal_amount: data.subtotal_amount ?? 0,
      total_amount: data.total_amount ?? 0,
      tax_amount: data.tax_amount ?? null,
      currency_id: (data.currency_id && data.currency_id > 0) ? data.currency_id : null,
      currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
      currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
      project_id: null,
      payment_mode_id: null,
      payment_mode_code: null,
      payment_mode_description: null,
      payment_method_id: null,
      payment_method_code: null,
      payment_method_name: null,
      cfdi_id: null,
      cfdi_code: null,
      cfdi_description: null,
      discount_amount: data.discount_amount ?? null,
      customer_tax_regime_id: 0,
      customer_tax_regime_code: myCustomerLocation?.tax_regime_code ?? (data.customer_tax_regime_code ?? null),
      customer_tax_regime_description: myCustomerLocation?.tax_regime_description ?? (data.customer_tax_regime_description ?? null),
      id_consecutive: data.id_consecutive,
      notes: data.notes ?? null,
      retention_amount: data.retention_amount ?? null,
      payment_condition_id: data.payment_condition_id ?? null,
      payment_condition_days: null,
      payment_condition_name: null,
      payment_condition_description: null,
      payment_due_date: data.payment_due_date ?? null,
      customer_language: myNewCustomer ? myNewCustomer.language_code : (data.customer_language ?? null),
      customer_email: myCustomerLocation?.customer_email ?? data.customer_email ?? null,
      customer_phone_number: myCustomerLocation?.customer_phone_number ?? data.customer_phone_number ?? null,
      date_mail_send: null,
      user_mail_send: null,
      company_location_id: 0,
      company_business_name: myCompanyLocation?.business_name ?? (data.company_business_name ?? null),
      company_street: myCompanyLocation?.street ?? (data.company_street ?? null),
      company_city_id: myCompanyLocation?.city_id ?? (data.company_city_id ?? null),
      company_city_name: myCompanyLocation?.city_name ?? (data.company_city_name ?? null),
      company_city_code: myCompanyLocation?.city_code ?? (data.company_city_code ?? null),
      company_state_id: myCompanyLocation?.state_id ?? (data.company_state_id ?? null),
      company_state_name: myCompanyLocation?.state_name ?? (data.company_state_name ?? null),
      company_state_abbr: myCompanyLocation?.state_abbr ?? (data.company_state_abbr ?? null),
      company_country_id: myCompanyLocation?.country_id ?? (data.company_country_id ?? null),
      company_country_name: myCompanyLocation?.country_name ?? (data.company_country_name ?? null),
      company_country_code: myCompanyLocation?.country_code ?? (data.company_country_code ?? null),
      company_postal_code: myCompanyLocation?.postal_code ?? (data.company_postal_code ?? null),
      company_rfc: myCompanyLocation?.company_rfc ?? (data.company_rfc ?? null),
      company_tax_regime_id: myCompanyLocation?.tax_regime_id ?? (data.company_tax_regime_id ?? null),
      company_tax_regime_code: myCompanyLocation?.tax_regime_code ?? (data.company_tax_regime_code ?? null),
      company_tax_regime_description: myCompanyLocation?.tax_regime_description ?? (data.company_tax_regime_description ?? null),
      company_email: myCompanyLocation?.email ?? (data.company_email ?? null),
      company_phone_number: myCompanyLocation?.phone_number ?? (data.company_phone_number ?? null),
      company_rpt_image: myCompanyLocation?.rpt_image ?? (data.company_rpt_image ?? null),
      company_account_alias: responseCompany.data?.primary_account_alias ?? null,
      company_primary_account_alias: responseCompany.data?.primary_account_alias ?? null,
      company_primary_bank_name: responseCompany.data?.primary_bank_name ?? null,
      company_primary_account_number: responseCompany.data?.primary_account_number ?? null,
      company_primary_interbank_key: responseCompany.data?.primary_interbank_key ?? null,
      company_primary_swift_code: responseCompany.data?.primary_swift_code ?? null,
      company_secondary_account_alias: responseCompany.data?.secondary_account_alias ?? null,
      company_secondary_bank_name: responseCompany.data?.secondary_bank_account ?? null,
      company_secondary_account_number: responseCompany.data?.secondary_account_number ?? null,
      company_secondary_interbank_key: responseCompany.data?.secondary_interbank_key ?? null,
      company_secondary_swift_code: responseCompany.data?.secondary_swift_code ?? null,
      url_files: data.url_files ?? null,
      pdf_file_id: data.pdf_file_id ?? null,
    }
    GenericPutResource(`/salespreorders/${salesPreorderLocal?.sales_preorder_id}`, myData)
      .then(async (responsePut) => {
        await reset({
          ...responsePut.data,
          sales_delivery_addresses_id: 0,
          company_location_id: 0,
          currency_id: responsePut.data.currency_code ? 0 : null,
        });
        await setSalesPreorderLocal(responsePut.data);
        setLoadingPut(false);
        setIsLoadingCustomerLocations(false);
        showSnackBar(t("generic.snackbar.update"), "success");
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPut(false);
        setIsLoadingCustomerLocations(false);
      });
  }

  const onPutDelivery = async (salesDelivery: CustomerLocation | undefined) => {
    if (resourceDelivery?.read) {
      let mySalesDelivery = {
        business_name: salesDelivery?.business_name ?? null,
        comercial_name: null,
        street: salesDelivery?.street ?? null,
        postal_code: salesDelivery?.postal_code ?? null,
        city_id: salesDelivery?.city_id ?? null,
        city_name: salesDelivery?.city_name ?? null,
        state_id: salesDelivery?.state_id ?? null,
        state_name: salesDelivery?.state_name ?? null,
        state_abbr: salesDelivery?.state_abbr ?? null,
        country_id: salesDelivery?.country_id ?? null,
        country_name: salesDelivery?.country_name ?? null,
        country_code: salesDelivery?.country_code ?? null,
        rfc: salesDelivery?.customer_rfc ?? null,
        tax_regime_id: salesDelivery?.tax_regime_id ?? null,
        tax_regime_code: salesDelivery?.tax_regime_code ?? null,
        tax_regime_description: salesDelivery?.tax_regime_description ?? null,
        email: salesDelivery?.customer_email ?? null,
        phone_number: salesDelivery?.customer_phone_number ?? null,
      }
      if (salesPreorderLocal?.sales_delivery_addresses_id && resourceDelivery?.update) {
        await GenericPutResource(`/salesdeliveryaddresses/${salesPreorderLocal?.sales_delivery_addresses_id}`, mySalesDelivery)
      }
      else if (resourceDelivery?.create) {
        await GenericPostResource(`/salesdeliveryaddresses`, mySalesDelivery).then(async (response) => {
          let mySalesPreorderLocal = { sales_delivery_addresses_id: response.data.sales_delivery_addresses_id }
          await GenericPutResource(`/salespreorders/${salesPreorderLocal?.sales_preorder_id}`, mySalesPreorderLocal)
        })
      }
    }
  }

  const onDelete = () => {
    GenericGetResource(`/salespreorderdetails/bysalespreorderid/${salesPreorderLocal?.sales_preorder_id}`)
      .then(async (responseDetails) => {
        for (let i = 0; i < responseDetails.data.data.length; i++) {
          const e: SalesPreorderDetail = responseDetails.data.data[i];
          await GenericDeleteResource(`/salespreorderdetails/${e.sales_preorder_detail_id}`);
        }
        await GenericDeleteResource(`/salespreorders/${salesPreorderLocal?.sales_preorder_id}`)
          .then(() => {
            setOpenDialog(false);
            navigate("/salespreorders", { state: { method: "delete" }, replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
            setOpenDialog(false);
          })
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      });
  }

  const onCopy = () => {
    GenericPostAction(`/salespreorders/copy/${salesPreorderLocal?.sales_preorder_id}`, {}, false)
      .then(async (response) => {
        await navigate("/salespreorders", { state: { row: response.data } });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
      .finally(() => setDisabledPostCopy(false));
  }

  const onChangeTotalValues = async () => {
    try {
      const response = await GenericGetResource(`/salespreorders/${salesPreorderLocal?.sales_preorder_id}`);

      setValue("total_amount", response.data.total_amount);
      setValue("subtotal_amount", response.data.subtotal_amount);
      setValue("discount_amount", response.data.discount_amount);
      setValue("tax_amount", response.data.tax_amount);
      setValue("retention_amount", response.data.retention_amount);
    } catch (error: any) {
      showSnackBar(error.message, 'error');
    }
  }

  useEffect(() => {
    let salesPreorderId;
    let myCustomerId: any;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      salesPreorderId = myParams.get("sales_preorder_id");
      myCustomerId = myParams.get("customer_id") ?? '';
      setCustomerId((prev) => parseInt(myCustomerId ?? 0));
    }
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salespreorders.title-view")}`;
    });
    if (location.state === null && (!salesPreorderId || !myCustomerId)) return navigate("/salespreorders", { state: {}, replace: true });

    GetResourceByUrl(`/salespreorderdetails`)
      .then((responsePermission) => {
        setResourceScreenDetails(responsePermission);
      });

    GetResourceByUrl(`/salespreorders/processorder`)
      .then((responsePermission) => {
        setResourceScreenProcessOrder((prev) => responsePermission);
      });

    // permission resource screen
    GetResourceByUrl(`/salespreorders`)
      .then((responsePermission) => {
        setIsEdit(!responsePermission?.update);
        setResourceScreen(responsePermission);
      });

    GetResourceByUrl(`/salesdeliveryaddresses`)
      .then((responsePermission) => {
        setResourceDelivery(responsePermission);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resourceScreen && resourceScreen.read) {
      let salesPreorderId;
      let myCustomerId: any;
      if (location.search.length > 0 && location.state === null) {
        let myParams = GetParamsFromBase64();
        salesPreorderId = myParams.get("sales_preorder_id");
        myCustomerId = myParams.get("customer_id") ?? '';
      }
      // set data local, reset and dataloaded header true
      GenericGetResource(`/salespreorders/${(location.state !== null) ? location.state.row.sales_preorder_id : salesPreorderId}`)
        .then(async (response) => {
          setSalesPreorderLocal(response.data);
          await reset({
            ...response.data,
            sales_delivery_addresses_id: 0,
            company_location_id: response.data.company_business_name ? 0 : null,
            currency_id: response.data.currency_code ? 0 : null,
          });

          if (response.data.company_business_name) {
            let myCompany: CompanyLocation = {
              street: getValues("company_street") ?? '',
              city_name: getValues("company_city_name") ?? '',
              city_code: getValues("company_city_code") ?? '',
              state_name: getValues("company_state_name") ?? '',
              state_abbr: getValues("company_state_abbr") ?? '',
              country_name: getValues("company_country_name") ?? '',
              country_code: getValues("company_country_code") ?? '',
              postal_code: getValues("company_postal_code") ?? '',
              company_rfc: getValues("company_rfc") ?? '',
              tax_regime_code: getValues("company_tax_regime_code") ?? '',
              tax_regime_description: getValues("company_tax_regime_description") ?? '',
              email: getValues("company_email") ?? '',
              phone_number: getValues("company_phone_number") ?? '',
              business_name: getValues("company_business_name") ?? '',
              rpt_image: getValues("company_rpt_image") ?? undefined,
            }
            await reset({ company_location_id: 0 }, { keepValues: true });
            setDefaultCompanyLocation((prev) => myCompany);
          }

          setDefaultDate(new Date(response.data.sales_preorder_date));
          let myHasSalesOrder: boolean = response.data.has_sales_order;
          if (myHasSalesOrder) {
            setIsEdit(true);
            setHasSalesOrder(true);
          }
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });

      // load permissions
      GetResourceByUrl(`/salespreorders/copy`)
        .then((responsePermission) => {
          setResourceCopySalesPreorder(responsePermission);
        })
    }
  }, [resourceScreen]);

  useEffect(() => {
    if (salesPreorderLocal) {
      new Promise(async () => {

        let myCurrenciesResponse = await GenericGetResource("/currencies").catch((error) => {
          showSnackBar(error.message, 'error');
        });
        let myComboCurrencies: Currency[] = myCurrenciesResponse.data.data;
        setComboCurrencies(myComboCurrencies);

        let myCompanyLocation= await GenericGetResource("/companylocations").catch((error) => {
          showSnackBar(error.message, 'error');
        });
        let myComboCompanyLocation: CompanyLocation[] = myCompanyLocation.data.data;
        setComboCompanyLocations(myComboCompanyLocation);

        // set historical data info in default combos
        if (salesPreorderLocal.customer_postal_code) {
          let myHistoricalCustomerLocation: CustomerLocation = {
            customer_location_id: 0,
            business_name: getValues("customer_business_name"),
            street: getValues("customer_street_name"),
            city_name: getValues("customer_city_name"),
            state_name: getValues("customer_state_name"),
            state_abbr: getValues("customer_state_abbr"),
            country_name: getValues("customer_country_name"),
            country_code: getValues("customer_country_code"),
          }
          setDefaultCustomerLocation((prev) => myHistoricalCustomerLocation);
        }

        if (salesPreorderLocal.currency_code) {
          let myHistoricalCurrency: Currency = {
            currency_id: 0,
            currency_code: getValues("currency_code") ?? '',
            currency_description: getValues("currency_description") ?? '',
          }
          setDefaultCurrency((prev) => myHistoricalCurrency);
        }

        if (salesPreorderLocal.sales_delivery_addresses_id && resourceDelivery?.read) {
          GenericGetResource(`/salesdeliveryaddresses/${salesPreorderLocal.sales_delivery_addresses_id}`).then(async (responseDelivery) => {
            if (responseDelivery.data && responseDelivery.data.business_name) {
              let myDeleveryLocation: CustomerLocation = {
                customer_location_id: 0,
                business_name: responseDelivery.data.business_name,
                street: responseDelivery.data.street,
                city_name: responseDelivery.data.city_name,
                state_name: responseDelivery.data.state_name,
                state_abbr: responseDelivery.data.state_abbr,
                country_name: responseDelivery.data.country_name,
                country_code: responseDelivery.data.country_code,
              }
              await reset({ sales_delivery_addresses_id: 0 }, { keepValues: true });
              setDefaultLocationDelivery((prev) => myDeleveryLocation);
            }
          })
        }

        if (!comboCustomerLocations.length) {
          await new Promise(async () => {
            let myCustomerLocationsResponse = await GenericGetResource(`/customerlocations/bycustomerid/${salesPreorderLocal.customer_id}`).catch((error) => {
              showSnackBar(error.message, 'error');
            });
            let myComboCustomerLocations: CustomerLocation[] = myCustomerLocationsResponse.data.data;
            setComboCustomerLocations(myComboCustomerLocations);
            let myComboDeliveryLocations = myComboCustomerLocations.filter((element) => element.delivery_address === true)
            setComboLocationsDelivery(myComboDeliveryLocations);
            setDataLoadedHeader(true);
          });
        }

        setDataLoadedHeader(true);
      })
    }
  }, [salesPreorderLocal]);

  useEffect(() => {
    if (dataLoadedHeader) {
      let myProjectId = "";
      let myCustomerId = "";
      if (location.search.length > 0 && location.state === null) {
        let myParams = GetParamsFromBase64();
        myProjectId = myParams.get("project_id") ?? '';
        myCustomerId = myParams.get("customer_id") ?? '';
      }
      //Revisar si tiene un default Customer
      new Promise(async () => {
        let responseUserCompanies = await GenericGetResourceGeneric(`/usercompanies/byuserid/${user?.user_id}`, "/gusercompanies");
        let myCustomerDefault = responseUserCompanies.data.data.find((element: UserCompanies) => element.schema_name === user?.schema);
        if (myCustomerDefault && myCustomerDefault.customer_default_id) {
          setHasDefaultCustomer(true);
        }
        else if (!isEdit) {
          // load customers
          GenericGetResource(`/customers`)
            .then((responseCustomers) => {
              let myComboCustomers: Customer[] = responseCustomers.data.data;
              setComboCustomers(myComboCustomers);
              setDefaultCustomer(myComboCustomers.find((item) => item.customer_id === getValues("customer_id")));
              setHasDefaultCustomer(false);
            });
        }
      });
    }

  }, [dataLoadedHeader]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          {isBackdrop &&
            <Backdrop
              sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
              open={isBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          <Header
            title={t("salespreorders.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button aria-describedby={id1} onClick={handleClick1} disabled={loadingPut}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (salesPreorderLocal?.has_sales_order) {
                          showSnackBar(t("salespreorders.info.has-sales-order"), "warning");
                        }
                        else {
                          setOpenDialogProcessOrder(true);
                        }
                      }}
                      disabled={!resourceScreenProcessOrder?.create}
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 200,
                      }}
                    >
                      {t("salespreorders.actions.processorder")}
                      <FontAwesomeIcon icon={faClipboardCheck} />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => { setOpenDialogCopySalesPreorder(true); }}
                      sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }}
                      disabled={!resourceCopySalesPreorder?.create}
                    >
                      {t("salespreorders.actions.copy")}
                      <ContentCopyIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='px-1 pt-1'>
                  <Button aria-describedby={id2} onClick={handleClick2} disabled={loadingPut}>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableScrollLock={false}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton onClick={() => { /* onGeneratePDF(); */ }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }}>
                      {t("salespreorders.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='ps-2'>
                  {resourceScreen?.delete &&
                    <ButtonStyled disabled={loadingPut || isEdit} variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                  }
                </div>
                <div className='pt-1'>
                  <Button disabled={loadingPut} variant="text" onClick={() => { navigate("/salespreorders", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Arriba de la card */}
                <div className='d-flex w-100 m-2'>
                  <div className="w-25"></div>
                  <div className="d-flex w-100 flex-row-reverse">
                    <div className='w-25'>
                      <Controller
                        name="sales_preorder_date"
                        control={control}
                        rules={{}}
                        render={({ field }) =>
                          <DatePicker
                            open={openDatePicker}
                            onClose={() => setOpenDatePicker(false)}
                            ref={field.ref}
                            label={`${t("salespreorders.fields.sales_preorder_date")}`}
                            onChange={field.onChange}
                            slotProps={{
                              popper: {
                                sx: myStylePickers
                              },
                              textField: {
                                variant: "filled",
                                size: "small",
                                fullWidth: true,
                                onClick: () => setOpenDatePicker(true),
                                onBeforeInput: disableKeyboardEntry,
                                sx: { paddingRight: 2 },
                                error: !!errors.sales_preorder_date,
                                helperText: GetError(errors.sales_preorder_date?.type),
                              }
                            }}
                            defaultValue={dayjs(TimeConverter(defaultDate.toString())) ?? dayjs()}
                            format="DD/MM/YYYY"
                            disableFuture
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("salespreorders.fields.sales_preorder_name")}`}
                        value={salesPreorderLocal?.sales_preorder_name}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                    <div className='w-25'>
                      <Controller
                        name="purchase_order"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("salespreorders.fields.purchase_order")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.purchase_order}
                            helperText={GetError(errors.purchase_order?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="company_location_id"
                        control={control}
                        rules={{required : true}}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboCompanyLocations}
                            defaultValue={defaultCompanyLocation}
                            getOptionLabel={(option) => `${option.business_name} - ${option.company_rfc}`}
                            renderOption={(props, option: CompanyLocation) => (
                              <div key={option.company_location_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.business_name} - {option.company_rfc}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.company_location_id === value.company_location_id}
                            onChange={(_, values) => {
                              field.onChange(values?.company_location_id || 0)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("companies.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.company_location_id}
                                helperText={GetError(errors.company_location_id?.type)}
                              />
                            )}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-card-view'>
                  {!dataLoadedHeader && <Spinner isBox={false} />}
                  {dataLoadedHeader &&
                    <UpdateSalesPreorderHeader
                      loading={loadingPut}
                      control={control}
                      errors={errors}
                      salesPreorderLocal={salesPreorderLocal}
                      defaultCurrency={defaultCurrency}
                      defaultCustomerLocation={defaultCustomerLocation}
                      comboCustomerLocations={comboCustomerLocations}
                      comboCurrencies={comboCurrencies}
                      getValues={getValues}
                      isEdit={isEdit}
                      isLoadingCustomerLocations={isLoadingCustomerLocations}
                      hasDefaultCustomer={hasDefaultCustomer}
                      comboCustomers={comboCustomers}
                      defaultCustomer={defaultCustomer}
                      comboLocationsDelivery={comboLocationsDelivery}
                      defaultLocationDelivery={defaultLocationDelivery}
                      resourceDelivery={resourceDelivery}
                    />
                  }
                </div>
              </form>
              <div>
                <SalesPreorderTabs
                  Update={
                    <>
                      {!resourceScreenDetails && <Spinner isBox={false} />}
                      {resourceScreenDetails &&
                        <UpdateSalesPreordersDetails
                          salesPreorderLocal={salesPreorderLocal}
                          resourceScreenDetails={resourceScreenDetails}
                          dataLoadedHeader={dataLoadedHeader}
                          loadingPut={loadingPut}
                          onChangeTotalValues={onChangeTotalValues}
                          hasSalesOrder={hasSalesOrder}
                        />
                      }
                    </>
                  }
                  Annotations={
                    <>
                      {dataLoadedHeader &&
                        <AnnotationsSalesPreorder
                          control={control}
                          errors={errors}
                          isEdit={isEdit}
                        />
                      }
                    </>
                  }
                  Documents={
                    <>
                      {dataLoadedHeader &&
                        <DocumentsSalesPreorder
                          getValues={getValues}
                          isEdit={isEdit}
                          control={control}
                          errors={errors}
                          onPut={onPut}
                        />
                      }
                    </>
                  }
                />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("salespreorders.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogEntity
        open={openDialogCopySalesPreorder}
        setOpen={setOpenDialogCopySalesPreorder}
        title={<Header title={t("salesinvoices.actions.copy")} size='sm' />}
        content={
          <>
            <div className="d-flex flex-row-reverse mt-4">
              <PrimaryButton disabled={disabledPostCopy} onClick={() => onCopy()}>{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenDialogCopySalesPreorder(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
      />
      {dataLoadedHeader &&
        <DialogProcessOrder
          open={openDialogProcessOrder}
          setOpenDialog={setOpenDialogProcessOrder}
          customerId={getValues("customer_id") ?? 0}
          salesPreorderIds={[salesPreorderLocal?.sales_preorder_id ?? 0]}
        />
      }
      <SnackbarComponent />
    </>
  )
}
