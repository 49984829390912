import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { AlertColor, Button, Typography } from '@mui/material';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { GridColDef, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { ProjectDocument } from '../../../../interfaces/Projects/Catalogs/projectDocuments';
import { useDates } from '../../../../hooks/useDates';
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { PrimaryButton } from '../../../../theme/buttons';
import { usePermissions } from '../../../../hooks/usePermissions';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';
import { Header } from '../../../../components/Header';
import { AddProjectDocuments } from './add';
import { AuthContext } from '../../../../context/AuthContext';
import { UpdateProjectDocuments } from './update';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type TabProjectDocumentsProps = {
  project_id: number;
  showSnackBar: (message: string, severity: AlertColor) => void;
  isClosedProject: boolean;
  resourceScreenDocuments: Menuitem | undefined
}

export const TabProjectDocuments = ({
  project_id,
  showSnackBar,
  isClosedProject,
  resourceScreenDocuments
}: TabProjectDocumentsProps) => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { user } = useContext(AuthContext);
  const { TimeConverter } = useDates();
  const { GetResourceByUrl } = usePermissions();
  const [myPreferences, setMyPreferences] = useState({});
  const [projectDocumentPayload, setProjectDocumentPayload] = useState({});
  const [messageSnack, setMessageSnack] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [projectDocumentsData, setProjectDocumentsData] = useState<ProjectDocument[]>([]);
  const [columns, setColumns] = useState<GridColDef<ProjectDocument>[]>([
    {
      field: 'document_name', headerName: t("projectdocuments.fields.document_name"), headerClassName: 'header-grid-table', flex: 1, headerAlign: 'center',
      renderCell(params) {
        return <>
          <Button onClick={() => {
            window.open(`${params.row.document_contents}`, '_blank');
          }}>
            <Typography fontWeight={"bold"}>
              {params.row.document_name}
            </Typography>
            <OpenInNewIcon />
          </Button>
        </>
      }
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-projectdocuments-items");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-projectdocuments-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<ProjectDocument, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field === "document_name") {
          element.renderCell = (params: GridValueGetterParams<ProjectDocument, any, GridTreeNodeWithRender>) => {
            return <>
              <Button onClick={() => {
                window.open(`${params.row.document_contents}`, '_blank');
              }}>
                <Typography fontWeight={"bold"}>
                  {params.row.document_name}
                </Typography>
                <OpenInNewIcon />
              </Button>
            </>
          }
        }
        else {
          element.headerName = t(`projectdocuments.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  const handleAddRow = (newRow: ProjectDocument) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setProjectDocumentsData((prevState) => [...projectDocumentsData, myRow]);
  };

  const handleUpdateRow = (updateRow: ProjectDocument) => {
    if (projectDocumentsData.length === 0) {
      return;
    }
    setProjectDocumentsData((prevRows) => {
      const rowToUpdateIndex = projectDocumentsData.findIndex(e => e.project_document_id === updateRow.project_document_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (projectDocumentsData.length === 0) {
      return;
    }
    setProjectDocumentsData((prevRows) => {
      const rowToDeleteIndex = projectDocumentsData.findIndex(e => e.project_document_id === deleteRow);
      return [
        ...projectDocumentsData.slice(0, rowToDeleteIndex),
        ...projectDocumentsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    GenericGetResource(`/projectdocuments/byprojectid/${project_id}`)
      .then(async (response) => {
        setProjectDocumentsData(response.data.data);
        loadPreferences();
        loadColumnsOrder();
        setDataLoaded(true);
      })
      .catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          {resourceScreenDocuments?.create === true &&
            <div className='d-flex flex-row-reverse my-1'>
              <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }} disabled={isClosedProject}>{t("generic.buttons.add")}</PrimaryButton>
            </div>
          }
          <div className="d-flex justify-content-center">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={projectDocumentsData}
              entityId={"project_document_id"}
              entity={`ProjectDocuments`}
              preferences={myPreferences}
              namePreferences={"grid-projectdocuments"}
              nameOrderColumns={"grid-projectdocuments-columns"}
              isChildren={true}
              setOpenDialog={setOpenDialogUpdate}
              setDataRow={setProjectDocumentPayload}
            />
          </div>
          <DialogEntity
            content={<UpdateProjectDocuments projectDocumentPayload={projectDocumentPayload} setOpenDialogUpdate={setOpenDialogUpdate} permissions={resourceScreenDocuments} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} isClosedProject={isClosedProject} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("projectdocuments.title-view")} size='sm' />}
          />
          <DialogEntity
            content={<AddProjectDocuments setOpenDialog={setOpenDialogAdd} project_id={project_id} AddNewRow={handleAddRow} isClosedProject={isClosedProject} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("projectdocuments.title-view")} size='sm' />}
          />
        </>
      }
    </>
  )
}