import { create } from "zustand";
import { Company } from "../interfaces/Security/companies";

export const useMenuStore = create((set) => ({
    menuState: undefined,
    updateMenuState: (menuState: any) => set(() => ({ menuState })),
    resetMenuState: () => set({ menu: undefined })
}));


// Ejemplo para authContext.tsx
export const useGroupsStore = create((set) => ({
    menuState: undefined,
    groupsState: undefined,
    updateGroupsState: (groupsState: any) => set(() => ({ groupsState })),
    resetGroupsState: () => set({ menu: undefined }),
    updateMenuState: (menuState: any) => set(() => ({ menuState })),
    resetMenuState: () => set({ menu: undefined })
}));

export const useMenuStorExample = create((set) => ({
    menuState: undefined,
    updateMenuState: (menuState: any) => set(() => ({ menuState })),
    resetMenuState: () => set({ menu: undefined })
}));

interface useCompanyStoreProps {
    companyState: Company | undefined,
    updateCompanyState: (groupsState: Company) => void,
    resetCompanyState: () => void,
}

export const useCompanyStore = create<useCompanyStoreProps>((set) => ({
    companyState: undefined,
    updateCompanyState: (companyState: Company) => set(() => ({ companyState })),
    resetCompanyState: () => set({ companyState: undefined })
}));