import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Receipt, ReceiptDetail } from '../../../../interfaces/Sales/Receipts/receipts';
import { Menuitem } from '../../../../interfaces/Security/menu';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import GenericPromises from '../../../../api/GenericPromises';
import { Header } from '../../../../components/Header';
import { Box, Button, Checkbox, FormControlLabel, ListItemButton, Popover, TextField, Typography, useTheme } from '@mui/material';
import DialogReceiptDetail from './tabsReceiptDetail';
import { Stamping } from '../commons/stamping';
import { TabRelatedDocuments } from './tabRelatedReceiptsDetails';
import { TabStatuses } from './tabStatuses';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { useDates } from '../../../../hooks/useDates';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { usePermissions } from '../../../../hooks/usePermissions';
import { Divider } from '../../../../components/Commons/Divider';
import PrintIcon from '@mui/icons-material/Print';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import EmailIcon from '@mui/icons-material/Email';
import { CancellationReason } from '../../../../interfaces/Sales/Invoices/Cancellations/cancellationReason';
import { DialogCancelFiscalSignatureReceipt } from './components/dialogCancelFiscalSignature';
import { File } from '../../../../interfaces/Commons/files';
import Swal from 'sweetalert2';
import { useFiles } from '../../../../hooks/useFiles';
import { ReceiptsPDF } from '../../../../reports/receiptsPDF/ReceiptsPDF';
import { DialogSendEmailReceipt } from '../helpers/dialogSendEmailReceipt';

type ViewReceiptDetailsProps = {
  receiptDetailPayload: ReceiptDetail;
  setOpenDialogView: Dispatch<SetStateAction<boolean>>;
  permissionsReceiptsDetails?: Menuitem;
  permissionsReceipts?: Menuitem;
  setClickedButtonGoBack: Dispatch<SetStateAction<boolean>>;
}

export const ViewReceiptDetails = ({
  receiptDetailPayload,
  setOpenDialogView,
  permissionsReceiptsDetails,
  permissionsReceipts,
  setClickedButtonGoBack,
}: ViewReceiptDetailsProps) => {
  const [t] = useTranslation("global");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource, GenericPatchResource, GenericGetReport, GenericPutResource } = GenericPromises();
  const { TimeConverter } = useDates();
  const { setFormatNumber } = useFormatNumber();
  const { GetResourceByUrl } = usePermissions();
  const theme = useTheme();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadedStampingInfo, setLoadedStampingInfo] = useState(false);
  const [openDialogCancelFiscalSignature, setOpenDialogCancelFiscalSignature] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [resourceGeneratePdf, setResourceGeneratePdf] = useState<Menuitem>();
  const [resourceCancelFiscalSignature, setResourceCancelFiscalSignature] = useState<Menuitem>();
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [receiptLocal, setReceiptLocal] = useState<Receipt>();
  const [comboCancellationReasons, setComboCancellationReasons] = useState<CancellationReason[]>([]);
  const [receiptsDetailsData, setReceiptsDetailsData] = useState<ReceiptDetail[]>([]);
  const [dataReceiptsDetailsSelected, setDataReceiptsDetailsSelected] = useState<any>([]);
  const [cancellationReasonId, setCancellationReasonId] = useState(0);
  const [replacementFiscalFolio, setReplacementFiscalFolio] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fiscalXML, setFiscalXML] = useState<File>();
  const [fiscalQr, setFiscalQr] = useState<File>();
  const [isNotSelectedUuid, setIsNotSelectedUuid] = useState(false);
  const { GetFileById, GetFileIfExists, GetFiles } = useFiles();

  const { generatePDF } = ReceiptsPDF();


  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const getDataAndGeneratePDF = async (receiptsId: number) => {
    try {
      const response = await GenericGetReport(`/receipts/pdf/${receiptsId}`, false);

      if (response.data.dataIssuingCompany.rpt_image) {
        const rptImageFile = await GenericGetResource(`/filessql/` + response.data.dataIssuingCompany.rpt_image);
        response.data.dataReceiptHeader.logoImage = rptImageFile?.data.data_file ?? undefined;
      }

      if (response.data.dataReceiptHeader.qr_file) {
        const responseQR = await GetFileById(response.data.dataReceiptHeader.qr_file);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataReceiptHeader.fiscalQr = newBase64 ?? undefined;
      }

      let pdfDoc = await generatePDF(response);
      return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const onGeneratePDF = async () => {
    try {
      if (!receiptLocal?.report_file_id) {
        const pdfDoc = await getDataAndGeneratePDF(receiptLocal?.receipt_id ?? 0);
        showSnackBar(t("salesinvoices.reports.generated-PDF"), 'success');
      } else {
        const pdfFile = await GetFileIfExists(receiptLocal?.report_file_id);
        const downloadLink = document.createElement('a');
        const fileName = `${receiptLocal?.receipt_name}.pdf`;
        downloadLink.href = pdfFile?.dataURL ?? '';
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(pdfFile);
        showSnackBar(t("salesinvoices.reports.downloaded-PDF"), "success");
      }
    } catch (error: any) {
      showSnackBar('Error to generate pdf', "error");
    }
  };

  const onCancelFiscalSignature = async (e: any) => {

    setIsLoading(true);
    let myCancellationReason = comboCancellationReasons.find((item) => item.cancellation_reason_id === cancellationReasonId);

    if (myCancellationReason?.cancellation_reason_key === "01") {
      await GenericPatchResource(`/receipts/cancel/fiscalsignature/${receiptLocal?.receipt_id}`,
        {
          cancellation_reason_key: myCancellationReason.cancellation_reason_key,
          replacement_fiscal_folio: replacementFiscalFolio,
        })
        .then(async (response1) => {
          setOpenDialogCancelFiscalSignature(false);
          if (response1.data.error) {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${response1.data.error}`,
              confirmButtonColor: theme.palette.primary.main,
              text: `${t(response1.data.details)}`,
              icon: "error",
              allowOutsideClick: false
            });
            setIsLoading(false);
            return;
          }
          else {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${t("salesinvoices.info.already-canceled")}`,
              icon: "success",
              confirmButtonColor: theme.palette.primary.main,
              allowOutsideClick: false
            });
            setReceiptLocal((prev) => ({
              ...receiptLocal,
              cancel_xml_file_id: response1.data.cancel_xml_file_id,
              cancellation_date: response1.data.cancellation_date,
              cancellation_reason: response1.data.cancellation_reason,
              cancellation_response_code: response1.data.cancellation_response_code,
            }))
            setLoadedStampingInfo((prev) => !prev);
          }
        })
        .catch(async (error) => {
          showSnackBar(error.message, "error");
          setIsLoading(false);
        })

    }
    else {
      await GenericPatchResource(
        `/receipts/cancel/fiscalsignature/${receiptLocal?.receipt_id}`,
        { cancellation_reason_key: myCancellationReason?.cancellation_reason_key })
        .then(async (response1) => {
          setOpenDialogCancelFiscalSignature(false);
          if (response1.data.error) {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${response1.data.error}`,
              text: `${t(response1.data.details)}`,
              icon: "error",
              confirmButtonColor: theme.palette.primary.main,
              allowOutsideClick: false
            });
            setIsLoading(false);
            return;
          }
          else {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${t("receipts.info.cancelled")}`,
              icon: "success",
              confirmButtonColor: theme.palette.primary.main,
              allowOutsideClick: false
            });
            setReceiptLocal((prev) => ({
              ...receiptLocal,
              cancel_xml_file_id: response1.data.cancel_xml_file_id,
              cancellation_date: response1.data.cancellation_date,
              cancellation_reason: response1.data.cancellation_reason,
              cancellation_response_code: response1.data.cancellation_response_code,
            }))
            setLoadedStampingInfo((prev) => !prev);
          }
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
          setIsLoading(false);
        })
    }
  }

  const onSendEmail = async () => {
    if (receiptLocal) {
      let receiptsDetailsData: any[] = []
      let myCustomer = await GenericGetResource(`/customers/${receiptLocal.customer_id}`);
      receiptLocal.customer_email = myCustomer.data.customer_email;
      receiptsDetailsData.push({
        data: receiptLocal,
        status: (myCustomer.data.customer_email) ? "Waiting" : "Error",
        status_description: (myCustomer.data.customer_email) ? "generic.send_email.waiting" :
          "generic.send_email.no_email"
      })
      setDataReceiptsDetailsSelected((prev: any) => receiptsDetailsData);
      setOpenEmail(true);
    }
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/receipts/${receiptDetailPayload.receipt_id ?? 0}`),
      GenericGetResource(`/cancellationreasons`),
      GenericGetResource(`/receiptsdetails/byreceiptid/${receiptDetailPayload.receipt_id}`),
    ]
    Promise.all(myPromises)
      .then(async (responses) => {
        responses[0] && setReceiptLocal(responses[0].data);
        responses[1] && setComboCancellationReasons(responses[1].data.data);
        responses[2] && setReceiptsDetailsData(responses[2].data.data);

        if (responses[2].data.data.some(
          (element: ReceiptDetail) => element.uuid_related === null ||
            element.uuid_related === undefined
        )) {
          setIsNotSelectedUuid(true);
        }

        let myPermissions = [
          GetResourceByUrl(`/receipts/pdf`),
          GetResourceByUrl(`/receiptsdetails/sendemail`),
          GetResourceByUrl(`/receipts/cancel/fiscalsignature`),
        ];

        Promise.all(myPermissions)
          .then((responsesPermissions) => {
            responsesPermissions[0] && setResourceGeneratePdf(responsesPermissions[0]);
            responsesPermissions[1] && setResourceSendEmail(responsesPermissions[1]);
            responsesPermissions[2] && setResourceCancelFiscalSignature(responsesPermissions[2]);
          });

        if (responses[0].data.uuid && (!responses[0].data.xml_file_id || !responses[0].data.qr_file)) {
          try {
            const response = await GenericPatchResource(`/receipts/fiscalsignature/${receiptDetailPayload.receipt_id}`);

            if (response.data.XML !== undefined && response.data.QR !== undefined) {
              const files = {
                qr_file: response.data.QR,
                xml_file_id: response.data.XML
              };

              await GenericPutResource(`/receipts/${receiptDetailPayload.receipt_id}`, files);

              const responseFiles = await GetFiles(response.data.receipt_id, "Receipts");

              if (responseFiles && responseFiles.length > 0) {
                responseFiles.forEach(async (element) => {
                  if (element.file_id === (response.data.XML ?? 0)) {
                    await setFiscalXML(element);
                  }
                  if (element.file_id === (response.data.QR ?? 0)) {
                    await setFiscalQr(element);
                  }
                });
              }
            }
          } catch (error) {
            showSnackBar(t("generic.snackbar.try_to_save_files"), "warning");
          }
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <Header
            title={t("receipts.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button aria-describedby={id2} onClick={handleClick2}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { setOpenDialogCancelFiscalSignature(true); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={!resourceCancelFiscalSignature?.create}>
                      {t("salesinvoices.actions.cancel-fiscalsignature")}<CancelPresentationIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { onSendEmail(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={!resourceSendEmail?.create}>
                      {t("salesinvoices.actions.sendemail")}<EmailIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='pt-1'>
                  <Button aria-describedby={id1} onClick={handleClick1}>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { onGeneratePDF(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200 }} disabled={!resourceGeneratePdf?.read}>
                      {t("salesinvoices.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <Button variant='outlined' onClick={() => { setOpenDialogView(false); setClickedButtonGoBack((prev) => !prev) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />

          {permissionsReceipts?.read &&
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-50">
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <Typography style={{ fontWeight: "bold" }}>{t("customers.title-view")}:</Typography>
                      <Typography>{receiptLocal?.customer_business_name}</Typography>
                      <Typography>{receiptLocal?.customer_rfc}</Typography>
                      <Typography>{receiptLocal?.customer_tax_regime_code} - {receiptLocal?.customer_tax_regime_description}</Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} sx={{ paddingRight: 1 }}>
                      <>
                        <Box>
                          {isNotSelectedUuid &&
                            <FormControlLabel
                              checked={true}
                              control={<Checkbox />}
                              disabled={true}
                              label={t("receipts.info.sales-invoice-nouuid")}
                            />
                          }
                        </Box>
                      </>
                    </Box>
                  </Box>
                  <Box sx={{ paddingRight: 1, marginBottom: 0.5 }}>
                    <Divider />
                  </Box>
                  <div className="d-flex w-100">
                    <TextField
                      variant="filled"
                      label={`${t("receipts.fields.receipt_name")}`}
                      value={receiptLocal?.receipt_name}
                      size="small"
                      sx={{ width: "100%", paddingRight: 1 }}
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column-reverse w-50">
                  <div className="d-flex mt-2">
                    <TextField
                      sx={{ width: "60%", paddingRight: 1 }}
                      variant="filled"
                      label={`${t("receipts.fields.total_amount")}`}
                      value={setFormatNumber({ value: receiptLocal?.total_amount ?? 0 })}
                      size="small"
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                    <TextField
                      sx={{ width: "40%" }}
                      label={`${t("currencies.title-view")}`}
                      variant="filled"
                      value={`${receiptLocal?.currency_code ?? ''} - ${receiptLocal?.currency_description ?? ''}`}
                      size="small"
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </div>
                  <Divider />
                  <div>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box>
                        <Typography style={{ fontWeight: "bold" }}>{t("companies.title-view")}:</Typography>
                        <Typography>{`${receiptLocal?.company_business_name}`}</Typography>
                        <Typography>{`${receiptLocal?.company_rfc}`}</Typography>
                        <Typography>{receiptLocal?.company_tax_regime_code} - {receiptLocal?.company_tax_regime_description}</Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>

              <Divider />

              <div className="d-flex">
                <TextField
                  label={`${t("paymentmethods.title-view")}`}
                  variant="filled"
                  value={`${receiptLocal?.payment_method_code ?? ''} - ${receiptLocal?.payment_method_name ?? ''}`}
                  sx={{ width: "50%", paddingRight: 1 }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
                <TextField
                  label={`${t("receipts.fields.receipt_date")}`}
                  variant="filled"
                  value={TimeConverter(receiptLocal?.receipt_date?.toString())}
                  sx={{ width: "50%" }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
              </div>
              <Divider />
              <Box className="d-flex w-100">
                <Box sx={{ width: "50%" }} className="d-flex custom-card-view">
                  <Box className="w-100 d-flex flex-column">
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.account_alias")}:
                        </Typography>
                        {receiptLocal?.customer_account_alias}
                      </Typography>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.bank_references")}:
                        </Typography>
                        {receiptLocal?.customer_bank_references}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.bank_name")}:
                          </Typography>
                          {receiptLocal?.customer_bank_name}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("currencies.title-view")}:
                          </Typography>
                          {receiptLocal?.customer_bank_currency_description} - {receiptLocal?.customer_bank_currency_code}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.card_number")}:
                          </Typography>
                          {receiptLocal?.customer_bank_card_number}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.account_number")}:
                          </Typography>
                          {receiptLocal?.customer_account_number}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.interbank_key")}:
                          </Typography>
                          {receiptLocal?.customer_bank_interbank_key}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.swift_code")}:
                          </Typography>
                          {receiptLocal?.customer_bank_swift_code}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "1%" }} display={"flex"} justifyContent={"center"}>
                  <Divider />
                </Box>
                <Box sx={{ width: "50%" }} className="d-flex custom-card-view">
                  <Box className="w-100">
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.account_alias")}:
                        </Typography>
                        {receiptLocal?.company_account_alias}
                      </Typography>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.bank_references")}:
                        </Typography>
                        {receiptLocal?.company_bank_references}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.bank_name")}:
                          </Typography>
                          {receiptLocal?.company_bank_name}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("currencies.title-view")}:
                          </Typography>
                          {receiptLocal?.company_bank_currency_description} - {receiptLocal?.company_bank_currency_code}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.card_number")}:
                          </Typography>
                          {receiptLocal?.company_bank_card_number}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.account_number")}:
                          </Typography>
                          {receiptLocal?.company_account_number}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.interbank_key")}:
                          </Typography>
                          {receiptLocal?.company_bank_interbank_key}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.swift_code")}:
                          </Typography>
                          {receiptLocal?.company_bank_swift_code}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Divider />
            </>
          }

          <DialogReceiptDetail
            hasFiscalSignature={isNotSelectedUuid}
            Receipt={
              permissionsReceiptsDetails?.read &&
              <TabRelatedDocuments
                receiptsDetailsData={receiptsDetailsData}
              />
            }
            Stamping={
              permissionsReceipts?.read && (loadedStampingInfo || !loadedStampingInfo) &&
              <Stamping
                receiptLocal={receiptLocal}
                QR={fiscalQr}
                XML={fiscalXML}
              />
            }
            Statuses={
              <TabStatuses receiptsDetailsData={receiptsDetailsData} />
            }
          />
          <DialogCancelFiscalSignatureReceipt
            open={openDialogCancelFiscalSignature}
            setOpen={setOpenDialogCancelFiscalSignature}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onAcept={onCancelFiscalSignature}
            comboCancellationReasons={comboCancellationReasons}
            cancellationReasonId={cancellationReasonId}
            setCancellationReasonId={setCancellationReasonId}
            replacementFiscalFolio={replacementFiscalFolio}
            setReplacementFiscalFolio={setReplacementFiscalFolio}
          />
          <DialogSendEmailReceipt
            open={openEmail}
            setOpen={setOpenEmail}
            dataReceiptsDetailsSelected={dataReceiptsDetailsSelected}
            receiptLocal={receiptLocal}
            setReceiptLocal={setReceiptLocal}
          />
        </>
      }
      <SnackbarComponent />
    </>
  )
}
