import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDates } from "../../../../hooks/useDates";
import GenericPromises from "../../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { ViewReceiptDetails } from "./view";
import { Button } from "@mui/material";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { CancelledReceiptDetail } from "../../../../interfaces/Sales/CancelledReceipts/receipts";

export const TableCancelledReceiptsDetails = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogView, setOpenDialogView] = useState(false);
  const [clickedButtonGoBack, setClickedButtonGoBack] = useState(false);
  const [cancelledReceiptDetailPayload, setCancelledReceiptDetailPayload] = useState<CancelledReceiptDetail>({});
  const [cancelledreceiptsDetailsData, setCancelledReceiptsDetailsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceCancelledReceipts, setResourceCancelledReceipts] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([])
  const [columns, setColumns] = useState<GridColDef<CancelledReceiptDetail>[]>([
    {
      field: 'sales_invoice_name', headerName: t("salesinvoices.fields.sales_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
    },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
    },
    { field: 'customer_business_name', headerName: t("receiptsdetails.fields.customer_bussines_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'receipt_name', headerName: t("receipts.fields.receipt_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'uuid', headerName: t("receipts.fields.uuid"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("receiptsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'amount_outstanding', headerName: t("receiptsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: 'left', headerAlign: 'left',
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'payment_deadline', headerName: t("receiptsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'user_mail_send', headerName: t("receipts.fields.user_mail_send"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'date_mail_send', headerName: t("receipts.fields.date_mail_send"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-cancelledreceiptsdetails");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-cancelledreceiptsdetails-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CancelledReceiptDetail, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
        else {
          if ((element.field === 'amount_outstanding') || (element.field === 'amount_outstanding')) {
            element.valueGetter = function (params: GridValueGetterParams<CancelledReceiptDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'sales_invoice_name') {
            element.headerName = t(`salesinvoices.fields.sales_invoice_name`);
          }
          else if (element.field === 'uuid') {
            element.headerName = t(`receipts.fields.uuid`);
          }
          else if (element.field === 'project_name') {
            element.headerName = t(`projects.fields.project_name`);
          }
          else {
            element.headerName = t(`receiptsdetails.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    setDataLoaded(false);
    GenericGetResource(`/cancelledreceiptsdetails`)
      .then(
        (response) => {
          setCancelledReceiptsDetailsData(response.data.data);

          let myPermissions = [
            GetResourceByUrl(`/cancelledreceipts`),
            GetResourceByUrl(`/cancelledreceiptsdetails`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourceCancelledReceipts((prev) => responses[0]);
              setResourceScreen((prev) => responses[1]);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
  }, [clickedButtonGoBack])


  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("cancelledreceiptsdetails.title")}`;
    })

    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/cancelledreceiptsdetails`)
      .then(
        (response) => {
          setCancelledReceiptsDetailsData(response.data.data);

          let myPermissions = [
            GetResourceByUrl(`/cancelledreceipts`),
            GetResourceByUrl(`/cancelledreceiptsdetails`),
          ]
          Promise.all(myPermissions)
            .then((responses) => {
              setResourceCancelledReceipts((prev) => responses[0]);
              setResourceScreen((prev) => responses[1]);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, "error");
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [cancelledreceiptsDetailsData]);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read &&
        <div className='screen-container'>
          <Header
            title={t("cancelledreceiptsdetails.title")}
            child={<></>
            }
          />
          <div className="d-flex justify-content-center mt-2">
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={cancelledreceiptsDetailsData}
              entityId={"cancelled_receipt_detail_id"}
              entity={`CancelledReceiptsDetails`}
              preferences={myPreferences}
              namePreferences={"grid-cancelledreceiptsdetails"}
              nameOrderColumns={"grid-cancelledreceiptsdetails-columns"}
              selectRows
              isChildren={true}
              setOpenDialog={setOpenDialogView}
              setDataRow={setCancelledReceiptDetailPayload}
              initFilter={defaultFilter}
              conditionalOpenRow={`cancelled_receipt_id`}
              countColumns={["total_company_currency_amount"]}
            />
          </div>
          <DialogEntity
            open={openDialogView}
            setOpen={setOpenDialogView}
            title={
              <></>
            }
            fullWidth
            content={
              <ViewReceiptDetails
                cancelledReceiptDetailPayload={cancelledReceiptDetailPayload}
                permissionsCancelledReceiptsDetails={resourceScreen}
                permissionsCancelledReceipts={resourceCancelledReceipts}
                setOpenDialogView={setOpenDialogView}
                setClickedButtonGoBack={setClickedButtonGoBack}
              /* UpdateRow={handleUpdateRow} */
              />
            }
          />
          <SnackbarComponent />

        </div>
      }
    </>
  )
}
