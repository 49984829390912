import { useTranslation } from 'react-i18next';
import { AlertColor, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemAvatar, TextField, Typography } from '@mui/material';
import { ButtonLoading } from '../../../../theme/buttons';
import { GridValidRowModel } from '@mui/x-data-grid';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useContext, useEffect, useState } from 'react';
import GenericPromises from '../../../../api/GenericPromises';
import { MessageMails } from '../../../../interfaces/Commons/messagesMails';
import { AuthContext } from '../../../../context/AuthContext';
import { CompanyConfiguration } from '../../../../interfaces/Security/companies';
import { emailSeparatedByCommas } from '../../../../constants/Regex';
import { useSendEmails } from '../../../../hooks/useSendEmails';

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  data: readonly GridValidRowModel[],
  showSnackBar: (message: string, severity: AlertColor) => void
}

export const DialogSendEmails = ({ open, setOpen, data: initialData, showSnackBar }: DialogProps) => {
  const [t] = useTranslation("global");
  const [data, setData] = useState<readonly GridValidRowModel[]>(initialData);
  const [progress, setProgress] = useState(false);
  const [errors, setErrors] = useState<{ [key: number]: string }>({});
  const { user } = useContext(AuthContext);
  const [company, setcompany] = useState<CompanyConfiguration>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericPatchResource, GenericPutResource, GenericGetResourceGeneric } = GenericPromises();
  const { ReplaceMessages } = useSendEmails();

  const onChangeEmails = (event: any, sales_invoice_id: number) => {
    let email = event.target.value;
    // Limpia la cadena: elimina espacios alrededor de las comas
    let myCleanEmails = email.trim().replace(/\s*,\s*/g, ',');
    let myEmails = myCleanEmails.split(',');

    // Define la expresión regular para validar correos electrónicos
    const emailRegex = emailSeparatedByCommas;

    let hasError = false;
    let hasValidEmail = false;

    for (let i = 0; i < myEmails.length; i++) {
      const e = myEmails[i];

      if (e === '') {
        // Si el email está vacío (resultado de ", ,"), continuamos con el siguiente
        continue;
      }

      if (emailRegex.test(e)) {
        hasValidEmail = true;
      } else {
        hasError = true;
        setErrors(prevErrors => ({ ...prevErrors, [sales_invoice_id]: 'generic.send_email.email_no_valid' }));
        const updatedData = data.map(item =>
          (item.data.sales_invoice_id === sales_invoice_id)
            ? {
              ...item,
              status: 'Error',
              status_description: 'generic.send_email.email_no_valid',
              data: {
                ...item.data,
                customer_email: email
              }
            }
            : item
        );
        setData(updatedData);
        break;  // Salimos del bucle ya que encontramos un error
      }
    }

    if (!hasValidEmail) {
      hasError = true;
      setErrors(prevErrors => ({ ...prevErrors, [sales_invoice_id]: 'generic.send_email.email_no_valid' }));
      const updatedData = data.map(item =>
        (item.data.sales_invoice_id === sales_invoice_id)
          ? {
            ...item,
            status: 'Error',
            status_description: 'generic.send_email.email_no_valid',
            data: {
              ...item.data,
              customer_email: email
            }
          }
          : item
      );
      setData(updatedData);
    }

    // Si no hay errores, actualizamos los datos con el estado "Waiting"
    if (!hasError) {
      setErrors(prevErrors => ({ ...prevErrors, [sales_invoice_id]: '' }));
      const updatedData = data.map(item =>
        (item.data.sales_invoice_id === sales_invoice_id)
          ? {
            ...item,
            data: {
              ...item.data,
              customer_email: email
            },
            status: "Waiting",
            status_description: "generic.send_email.waiting"
          }
          : item
      );
      setData(updatedData);
    }
  };

  const onSend = async () => {
    setProgress(true);
    const updatedData = data.map(item => ({
      ...item,
      status: 'Process',
      status_description: 'generic.send_email.processing'
    }));
    setData(updatedData);

    await GenericGetResource(`/messagesmails`).then(async (response) => {
      let messages = response.data.data
      if (messages.length < 1) {
        showSnackBar(t('generic.send_email.no_messages'), "error");
      }
      for (let i = 0; i < updatedData.length; i++) {
        let messagesLanguajes = messages.filter((element: MessageMails) => (element.language_code.toLocaleUpperCase() === data[i].data.customer_language.toLocaleUpperCase()) && (element.consecutive_id === data[i].data.id_consecutive));
        let messagesEmails;
        if (messagesLanguajes.length > 0)
          messagesEmails = messagesLanguajes[0];
        else {
          messagesEmails = messages.find((element: MessageMails) => (element.main === true) && (element.consecutive_id === data[i].data.id_consecutive));
        }
        if (messagesEmails == undefined) {
          const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_messages' };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          continue;
        }

        let files: number[] = [];
        if (typeof data[i].data.sales_invoice_XML === "number") {
          files.push(data[i].data.sales_invoice_XML);
        }
        else {
          const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_xml' };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          continue;
        }
        if (typeof data[i].data.sales_invoice_pdf === "number") {
          files.push(data[i].data.sales_invoice_pdf);
        }
        else {
          const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_pdf' };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          continue;
        }

        let dataMessage = {
          emails: data[i].data.customer_email,
          subject: ReplaceMessages(messagesEmails.subject, data[i].data),
          body: ReplaceMessages(messagesEmails.body, data[i].data),
          files: files,
          company: {
            host_mail: company?.host_mail,
            port_mail: company?.port_mail,
            username_mail: company?.username_mail,
            password_mail: company?.password_mail,
            is_ssl_mail: company?.is_ssl_mail ?? false,
          }
        };

        try {
          await GenericPatchResource("/salesinvoices/sendemail", dataMessage);

          // Actualizar la factura de ventas
          let UpdateSalesInvoices = {
            date_mail_send: new Date(),
            user_mail_send: user?.user_email
          };
          await GenericPutResource(`/salesinvoices/${data[i].data.sales_invoice_id}`, UpdateSalesInvoices).then(() => {
            const updatedItem = { ...data[i], status: 'Send', status_description: 'generic.send_email.sent' };
            setData(prevData => {
              const newData = [...prevData];
              newData[i] = updatedItem;
              return newData;
            });
          }).catch((error) => {
            const updatedItem = { ...data[i], status: 'Error', status_description: error.message };
            setData(prevData => {
              const newData = [...prevData];
              newData[i] = updatedItem;
              return newData;
            });
          });
        } catch (error) {
          const updatedItem = { ...data[i], status: 'Error', status_description: error };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          setProgress(false);
        }
      }
      setProgress(false);
    })
  };

  const validateButtonSendEmail = (): boolean => {
    let myValidation = data.findIndex(
      (element) =>
        // si tiene error
        (element.status === 'Error') ||
        // esta en estado diferente de espera
        (element.status !== 'Waiting')
    );
    return myValidation === -1 ? false : true;
  }

  useEffect(() => {
    setDataLoaded(false);
    setErrors({})
    GenericGetResourceGeneric("/companyconfigurations/bycompanyid/1", "/gcompanyconfigurations")
      .then((company) => {
        setcompany(company.data.data[0])
        setData(initialData.map(item => {
          if (item.data.uuid == null) {
            return { ...item, status: 'Error', status_description: 'generic.send_email.nofiscal' };
          }
          return item;
        }));
        setProgress(false);
        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setOpen(false);
        setProgress(false);
      });
  }, [initialData]);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth={'lg'}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="responsive-dialog-title">
          {t("generic.send_email.title")}
        </DialogTitle>
        {!dataLoaded &&
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2
            }}
          >
            <CircularProgress size={35} />
          </Box>
        }
        {dataLoaded &&
          <>
            <DialogContent>
              <DialogContentText className='mb-2'>
                {t("generic.send_email.default_content")}
              </DialogContentText>
              <List>
                {data.map(item => (
                  <ListItem key={item.data.sales_invoice_id}>
                    <Box className='w-100' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box className="w-25 d-flex">
                        <ListItemAvatar>
                          {item.status == 'Error' ?
                            <ErrorOutlineRoundedIcon />
                            :
                            item.status == 'Waiting' ?
                              <HelpRoundedIcon />
                              :
                              item.status == 'Process' ?
                                <CircularProgress size={30} sx={{ color: 'primary.main' }} />
                                :
                                <CheckRoundedIcon />
                          }
                        </ListItemAvatar>
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant="body2" display="flex" alignItems="center">
                            {item.data.sales_invoice_name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="w-75">
                        <TextField
                          variant="standard"
                          value={item.data.customer_email}
                          fullWidth
                          InputProps={{ sx: { height: 25 } }}
                          disabled={progress || item.data.uuid == null}
                          onChange={(event) => { onChangeEmails(event, item.data.sales_invoice_id); }}
                          error={!!errors[item.data.sales_invoice_id]}
                        />
                      </Box>
                      <Box className="w-25 d-flex ps-3" sx={{ justifyContent: 'flex-end' }}>
                        <Typography
                          sx={{ color: item.status === 'Error' ? 'error.main' : item.status === 'Waiting' ? '#0277bd' : item.status === 'Process' ? '#00838f' : 'green' }}
                          variant="body2"
                          display="flex"
                          alignItems="center"
                        >
                          {t(item.status_description)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" className="m-1" disabled={progress} onClick={() => { setOpen(false); }}>{t("generic.buttons.goback")}</Button>
              <ButtonLoading
                isLoading={progress}
                variant="outlined"
                type="button"
                onClick={() => { if (progress) { setOpen(false); } else { onSend(); } }}
                className="btn m-1"
                disabled={validateButtonSendEmail()}
              >
                {t("generic.buttons.accept")}
              </ButtonLoading>
            </DialogActions>
          </>
        }
      </Dialog>
    </>
  )
}
