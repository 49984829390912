import { AlertColor, Avatar, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { FileSQL } from "../../../../../interfaces/Commons/files";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { useFiles } from "../../../../../hooks/useFiles";

type AddDesingProps = {
    showSnackBar: (message: string, severity: AlertColor) => void,
    imageLoadedRpt: FileSQL;
    setImageLoadedRpt: Dispatch<SetStateAction<FileSQL>>;
    isEdit?: boolean;
}

export const AddDesingCompanyLocation = ({ isEdit = false, showSnackBar, imageLoadedRpt, setImageLoadedRpt }: AddDesingProps) => {
    const [t] = useTranslation("global");
    const { DecodeFileSQLToBase64 } = useFiles();

    const limitsImage = {
        //alto x ancho
        iconMaxHeight: 300, iconMaxWidth: 300,
        iconMinHeight: 64, iconMinWidth: 64,

        topBarmaxHeight: 4000, topBarMaxWidth: 5000,
        topBarMinHeight: 400, topBarMinWidth: 1000,

        rptMaxHeight: 4000, rptMaxWidth: 5000,
        rptMinHeight: 400, rptMinWidth: 1000,
    };

    const filterImage = async (file: globalThis.File, maxWidth: number, maxHeight: number, minxWidth: number, minHeight: number,) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                if (image.width <= maxWidth && image.height <= maxHeight && image.width >= minxWidth && image.height >= minHeight) {
                    resolve(true);
                } else {
                    showSnackBar(t("companies.dialog.nofile"), "error");
                    resolve(false);
                }
            };
            image.onerror = () => {
                reject(t("companies.dialog.nofile"));
            };
        });
    }

    return (
        <>
            <div className="w-100">
                <div className='pb-2'>
                    <Typography sx={{ ml: 2 }}>{t("companies.fields.rpt_image")}</Typography>
                    <Avatar className='mx-3' sx={{ bgcolor: '#eeeeee', border: '1px solid grey', height: 90, width: 300 }} variant='rounded' alt={""}>
                        <Button component="label" disabled={isEdit}>
                            {!imageLoadedRpt.data_file &&
                                <img alt='report' width={"100%"} height={"100%"} src={require("../../../../../assets/img/profile.webp")} />
                            }
                            {imageLoadedRpt.data_file &&
                                <img width={"100%"} height={"100%"} alt="" src={imageLoadedRpt.data_file} />
                            }
                            <input type="file" accept="image/*" hidden
                                onChange={async (e) => {
                                    let file = e.target.files;
                                    if (file && file.length > 0 && await filterImage(file[0], limitsImage.rptMaxWidth, limitsImage.rptMaxHeight, limitsImage.rptMinWidth, limitsImage.rptMinHeight)) {
                                        await DecodeFileSQLToBase64(e).then(async (response) => {
                                            await setImageLoadedRpt(response);
                                        });
                                    }
                                }
                                }
                            />
                        </Button>
                    </Avatar>
                    <Typography sx={{ ml: 2, fontSize: 10 }}>{t("companies.desing.limit_size")}: {limitsImage.rptMinHeight} x {limitsImage.rptMinWidth}px - {limitsImage.rptMaxHeight} x {limitsImage.rptMaxWidth}px</Typography>
                </div>
            </div>
        </>
    )
}