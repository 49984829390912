import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { FormsErrors } from '../../../../../hooks/Forms/FormsErrors';
import { CompanyLocation } from '../../../../../interfaces/Security/companies';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { PrimaryButton } from '../../../../../theme/buttons';
import { Country } from '../../../../../interfaces/Commons/Locations/countries';
import { State } from '../../../../../interfaces/Commons/Locations/states';
import { City } from '../../../../../interfaces/Commons/Locations/cities';
import { GenericDialog } from '../../../../../components/Dialogs/Dialog';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { AddSignatureTab } from './addSignature';
import { AddDesingCompanyLocation } from './addDesing';
import { AddGeneralCompanyLocation } from './addGeneral';
import CompanyLocationsDetail from './companyLocations';
import { TaxRegime } from '../../../../../interfaces/Sales/Catalogs/taxRegimes';
import { FileSQL } from '../../../../../interfaces/Commons/files';

export const UpdateCompanyLocation = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const { GenericPutResource, GenericDeleteResource, GenericGetResource, GenericPostResource } = GenericPromises();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [defaultCountry, setDefaultCountry] = useState<Country>();
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [defaultState, setDefaultState] = useState<State>();
  const [comboCities, setComboCities] = useState<City[]>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { companyLocationPayload, permissions } = props;
  const isEdit = !permissions.update;

  const [comboTaxRegimes, setComboTaxRegimes] = useState<TaxRegime[]>([]);
  const [defaultTaxRegime, setDefaultTaxRegime] = useState<TaxRegime>();
  const [fileKey, setFileKey] = useState<FileSQL>({});
  const [fileCer, setFileCer] = useState<FileSQL>({});
  const [imageLoadedRpt, setImageLoadedRpt] = useState<FileSQL>({});

  const [isStateSelected, setIsStateSelected] = useState(false);
  const [stateId, setStateId] = useState<number | undefined>();

  const [city, setCity] = useState<City | null>(null)

  const getCities = (stateId: number) => {
    GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setDataLoaded(true);
      });
  };

  useEffect(() => {
    if (stateId !== undefined) {
      if (stateId !== companyLocationPayload.state_id) {
        setCity(null)
      }
      setIsStateSelected(true);
      getCities(stateId)
      return;
    }
    setIsStateSelected(false);
  }, [stateId])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
    trigger
  } = useForm<CompanyLocation>({
    defaultValues: {
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: undefined,
      street: undefined,
      contact_name: undefined,
      phone_number: undefined,
      email: undefined,
      company_id: undefined,
      main_location: undefined,
    }
  });
  const onSubmit: SubmitHandler<CompanyLocation> = (data) => onPut(data);

  const onPut = (data: CompanyLocation) => {
    trigger().then((responseTrigger) => {
      if (responseTrigger &&
        data.business_name !== undefined &&
        data.tax_regime_id !== undefined &&
        data.company_rfc !== undefined &&
        data.city_id !== undefined &&
        data.country_id !== undefined && //TODO : Que revise desde otras tabs
        data.state_id !== undefined) {
        let myData = {
          country_id: data.country_id ?? null,
          state_id: data.state_id ?? null,
          city_id: data.city_id ?? null,
          postal_code: data.postal_code ?? null,
          street: data.street ?? null,
          contact_name: data.contact_name ?? null,
          phone_number: data.phone_number ?? null,
          email: data.email ?? null,
          company_id: props.company_id ?? null,
          main_location: data.main_location ?? false,
          business_name: data.business_name,
          company_rfc: data.company_rfc,
          tax_regime_id: data.tax_regime_id,
          fiscal_address: data.fiscal_address ?? false,
        }
        let myCountry = comboCountries.find((item: Country) => item.country_id === data.country_id);
        let myState = comboStates.find((item: State) => item.state_id === data.state_id);
        let myCity = comboCities.find((item: City) => item.city_id === data.city_id);
        setLoadingPost(true);
        GenericPutResource(`/companylocations/${companyLocationPayload.company_location_id}`, myData)
          .then(async (response) => {
            let myUpdateData: CompanyLocation = {
              ...response.data,
              country_name: myCountry?.country_name,
              state_name: myState?.state_name,
              city_name: myCity?.city_name,
            };
            if (response.data.main_location === true) {
              GenericGetResource(`/companylocations/bycompanyid/${props.company_id}`)
                .then(async (response1) => {
                  for (let i = 0; i < response1.data.data.length; i++) {
                    const element = response1.data.data[i];
                    if (element.company_location_id !== response.data.company_location_id) {
                      let myData = {
                        state_id: element.state_id,
                        city_id: element.city_id,
                        postal_code: element.postal_code,
                        street: element.street,
                        contact_name: element.contact_name,
                        phone_number: element.phone_number,
                        email: element.email,
                        company_id: element.company_id,
                        main_location: false,
                      };
                      await GenericPutResource(`/companylocations/${element.company_location_id}`, myData)
                        .then((response2) => {
                          props.UpdateRow(response2.data);
                        })
                        .catch((error) => {
                          setLoadingPost(false);
                          showSnackBar(error.message, "error");
                        });
                    }
                  }
                })
                .catch((error) => {
                  setLoadingPost(false);
                  showSnackBar(error.message, 'error');
                });
            }
            if (response.data.company_location_id) {
              //Signature
              let myData = {
                fiscal_password: data.fiscal_password ?? null,
                name_certificate_file: fileCer.file_name ?? null,
                certificate_file: fileCer.data_file ?? null,
                name_key_file: fileKey.file_name ?? null,
                key_file: fileKey.data_file ?? null
              }
              if (myUpdateData.company_signature_id && myUpdateData.company_signature_id > 0) {
                await GenericPutResource(`/companysignature/${myUpdateData.company_signature_id}`, myData)
                  .catch((error) => {
                    setLoadingPost(false);
                    showSnackBar(error.message, "error");
                    setLoadingPost(false);
                  });
              }
              else {
                await GenericPostResource(`/companysignature`, myData)
                  .then(async (responseCompanySignature) => {
                    // put company with id 
                    let myPutData = {
                      company_signature_id: responseCompanySignature.data.company_signature_id,
                    }
                    await GenericPutResource(`/companylocations/${response.data.company_location_id ?? 0}`, myPutData)
                      .then(async (responseCompanyPut) => {
                        myUpdateData = {
                          ...responseCompanyPut.data,
                          country_name: myCountry?.country_name,
                          state_name: myState?.state_name,
                          city_name: myCity?.city_name,
                        };
                      })
                  })
                  .catch((error) => {
                    setLoadingPost(false);
                    showSnackBar(error.message, "error");
                    setLoadingPost(false);
                  });
              }
              //Desing
              if (imageLoadedRpt.data_file) {
                await onPostImage(imageLoadedRpt, response.data)
              }
            }
            props.UpdateRow(myUpdateData);
            showSnackBar(t("generic.snackbar.update"), "success");
            setLoadingPost(false);
            props.setOpenDialog(false);
          })
          .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, "error");
            setLoadingPost(false);
          });
      }
    })
  }

  const onDelete = () => {
    GenericDeleteResource(`/companylocations/${companyLocationPayload?.company_location_id}`)
      .then(() => {
        setOpenDialog(false);
        props.DeleteRow(companyLocationPayload?.company_location_id);
        props.setOpenDialog(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
      GenericGetResource("/taxregimes"),
    ]

    Promise.all(myPromises)
      .then(async (responses) => {
        setComboCountries((prev) => responses[0].data.data);
        setComboStates((prev) => responses[1].data.data);
        setComboCities((prev) => responses[2].data.data);
        setComboTaxRegimes((prev) => responses[3].data.data);

        await reset({
          country_id: companyLocationPayload.country_id ?? undefined,
          state_id: companyLocationPayload.state_id ?? undefined,
          city_id: companyLocationPayload.city_id ?? undefined,
          tax_regime_id: companyLocationPayload.tax_regime_id ?? undefined,
          postal_code: companyLocationPayload.postal_code ?? undefined,
          street: companyLocationPayload.street ?? undefined,
          contact_name: companyLocationPayload.contact_name ?? undefined,
          phone_number: companyLocationPayload.phone_number ?? undefined,
          email: companyLocationPayload.email ?? undefined,
          main_location: companyLocationPayload.main_location ?? false,
          fiscal_address: companyLocationPayload.fiscal_address ?? false,
          business_name: companyLocationPayload.business_name ?? undefined,
          company_rfc: companyLocationPayload.company_rfc ?? undefined,
        });

        if (companyLocationPayload.company_signature_id && companyLocationPayload.company_signature_id > 0) {
          await GenericGetResource(`/companysignature/${companyLocationPayload.company_signature_id}`).then((responseSignature) => {
            let myfileCer = {
              file_name: responseSignature.data.name_certificate_file,
              data_file: responseSignature.data.certificate_file,
            }
            let myfileKey = {
              file_name: responseSignature.data.name_key_file,
              data_file: responseSignature.data.key_file,
            }
            setFileCer(myfileCer);
            setFileKey(myfileKey);
            reset({
              fiscal_password: responseSignature.data.fiscal_password ?? undefined,
            },{keepValues : true})
          })
        }

        if (companyLocationPayload.rpt_image) {
          await GenericGetResource(`/filessql/${companyLocationPayload.rpt_image}`)
              .then((responseIcon) => {
                  setImageLoadedRpt(responseIcon.data);
              });
      }

        setValue("fiscal_address", companyLocationPayload.fiscal_address ?? false);
        setValue("main_location", companyLocationPayload.main_location ?? false);

        setDefaultCountry(responses[0].data.data.find((item: Country) => item.country_id === companyLocationPayload.country_id));
        setDefaultState(responses[1].data.data.find((item: State) => item.state_id === companyLocationPayload.state_id));
        setStateId(responses[1].data.data.find((state: State) => state.state_id === companyLocationPayload.state_id).state_id);
        setCity(responses[2].data.data.find((city: City) => city.city_id === companyLocationPayload.city_id));
        setDefaultTaxRegime(responses[3].data.data.find((taxRegime: TaxRegime) => taxRegime.tax_regime_id === companyLocationPayload.tax_regime_id));

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPostImage = async (image: FileSQL, companyLocation: CompanyLocation) => {
    let myData = {
      file_name: image.file_name,
      type_of_file: image.type_of_file,
      data_file: image.data_file,
    }
    await GenericPostResource(`/filessql`, myData)
      .then(async (response) => {
        await UpdateIdPhotoCompany(response.data, companyLocation);
        showSnackBar(t("generic.snackbar.update_photo"), "success");
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
  }

  const UpdateIdPhotoCompany = async (image: FileSQL, companyLocation: CompanyLocation) => {
    let myData = {
      rpt_image: image.file_sql_id ?? null,
    }
    if (!(companyLocation?.rpt_image)) {
      // si exite un id para esa foto eliminarla y actualizar registro en company
      await GenericPutResource(`/companylocations/${companyLocation?.company_location_id ?? 0}`, myData)
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }
    else {
      await GenericDeleteResource(`/filessql/${companyLocation?.rpt_image}`)
        .then(async () => {
          await GenericPutResource(`/companylocations/${companyLocation?.company_location_id ?? 0}`, myData)
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }

  }

  return (
    <>
      {dataLoaded &&
        <>
          <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

            <form onSubmit={handleSubmit(onSubmit)} className='w-100'>

              <CompanyLocationsDetail
                General={
                  <AddGeneralCompanyLocation
                    control={control}
                    errors={errors}
                    comboCountries={comboCountries}
                    comboStates={comboStates}
                    comboCities={comboCities}
                    getCities={getCities}
                    setValue={setValue}
                    comboTaxRegimes={comboTaxRegimes}
                    setIsStateSelected={setIsStateSelected}
                    setStateId={setStateId}
                    setCity={setCity}
                    isStateSelected={isStateSelected}
                    stateId={stateId}
                    city={city}
                    isMexican={true}
                    setDefaultTaxRegime={setDefaultTaxRegime}
                    setDefaultCountry={setDefaultCountry}
                    defaultCountry={defaultCountry}
                    defaultTaxRegime={defaultTaxRegime}
                    getValues={getValues}
                    isEdit={isEdit}
                    GetError={GetError}
                  />}
                Invoicing={<AddSignatureTab
                  control={control}
                  errors={errors}
                  fileKey={fileKey}
                  setFileKey={setFileKey}
                  fileCer={fileCer}
                  setFileCer={setFileCer}
                />}
                Desing={<AddDesingCompanyLocation
                  showSnackBar={showSnackBar}
                  imageLoadedRpt={imageLoadedRpt}
                  setImageLoadedRpt={setImageLoadedRpt}
                />}
              />

              <div className='d-flex flex-row-reverse mt-4 justify-content-between'>
                <div>
                  <Button variant='outlined' className='m-1' onClick={() => props.setOpenDialog(false)}>{t("generic.buttons.goback")}</Button>
                  {permissions.update && <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>}
                </div>
                {permissions.delete ?
                  <PrimaryButton onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</PrimaryButton> : <></>
                }
              </div>

            </form>

          </Box>

        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("companylocations.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}
