import { AlertColor, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemAvatar, TextField, Typography } from "@mui/material";
import { GridValidRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { useSendEmails } from "../../../../hooks/useSendEmails";
import { AuthContext } from "../../../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { CompanyConfiguration } from "../../../../interfaces/Security/companies";
import { emailSeparatedByCommas } from "../../../../constants/Regex";
import { MessageMails } from "../../../../interfaces/Commons/messagesMails";
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { ButtonLoading } from "../../../../theme/buttons";

interface DialogProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  dataPaymentsDetailsSelected: readonly GridValidRowModel[],
  showSnackBar: (message: string, severity: AlertColor) => void
}

export const DialogSendEmailsTable = ({
  open,
  setOpen,
  dataPaymentsDetailsSelected,
  showSnackBar,
}: DialogProps) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource, GenericPatchResource } = GenericPromises();
  const { ReplaceMessages, GetCompanyConfiguration } = useSendEmails();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [progress, setProgress] = useState(false);
  const [data, setData] = useState<readonly GridValidRowModel[]>(dataPaymentsDetailsSelected);
  const [company, setCompany] = useState<CompanyConfiguration>();
  const [errors, setErrors] = useState<{ [key: number]: string }>({});

  const onChangeEmails = (event: any, payment_id: number) => {
    let email = event.target.value;
    let myCleanEmails = email.trim().replace(/\s*,\s*/g, ',');
    let myEmails = myCleanEmails.split(',');

    const emailRegex = emailSeparatedByCommas;

    let hasError = false;
    let hasValidEmail = false;

    for (let i = 0; i < myEmails.length; i++) {
      const e = myEmails[i];

      if (e === '') {
        continue;
      }

      if (emailRegex.test(e)) {
        hasValidEmail = true;
      } else {
        hasError = true;
        setErrors(prevErrors => ({ ...prevErrors, [payment_id]: 'generic.send_email.email_no_valid' }));
        const updatedData = data.map(item =>
          (item.data.payment_id === payment_id)
            ? {
              ...item,
              status: 'Error',
              status_description: 'generic.send_email.email_no_valid',
              data: {
                ...item.data,
                supplier_email: email
              }
            }
            : item
        );
        setData(updatedData);
        break;
      }
    }

    if (!hasValidEmail) {
      hasError = true;
      setErrors(prevErrors => ({ ...prevErrors, [payment_id]: 'generic.send_email.email_no_valid' }));
      const updatedData = data.map(item =>
        (item.data.payment_id === payment_id)
          ? {
            ...item,
            status: 'Error',
            status_description: 'generic.send_email.email_no_valid',
            data: {
              ...item.data,
              supplier_email: email
            }
          }
          : item
      );
      setData(updatedData);
    }

    if (!hasError) {
      setErrors(prevErrors => ({ ...prevErrors, [payment_id]: '' }));
      const updatedData = data.map(item =>
        (item.data.payment_id === payment_id)
          ? {
            ...item,
            data: {
              ...item.data,
              supplier_email: email
            },
            status: "Waiting",
            status_description: "generic.send_email.waiting"
          }
          : item
      );
      setData(updatedData);
    }
  };

  const onSend = async () => {
    setProgress(true);
    const updatedData = data.map(item => ({
      ...item,
      status: 'Process',
      status_description: 'generic.send_email.processing'
    }));
    setData(updatedData);

    await GenericGetResource(`/messagesmails`).then(async (response) => {
      let messages = response.data.data;
      if (messages.length < 1) {
        showSnackBar(t('generic.send_email.no_messages'), "error");
      }
      for (let i = 0; i < updatedData.length; i++) {
        let messagesLanguajes = messages.filter((element: MessageMails) => (element.language_code.toLocaleUpperCase() === data[i].data.supplier_language.toLocaleUpperCase()) && (element.consecutive_id === data[i].data.consecutive_id));
        let messagesEmails;
        if (messagesLanguajes.length > 0)
          messagesEmails = messagesLanguajes[0];
        else {
          messagesEmails = messages.find((element: MessageMails) => (element.main === true) && (element.consecutive_id === data[i].data.consecutive_id));
        }
        if (messagesEmails === undefined) {
          const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_messages' };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          continue;
        }

        let files: number[] = [];
        if (typeof data[i].data.xml_file_id === "number") {
          files.push(data[i].data.xml_file_id);
        } else {
          const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_xml' };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          continue;
        }
        if (typeof data[i].data.report_file_id === "number") {
          files.push(data[i].data.report_file_id);
        } else {
          const updatedItem = { ...data[i], status: 'Error', status_description: 'generic.send_email.no_pdf' };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          continue;
        }

        let dataMessage = {
          emails: data[i].data.supplier_email,
          subject: ReplaceMessages(messagesEmails.subject, data[i].data),
          body: ReplaceMessages(messagesEmails.body, data[i].data),
          files: files,
          company: {
            host_mail: company?.host_mail,
            port_mail: company?.port_mail,
            username_mail: company?.username_mail,
            password_mail: company?.password_mail,
            is_ssl_mail: company?.is_ssl_mail ?? false,
          }
        };

        try {
          await GenericPatchResource("/payments/sendemail", dataMessage);

          let UpdatePayments = {
            date_mail_send: new Date(),
            user_mail_send: user?.user_email
          };
          await GenericPutResource(`/payments/${data[i].data.payment_id}`, UpdatePayments)
            .then(() => {
              const updatedItem = { ...data[i], status: 'Send', status_description: 'generic.send_email.sent' };
              setData(prevData => {
                const newData = [...prevData];
                newData[i] = updatedItem;
                return newData;
              });
            }).catch((error) => {
              const updatedItem = { ...data[i], status: 'Error', status_description: error.message };
              setData(prevData => {
                const newData = [...prevData];
                newData[i] = updatedItem;
                return newData;
              });
            });
        } catch (error) {
          const updatedItem = { ...data[i], status: 'Error', status_description: error };
          setData(prevData => {
            const newData = [...prevData];
            newData[i] = updatedItem;
            return newData;
          });
          setProgress(false);
        }
      }
      setProgress(false);
    })
  }

  const validateButtonSendEmail = (): boolean => {
    let myValidation = data.findIndex(
      (element) =>
        (element.status === 'Error') ||
        (element.status !== 'Waiting')
    );
    return myValidation === -1 ? false : true;
  }

  useEffect(() => {
    if (open && dataPaymentsDetailsSelected.length > 0) {
      setData(dataPaymentsDetailsSelected);
      GetCompanyConfiguration()
        .then((response) => {
          setCompany(response.data.data[0]);
          setProgress(false);
          setDataLoaded(true);
        })
    }
    else {
      setData([]);
      setDataLoaded(false);
      setProgress(false);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth={'lg'}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="responsive-dialog-title">
          {t("generic.send_email.title")}
        </DialogTitle>
        {!dataLoaded &&
          <>
            {data.length > 0 &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2
                }}
              >
                <CircularProgress size={35} />
              </Box>
            }
            {data.length === 0 &&
              <>
                <Box display={"flex"} justifyContent={"space-between"} margin={4}>
                  <InfoIcon fontSize={"large"} />
                  <Typography>
                    {t("generic.send_email.no-details")}
                  </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"row-reverse"} margin={2}>
                  <Button variant="outlined" className="m-1" disabled={progress == true} onClick={() => { setOpen(false); }}>{t("generic.buttons.goback")}</Button>
                </Box>
              </>
            }
          </>
        }
        {dataLoaded &&
          <>
            <DialogContent>
              <DialogContentText className='mb-2'>
                {t("generic.send_email.default_content")}
              </DialogContentText>
              <List>
                {data.map(item => (
                  <ListItem key={item.data.payment_id}>
                    <Box className='w-100' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box className="w-25 d-flex">
                        <ListItemAvatar>
                          {item.status == 'Error' ?
                            <ErrorOutlineRoundedIcon />
                            :
                            item.status == 'Waiting' ?
                              <HelpRoundedIcon />
                              :
                              item.status == 'Process' ?
                                <CircularProgress size={30} sx={{ color: 'primary.main' }} />
                                :
                                <CheckRoundedIcon />
                          }
                        </ListItemAvatar>
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant="body2" display="flex" alignItems="center">
                            {item.data.payment_name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="w-75">
                        <TextField
                          variant="standard"
                          value={item.data.supplier_email}
                          fullWidth
                          InputProps={{ sx: { height: 25 } }}
                          disabled={progress || item.data.uuid == null}
                          onChange={(event) => { onChangeEmails(event, item.data.payment_id); }}
                          error={!!errors[item.data.payment_id]}
                        />
                      </Box>
                      <Box className="w-25 d-flex ps-3" sx={{ justifyContent: 'flex-end' }}>
                        <Typography
                          sx={{ color: item.status === 'Error' ? 'error.main' : item.status === 'Waiting' ? '#0277bd' : item.status === 'Process' ? '#00838f' : 'green' }}
                          variant="body2"
                          display="flex"
                          alignItems="center"
                        >
                          {t(item.status_description)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" className="m-1" disabled={progress} onClick={() => { setOpen(false); }}>{t("generic.buttons.goback")}</Button>
              <ButtonLoading
                isLoading={progress}
                variant="outlined"
                type="button"
                onClick={() => { if (progress) { setOpen(false); } else { onSend(); } }}
                className="btn m-1"
                disabled={validateButtonSendEmail()}>
                {t("generic.buttons.accept")}
              </ButtonLoading>
            </DialogActions>
          </>
        }
      </Dialog>
    </>
  )
}
