import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Avatar, Card, CardContent } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GenericDialog } from '../../Dialogs/Dialog';
import GenericPromises from '../../../api/GenericPromises';
import { File } from '../../../interfaces/Commons/files';
import { useCompanyStore } from '../../../store/AuthStore';

export default function PopMenu() {
    const [t] = useTranslation("global");
    const { user, logOut } = React.useContext(AuthContext);
    const { GenericGet } = GenericPromises();
    const { resetCompanyState } = useCompanyStore();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [objectId, setObjectId] = React.useState("");
    const [myProfileImage, setMyProfileImage] = React.useState<File>({ dataURL: "" });
    const navigate = useNavigate();

    React.useEffect(() => {
        if (user?.photo_id !== null && user?.user_id !== undefined) {
            GenericGet(`/files/recordId/${user.user_id}/entity/Users/schema/dbo`)
                .then(async (response) => {
                    let myFiles = response.data.data
                    if (myFiles !== undefined) {
                        for (let index = 0; index < response.data.data.length; index++) {
                            const element = response.data.data[index];
                            await GenericGet(`/files/${element.object_id}`)
                                .then(async (response1) => {
                                    element.dataURL = await `data:${element.type_of_file};base64,${response1.data.content}`;
                                });
                        }

                        if (myFiles && myFiles.length > 0) {
                            let photo: File;
                            photo = myFiles.find((photo: File) => photo.file_id === user?.photo_id) ?? {}
                            await setMyProfileImage(photo)
                            setObjectId(photo.object_id ?? '')
                            setDataLoaded(true);
                        }
                    }
                });
        }
    }, []);

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div>
                    <Button {...bindTrigger(popupState)}>
                        <Avatar sx={{
                            bgcolor: 'primary.main',
                            border: '1px solid white',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} variant='rounded' alt={user?.user_name}>
                            {!dataLoaded && (
                                <Typography>
                                    {user?.user_name.charAt(0)}
                                </Typography>
                            )}
                            {dataLoaded && (!myProfileImage.dataURL || myProfileImage.dataURL === "") ? (
                                <Typography>
                                    {user?.user_name.charAt(0)}
                                </Typography>
                            ) : (
                                dataLoaded && myProfileImage.dataURL && (
                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img alt='profile' src={myProfileImage.dataURL} style={{ objectFit: "cover", width: '100%', height: '100%' }} />
                                    </div>
                                )
                            )}
                        </Avatar>
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Card>
                            <CardContent className='d-flex flex-column align-items-end'>
                                <Button onClick={() => setOpenDialog(true)} variant='outlined'>
                                    <Typography fontSize={15}>{t("generic.logOut")}</Typography>
                                </Button>
                                <div className='d-flex'>
                                    <Button className='m-1' sx={{ borderRadius: 3 }} onClick={() => navigate("profile")}>
                                        <Avatar className='m-2'
                                            sx={{
                                                bgcolor: 'transparent',
                                                border: '1px solid white',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '70px', width: '70px'
                                            }}
                                            variant='rounded' alt={user?.user_name}>
                                            {!dataLoaded && (
                                                <Typography color={'black'}>
                                                    {user?.user_name.charAt(0)}
                                                </Typography>
                                            )}
                                            {dataLoaded && (!myProfileImage.dataURL || myProfileImage.dataURL === "") ? (
                                                <Typography color={'black'}>
                                                    {user?.user_name.charAt(0)}
                                                </Typography>
                                            ) : (
                                                dataLoaded && myProfileImage.dataURL && (
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img alt='profile' src={myProfileImage.dataURL} style={{ objectFit: "cover", width: '100%', height: '100%' }} />
                                                    </div>
                                                )
                                            )}
                                        </Avatar>
                                    </Button>
                                    <div className='d-flex flex-column m-2'>
                                        <span>{user?.user_name}</span>
                                        <span>{user?.user_email}</span>
                                        <span>{user?.group_name}</span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Popover>
                    <GenericDialog
                        open={openDialog}
                        setOpen={setOpenDialog}
                        title={t("generic.logOut")}
                        content={t("generic.logOutMessage")}
                        onAcept={async () => {
                            await localStorage.removeItem("schemaName")
                            await resetCompanyState();
                            await logOut()
                            window.location.reload();
                        }}
                    />
                </div>
            )}
        </PopupState>
    );
}
