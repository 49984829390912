interface ErrorDisplayProps {
  errorMessage: string;
  imageSrc?: string; 
  imageWidth?: number; 
  imageHeight?: number | 'auto'; 
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
  errorMessage,
  imageSrc = require('../../../assets/img/error.webp'), 
  imageWidth = 200, 
  imageHeight = 'auto',
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <img
        src={imageSrc}
        alt="Error"
        style={{ width: imageWidth, height: imageHeight }}
      />
      <p style={{ marginTop: '10px', color: 'red', fontSize: '16px' }}>
        {errorMessage}
      </p>
    </div>
  );
};