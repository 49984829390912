export const useGridPreferences = () => {

  const LoadPreferences = async (entity: string) => {
    let myPreferences = await localStorage.getItem(entity);
    if (myPreferences != null) {
      return JSON.parse(myPreferences);
    }
  }

  // TODO: loadColumnsOrder (gridEntity, prefixColumns)
  // generico de los campos de la entidad
  // que reciba param de un arreglo si es custom con otras vistas / campos
  // [{field: 'total_company_currency_amount', type: 'number'}] y opcional funciones de value getter o render cell
  // validar si no tiene una columna de la vista agregarla
  // las tres columnas genericas de control agregarlas aparte

  return {
    LoadPreferences,
  }
}
