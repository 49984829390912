import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvoiceDocumentType } from '../../../interfaces/Sales/Invoices/salesinvoices';
import GenericPromises from '../../../api/GenericPromises';
import { Menuitem } from '../../../interfaces/Security/menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import InvoiceDetail from './invoiceDetail';
import { usePermissions } from '../../../hooks/usePermissions';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, ListItemButton, Popover, TextField } from '@mui/material';
import { PaymentMethod } from '../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../interfaces/Sales/Catalogs/paymentModes';
import { Currency } from '../../../interfaces/Commons/currencies';
import { CFDI } from '../../../interfaces/Sales/Catalogs/CFDIs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useFiles } from '../../../hooks/useFiles';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import { ButtonStyled } from '../../../theme/buttons';
import { PaymentCondition } from '../../../interfaces/Sales/Catalogs/paymentConditions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';
import { SalesInvoicePDF } from '../../../reports/salesInvoicePDF/SalesInvoicePDF';
import { DialogSendEmailSingular } from './helpers/dialogSendEmailSingular';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { PurchaseInvoice, PurchaseInvoiceDetail } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { UpdatePurchaseInvoices } from './update';
import { UpdatePurchaseInvoicesHeader } from './updateInvoiceHeader';
import { Supplier, SupplierLocation } from '../../../interfaces/Purchases/Catalogs/suppliers';
import { AnnotationsPurchaseInvoices } from './Tabs/annotations';
import { Language } from '../../../interfaces/Commons/languages';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { CopyPurchaseInvoice } from './actions/copySalesInvoice';
import { Project } from '../../../interfaces/Projects/projects';
import { FinancialPurchaseInvoices } from './Tabs/financial';
import { SupplierBankAccount } from '../../../interfaces/Purchases/Catalogs/supplierBankAccounts';
import { useCurrencies } from '../../../hooks/useCurrencies';
import { TaxRegime } from '../../../interfaces/Sales/Catalogs/taxRegimes';
import { BankAccount } from '../../../interfaces/Commons/bankAccounts';
import { useBase64 } from '../../../hooks/useBase64';
import { AxiosError } from 'axios';
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */

export const ViewPurchaseInvoices = () => {
  const location = useLocation();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GetTodayExchangeRateFromCurrencyCode } = useCurrencies();
  const { TimeConverter } = useDates();
  const { GetFileById, GetFileIfExists } = useFiles();
  const { utf8ToBase64 } = useBase64();
  const { GenericPutResource, GenericPostResource, GenericPost, GenericGetResource, GenericPutAction, GenericGetReport, GenericDeleteResource, GenericGetResourceGeneric, GenericGet } = GenericPromises();
  const { generatePDF } = SalesInvoicePDF();
  const { GetError } = FormsErrors();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { GetResourceByUrl } = usePermissions();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetParamsFromBase64 } = useParamsFilter();
  const [dataloaded, setDataLoaded] = useState(false);
  const [isAccounted, setIsAccounted] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [openDialogCopyPurchaseInvoice, setOpenDialogCopyPurchaseInvoice] = useState(false);
  const [isDeletedInvoice, setIsDeletedInvoice] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [IsChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false);
  const [isNotExchange, setIsNotExchange] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const [hasFinalPaymentBankAccount, setHasFinalPaymentBankAccount] = useState(true);
  const [myLocalInvoiceDocType, setMyLocalInvoiceDocType] = useState(0);
  const [myLocalConsecutive, setMyLocalConsecutive] = useState("");
  const [myConsecutive, setMyConsecutive] = useState("");
  const [rows, setRows] = useState<PurchaseInvoiceDetail[]>([]);
  const [purchaseInvoiceLocal, setPurchaseInvoiceLocal] = useState<PurchaseInvoice>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [comboInvoiceDocumentTypes, setComboInvoiceDocumentTypes] = useState<InvoiceDocumentType[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [defaultInvoiceDocumentType, setDefaultInvoiceDocumentType] = useState<InvoiceDocumentType>();
  const [comboSupplierLocations, setComboSupplierLocations] = useState<SupplierLocation[]>([]);
  const [defaultSupplierLocation, setDefaultSupplierLocation] = useState<SupplierLocation>();
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>();
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [defaultDate, setDefaultDate] = useState<Date>(new Date());
  const [defaultDateSupplier, setDefaultDateSupplier] = useState<Date | undefined>();
  const [dataPurchaseInvoiceSelected, setDataPurchaseInvoiceSelected] = useState<any>([]);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([]);
  const [comboLanguages, setComboLanguages] = useState<Language[]>([]);
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [resourceCopySalesInvoice, setResourceCopySalesInvoice] = useState<Menuitem>();
  const [resourceAccounted, setResourceAccounted] = useState<Menuitem>();
  const [comboProjects, setComboProjects] = useState<Project[]>([]);
  const [defaultProject, setDefaultProject] = useState<Project>();
  const [comboSupplierBankAccounts, setComboSupplierBankAccounts] = useState<SupplierBankAccount[]>([]);
  const [defaultSupplierBankAccount, setDefaultSupplierBankAccount] = useState<SupplierBankAccount>();
  const [defaultExpirationDate, setDefaultExpirationDate] = useState<Date | undefined>();
  const [comboBankAccountsGeneral, setComboBankAccountsGeneral] = useState<BankAccount[]>([]);
  const [defaultBankAccountFinal, setDefaultBankAccountFinal] = useState<BankAccount>();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    trigger,
  } = useForm<PurchaseInvoice>({
    defaultValues: {}
  });

  const onSubmit: SubmitHandler<PurchaseInvoice> = (data) => onPut(data);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const onPut = async (data: PurchaseInvoice) => {
    try {
      await trigger()
        .then(async (responseTrigger) => {
          if (responseTrigger) {
            if (data.invoice_doc_type_id == null) {
              showSnackBar(t("purchaseinvoices.info.no-data"), "error");
              setOpenDialog(false);
            }
            else {
              setLoadingPut(true);
              let mySupplier = comboSuppliers.find((supplier: Supplier) => supplier.supplier_id === data.supplier_id);
              let myPaymentMode = comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === data.payment_mode_id);
              let myPaymentMethod = comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === data.payment_method_id);
              let myCFDI = comboCFDIs.find((item: CFDI) => item.cfdi_id === data.cfdi_id);
              let myCurrency = comboCurrencies.find((item: Currency) => item.currency_id === data.currency_id);
              let myInvoiceDocumentType = comboInvoiceDocumentTypes.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === data.invoice_doc_type_id);
              let myPaymentCondition = comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === data.payment_condition_id);
              let mySupplierLanguage = comboLanguages.find((language: Language) => language.language_id === mySupplier?.language_id)
              let mySupplierLocation = await comboSupplierLocations.find((element: SupplierLocation) => element.supplier_location_id === data.supplier_location_id);
              let myBankAccount = await comboSupplierBankAccounts.find((element: SupplierBankAccount) => element.supplier_bank_account_id === data.supplier_bank_account_id);
              let myIntermediaryBank = await comboBankAccountsGeneral.find((element: BankAccount) => element.bank_account_id === data.intermediary_bank_account_id);

              let myResponseConsecutive;
              if (myConsecutive !== purchaseInvoiceLocal?.purchase_invoice_name) {
                const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
                myResponseConsecutive = await responseConsecutive.data.data[0].Consecutive;
              }
              const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");
              const company = responseCompany.data ?? null;

              let mySupplierTaxRegime: TaxRegime | undefined = undefined;
              if (mySupplierLocation) {
                let myTaxRegimeResponse = await GenericGetResource(`/taxregimes/${mySupplierLocation.tax_regime_id}`);
                mySupplierTaxRegime = myTaxRegimeResponse.data;
              }

              var date = new Date();
              if (typeof data.purchase_invoice_date == 'string')
                date = TimeConverter(data.purchase_invoice_date) ?? new Date();
              else if (data.purchase_invoice_date) {
                let currentDate = new Date();
                let invoiceDate = new Date(data.purchase_invoice_date);
                invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
                date = invoiceDate;
              }

              var supplierDate = null;
              if (typeof data.supplier_sale_invoice_date == 'string')
                supplierDate = TimeConverter(data.supplier_sale_invoice_date) ?? new Date();
              else if (data.supplier_sale_invoice_date) {
                let currentDate = new Date();
                let invoiceDate = new Date(data.supplier_sale_invoice_date);
                invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
                supplierDate = invoiceDate;
              }

              var expirationDate = null;
              if (typeof data.expiration_date == 'string')
                expirationDate = TimeConverter(data.expiration_date) ?? new Date();
              else if (data.expiration_date) {
                let currentDate = new Date();
                let invoiceDate = new Date(data.expiration_date);
                invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
                expirationDate = invoiceDate;
              }

              let myData = {
                purchase_invoice_name: myResponseConsecutive,
                purchase_invoice_date: date ?? data.purchase_invoice_date ?? new Date(),
                supplier_sale_invoice_date: supplierDate ?? data.supplier_sale_invoice_date ?? null,
                expiration_date: expirationDate ?? data.expiration_date ?? null,
                sales_order: data.sales_order,
                supplier_id: data.supplier_id,
                supplier_business_name: mySupplierLocation?.business_name ?? (data?.supplier_business_name ?? null),
                supplier_comercial_name: mySupplier?.comercial_name ?? (data.supplier_comercial_name ?? null),
                supplier_rfc: mySupplierLocation?.supplier_rfc ?? (data?.supplier_rfc ?? null),
                supplier_location_id: mySupplierLocation?.supplier_location_id ?? (data.supplier_location_id ?? null),
                supplier_street_name: mySupplierLocation?.street ?? (data.supplier_street_name ?? null),
                supplier_postal_code: mySupplierLocation?.postal_code ?? (data.supplier_postal_code ?? null),
                supplier_city_id: mySupplierLocation?.city_id,
                supplier_city_name: mySupplierLocation?.city_name ?? (data.supplier_city_name ?? null),
                supplier_state_id: mySupplierLocation?.state_id ?? (data.supplier_state_id ?? null),
                supplier_state_name: mySupplierLocation?.state_name ?? (data.supplier_state_name ?? null),
                supplier_state_abbr: mySupplierLocation?.state_abbr ?? (data.supplier_state_abbr ?? null),
                supplier_country_id: mySupplierLocation?.country_id ?? (data.supplier_country_id ?? null),
                supplier_country_name: mySupplierLocation?.country_name ?? (data.supplier_country_name ?? null),
                supplier_country_code: mySupplierLocation?.country_code ?? (data.supplier_country_code ?? null),
                supplier_account_alias: myBankAccount?.account_alias ?? (data.supplier_account_alias ?? null),
                supplier_interbank_key: myBankAccount?.interbank_key ?? (data.supplier_interbank_key ?? null),
                supplier_swift_code: myBankAccount?.swift_code ?? (data.supplier_swift_code ?? null),
                supplier_bank_account_id: myBankAccount?.supplier_bank_account_id ?? (data.supplier_bank_account_id ?? null),
                supplier_bank_name: myBankAccount?.bank_name ?? (data.supplier_bank_name ?? null),
                supplier_account_number: myBankAccount?.account_number ?? (data.supplier_account_number ?? null),
                currency_id: myCurrency?.currency_id ?? (data.currency_id ?? null),
                currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
                currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
                invoice_doc_type_id: data.invoice_doc_type_id,
                invoice_doc_status_id: data.invoice_doc_status_id,
                payment_mode_code: myPaymentMode?.code ?? (data.payment_mode_code ?? null),
                payment_mode_description: myPaymentMode?.description ?? (data.payment_mode_description ?? null),
                payment_method_code: myPaymentMethod?.code ?? (data.payment_method_code ?? null),
                payment_method_name: myPaymentMethod?.description ?? (data.payment_method_name ?? null),
                cfdi_code: myCFDI?.code ?? (data.cfdi_code ?? null),
                cfdi_description: myCFDI?.description ?? (data.cfdi_description ?? null),
                supplier_tax_regime_code: mySupplierTaxRegime?.tax_regime_code ?? (data.supplier_tax_regime_code ?? null),
                supplier_tax_regime_description: mySupplierTaxRegime?.description ?? (data.supplier_tax_regime_description ?? null),
                id_consecutive: myInvoiceDocumentType?.consecutive_id ?? (data.id_consecutive ?? null),
                notes: data.notes,
                payment_condition_days: myPaymentCondition?.payment_condition_days ?? (data.payment_condition_days ?? null),
                payment_condition_name: myPaymentCondition?.payment_condition_name ?? (data.payment_condition_name ?? null),
                payment_condition_description: myPaymentCondition?.payment_condition_description ?? (data.payment_condition_description ?? null),
                payment_due_date: data.payment_due_date,
                supplier_language: mySupplierLanguage?.language_code ?? "ES",
                supplier_email: data.supplier_email,
                supplier_phone_number: data.supplier_phone_number,
                date_mail_send: data.date_mail_send,
                user_mail_send: data.user_mail_send,
                company_business_name: company.business_name ?? (data.company_business_name ?? null),
                company_street: company.street ?? (data.company_street ?? null),
                company_city_id: company.city_id ?? (data.company_city_id ?? null),
                company_city_name: company.city_name ?? (data.company_city_name ?? null),
                company_city_code: company.city_code ?? (data.company_city_code ?? null),
                company_state_id: company.state_id ?? (data.company_state_id ?? null),
                company_state_name: company.state_name ?? (data.company_state_name ?? null),
                company_state_abbr: company.state_abbr ?? (data.company_state_abbr ?? null),
                company_country_id: company.country_id ?? (data.company_country_id ?? null),
                company_country_name: company.country_name ?? (data.company_country_name ?? null),
                company_country_code: company.country_code ?? (data.company_country_code ?? null),
                company_postal_code: company.postal_code ?? (data.company_postal_code ?? null),
                company_rfc: company.rfc ?? (data.company_rfc ?? null),
                company_tax_regime_id: company.tax_regime_id ?? (data.company_tax_regime_id ?? null),
                company_tax_regime_code: company.tax_regime_code ?? (data.company_tax_regime_code ?? null),
                company_tax_regime_description: company.tax_regime_description ?? (data.company_tax_regime_description ?? null),
                company_email: company.email ?? (data.company_email ?? null),
                company_phone_number: company.phone_number ?? (data.company_phone_number ?? null),
                xml_file_id: data.xml_file_id,
                qr_file: data.qr_file,
                report_file_id: data.report_file_id,
                cancel_xml_file_id: data.cancel_xml_file_id,
                exchange_rate: (data.exchange_rate == null || data.exchange_rate == undefined || data.exchange_rate <= 1) ? 1 : data.exchange_rate,
                project_id: data.project_id ?? null,
                uuid: data.uuid ?? null,
                intermediary_bank_account_id: 0,
                intermediary_account_alias: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.account_alias ?? (data.intermediary_account_alias ?? null)),
                intermediary_bank_name: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.bank_name ?? (data.intermediary_bank_name ?? null)),
                intermediary_bank_references: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.bank_references ?? (data.intermediary_bank_references ?? null)),
                intermediary_account_number: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.account_number ?? (data.intermediary_account_number ?? null)),
                intermediary_bank_phone_number: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.phone_number ?? (data.intermediary_bank_phone_number ?? null)),
                intermediary_bank_card_number: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.card_number ?? (data.intermediary_bank_card_number ?? null)),
                intermediary_bank_interbank_key: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.interbank_key ?? (data.intermediary_bank_interbank_key ?? null)),
                intermediary_bank_swift_code: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.swift_code ?? (data.intermediary_bank_swift_code ?? null)),
                intermediary_bank_currency_id: 0,
                intermediary_bank_currency_code: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.currency_code ?? (data.intermediary_bank_currency_code ?? null)),
                intermediary_bank_currency_description: !hasFinalPaymentBankAccount ? null : (myIntermediaryBank?.currency_description ?? (data.intermediary_bank_currency_description ?? null)),
              }
              await GenericPutResource(`/purchaseinvoices/${purchaseInvoiceLocal?.purchase_invoice_id}`, myData)
                .then(async (response) => {
                  setLoadingPut(false);
                  setValue("tax_amount", response.data.tax_amount);
                  setValue("purchase_invoice_name", response.data.purchase_invoice_name);
                  setValue("retention_amount", response.data.retention_amount);
                  setValue("subtotal_amount", response.data.subtotal_amount);
                  setValue("discount_amount", response.data.discount_amount);
                  setValue("total_amount", response.data.total_amount);
                  setValue("supplier_street_name", response.data.supplier_street_name);
                  setValue("supplier_city_name", response.data.supplier_city_name);
                  setValue("supplier_state_name", response.data.supplier_state_name);
                  setValue("supplier_state_abbr", response.data.supplier_state_abbr);
                  setValue("supplier_country_name", response.data.supplier_country_name);
                  setValue("supplier_country_code", response.data.supplier_country_code);
                  setValue("invoice_doc_type_id", response.data.invoice_doc_type_id);
                  setValue("purchase_invoice_name", response.data.purchase_invoice_name);
                  setValue("purchase_invoice_id", response.data.purchase_invoice_id);
                  setValue("purchase_invoice_name", response.data.purchase_invoice_name);
                  setValue("currency_id", response.data.currency_id);
                  setValue("currency_code", response.data.currency_code);
                  setValue("currency_description", response.data.currency_description);
                  setValue("payment_condition_id", response.data.payment_condition_id);
                  setValue("payment_condition_days", response.data.payment_condition_days);
                  setValue("payment_condition_name", response.data.payment_condition_name);
                  setValue("supplier_business_name", response.data.supplier_business_name);
                  setValue("supplier_rfc", response.data.supplier_rfc);
                  setMyLocalInvoiceDocType(response.data.invoice_doc_type_id);
                  reset({
                    ...response.data,
                    original_string_SAT: response.data.original_string_SAT,
                    certificate_number_SAT: response.data.certificate_number_SAT,
                    certificate_number_CFDI: response.data.certificate_number_CFDI,
                    supplier_street_name: response.data.supplier_street_name,
                    supplier_business_name: response.data.supplier_business_name,
                    supplier_city_name: response.data.supplier_city_name,
                    supplier_rfc: response.data.supplier_rfc,
                    supplier_state_name: response.data.supplier_state_name,
                    supplier_state_abbr: response.data.supplier_state_abbr,
                    supplier_country_name: response.data.supplier_country_name,
                    supplier_country_code: response.data.supplier_country_code,
                    is_accounted: response.data.is_accounted,
                    invoice_doc_type_id: response.data.invoice_doc_type_id,
                    payment_condition_id: response.data.payment_condition_id,
                    payment_condition_days: response.data.payment_condition_days,
                    payment_condition_name: response.data.payment_condition_name,
                    intermediary_bank_account_id: 0
                  }, { keepValues: true })
                  let mySupplierLocation: SupplierLocation = {
                    supplier_location_id: 0,
                    business_name: getValues("supplier_business_name"),
                    supplier_rfc: getValues("supplier_rfc"),
                    street: getValues("supplier_street_name"),
                    city_name: getValues("supplier_city_name"),
                    state_name: getValues("supplier_state_name"),
                    state_abbr: getValues("supplier_state_abbr"),
                    country_name: getValues("supplier_country_name"),
                    country_code: getValues("supplier_country_code"),
                  }
                  setDefaultSupplierLocation((prev) => mySupplierLocation);
                  setPurchaseInvoiceLocal((prev) => response.data);
                  setLocationEdit(false);
                  showSnackBar(t("generic.snackbar.update"), "success");
                })
                .catch((error: unknown) => {
                  if (error instanceof AxiosError) {
                    if (error.response && error.response.status === 400) {
                      showSnackBar(t(error.response.data), "error");
                    } else {
                      showSnackBar(error.message, "error");
                    }
                  } else {
                    showSnackBar("An unexpected error occurred", "error");
                  }
                  setLoadingPut(false);
                  throw error;
                }).finally(() => {
                  if (hasFinalPaymentBankAccount) {
                    setHasFinalPaymentBankAccount(true);
                  }
                });
            }
          }
          else {
            throw new Error();
          }
        });
    }
    catch (e: unknown) {
      throw e;
    }
  }

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const onDelete = () => {
    GenericGetResource(`/purchaseinvoices/${purchaseInvoiceLocal?.purchase_invoice_id}`)
      .then((response) => {
        let myPurchaseInvoice = {
          invoice_doc_status_id: 2,
        }
        GenericPutResource(`/purchaseinvoices/${purchaseInvoiceLocal?.purchase_invoice_id}`, myPurchaseInvoice)
          .then(() => {
            setOpenDialog(false);
            navigate("/purchaseinvoices", { replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setOpenDialog(false);
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  }

  const GetExchangeRateFromDates = async (currency_id: number, datePurchaseInvoice: Date | undefined, dateSupplierInvoice: Date | undefined) => {
    if (!(datePurchaseInvoice)) {
      setValue("exchange_rate", 1)
    }
    let date = (dateSupplierInvoice) ? dateSupplierInvoice : datePurchaseInvoice;
    let ExchangeRate = await GetTodayExchangeRateFromCurrencyCode(currency_id, date);
    if (ExchangeRate && ExchangeRate.rate && ExchangeRate.rate > 1) {
      setValue("exchange_rate", ExchangeRate?.rate ?? 1)
    }
    else {
      setValue("exchange_rate", 1)
    }
  }

  const getDataAndGeneratePDF = async (purchaseInvoiceId: number) => {
    try {
      const response = await GenericGetReport(`/purchaseinvoices/pdf/${purchaseInvoiceId}`, false);
      if (response.data.dataIssuingCompany.rpt_image) {
        const rptImageFile = await GenericGetResource(`/filessql/` + response.data.dataIssuingCompany.rpt_image);
        response.data.dataInvoiceGeneral.logoImage = rptImageFile?.data.data_file ?? undefined;
      }

      let qrString = getValues("qr_file");
      if (qrString) {
        const responseQR = await GetFileById(qrString);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataInvoiceGeneral.fiscalQr = newBase64 ?? undefined;
      }

      let pdfDoc = await generatePDF(response);
      return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const savePDFToDatabase = async (purchaseInvoiceId: number, pdfDoc: any) => {
    try {
      const startIndex = pdfDoc.indexOf(',') + 1;
      const pdfInBase64 = pdfDoc.substring(startIndex);
      const myFilePDF = {
        entity: "PurchaseInvoices",
        recordId: purchaseInvoiceId,
        fileName: purchaseInvoiceLocal?.purchase_invoice_name,
        typeOfFile: "application/pdf",
        schema_name: localStorage.getItem("schemaName"),
        Content: pdfInBase64
      };
      const responsePdf = await GenericPost(`/files`, myFilePDF);
      return responsePdf.file_id;
    } catch (error) {
      throw error;
    }
  };

  const onGeneratePDF = async () => {
    try {
      if (!purchaseInvoiceLocal?.report_file_id) {
        const pdfDoc = await getDataAndGeneratePDF(getValues("purchase_invoice_id") ?? 0);
        if (getValues("is_accounted") == true) {
          const fileId = await savePDFToDatabase(getValues("purchase_invoice_id") ?? 0, pdfDoc);
          setValue("report_file_id", fileId);
          onPut(getValues());
          showSnackBar(t("purchaseinvoices.reports.save-PDF"), "success");
          return;
        }
        showSnackBar(t("purchaseinvoices.reports.generated-PDF"), 'success');
      } else {

        const pdfFile = await GetFileIfExists(purchaseInvoiceLocal.report_file_id);
        const downloadLink = document.createElement('a');
        const fileName = `${purchaseInvoiceLocal.purchase_invoice_name}.pdf`;
        downloadLink.href = pdfFile?.dataURL ?? '';
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(pdfFile);
        showSnackBar(t("purchaseinvoices.reports.downloaded-PDF"), "success");
      }
    } catch (error: any) {
      showSnackBar(t("purchaseinvoices.info.error-gnereate-pdf"), "error");
      setOpenDialog(false);
      setValue("report_file_id", undefined);
      onPut(getValues());
      await getDataAndGeneratePDF(getValues("purchase_invoice_id") ?? 0);
    } finally {
      setOpenDialog(false);
    }
  };

  const onDeaccounted = async () => {
    await GenericPutAction(`/purchaseinvoices/isaccounted/${purchaseInvoiceLocal?.purchase_invoice_id}`, { is_accounted: false, notes: null }, false)
      .then(async () => {
        setIsAccounted((prev) => false);
        reset({ is_accounted: false, notes: undefined }, { keepValues: true });
        setValue("is_accounted", false);
        setValue('notes', ' ', { shouldValidate: true });
        setIsBackdrop(false);
        showSnackBar(t("generic.snackbar.update"), "success");
      })
      .catch((error) => {
        setIsAccounted(true);
        setIsBackdrop(false);
        showSnackBar(t(error.response.data), "error");
      });
  }

  const onAccounted = () => {
    trigger()
      .then(async (responseTrigger) => {
        if (responseTrigger) {
          let missingData = false;
          if ((defaultPaymentMethod?.code === undefined && getValues("payment_method_code") === null) ||
            (defaultPaymentMode?.code === undefined && getValues("payment_mode_code") === null))
            missingData = true;
          await GenericGetResource(`/purchaseinvoicesdetails/bypurchaseinvoiceid/${purchaseInvoiceLocal?.purchase_invoice_id}`)
            .then(async (response) => {
              if (response.data.total > 0 && missingData === false) {
                setValue("is_accounted", true);
                let myStringNotes = getValues("notes") ? `${getValues("notes")?.toString()}-` : "";
                for (let i = 0; i < response.data.data.length; i++) {
                  const element = response.data.data[i];
                  myStringNotes += `${element.item_description},`;
                }
                let myUpdateData = {
                  is_accounted: true,
                  notes: myStringNotes,
                }
                await GenericPutAction(`/purchaseinvoices/isaccounted/${purchaseInvoiceLocal?.purchase_invoice_id}`, myUpdateData, false)
                  .then(async () => {
                    reset({
                      is_accounted: true,
                      notes: myStringNotes,
                    }, { keepValues: true })
                    setValue('notes', myStringNotes, { shouldValidate: true });
                    setIsAccounted((prev) => getValues("is_accounted") ?? false);
                    await PostRelatedPaymentsDetails()
                      .catch(async (error) => {
                        setValue("is_accounted", false);
                        await GenericPutAction(`/purchaseinvoices/isaccounted/${purchaseInvoiceLocal?.purchase_invoice_id}`, { is_accounted: true, notes: getValues("notes"), }, false)
                          .then(async () => {
                            reset({
                              is_accounted: false,
                              notes: getValues("notes"),
                            }, { keepValues: true })
                            setIsAccounted((prev) => getValues("is_accounted") ?? false);
                            showSnackBar(error.message, 'error');
                            await setIsBackdrop((prev) => false);
                          })
                        await setIsBackdrop((prev) => false);
                      });
                    await setIsBackdrop((prev) => false);
                  }).catch(async (error) => {
                    setValue("is_accounted", false);
                    showSnackBar(error.message, "error");
                    await setIsBackdrop((prev) => false);
                  });
              }
              else if (missingData) {
                showSnackBar(t("purchaseinvoices.info.no-data"), "warning");
                await setIsBackdrop((prev) => false);
              }
              else if (response.data.total === 0) {
                showSnackBar(t("purchaseinvoices.info.no_details"), "warning");
                await setIsBackdrop((prev) => false);
              }
            }).catch(async (error) => {
              await setIsBackdrop((prev) => false);
              showSnackBar(error.message, "error");
            });
        }
        else {
          await setIsBackdrop((prev) => false);
        }
      })
  }

  const GetAndSetConsecutive = async (consecutive_id?: number) => {
    if (consecutive_id) {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutive_id}`);
      setMyConsecutive((prev) => response.data.data[0].Consecutive);
    }
    else {
      setMyConsecutive((prev) => myLocalConsecutive);
    }
  }

  const PostRelatedPaymentsDetails = async () => {

    var expirationDate = new Date();
    if (typeof getValues("expiration_date") == 'string')
      expirationDate = TimeConverter(getValues("expiration_date")?.toString()) ?? new Date();
    else if (getValues("expiration_date")) {
      let currentDate = new Date();
      let invoiceDate = new Date(getValues("expiration_date") ?? new Date());
      invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
      expirationDate = invoiceDate;
    }

    try {
      let myPaymentDetail = {
        current_payment_status_id: 1,
        payment_id: null,
        payment_deadline: expirationDate,
        payment_date: null,
        tax_id: null,
        tax_code: null,
        is_authorized: false,
        tax_description: null,
        tax_percent: null,
        retention_id: null,
        retention_code: null,
        retention_description: null,
        retention_percent: null,
        currency_id: null,
        currency_code: null,
        currency_description: null,
        exchange_rate: getValues("exchange_rate"),
        payment_method_id: null,
        payment_method_code: null,
        payment_method_description: null,
        related_total_tax_amount: 0,
        related_total_retention_amount: 0,
        total_tax_amount: 0,
        total_retention_amount: 0,
        subtotal_amount: 0,
        total_amount: 0
      }
      await GenericPostResource(`/paymentsdetails`, myPaymentDetail)
        .then(async (response) => {
          let myRelatedPaymentDetail = {
            payment_detail_id: response.data.payment_detail_id,
            purchase_invoice_id: getValues("purchase_invoice_id"),
            purchase_invoice_name: getValues("purchase_invoice_name"),
            currency_id: getValues("currency_id"),
            currency_code: getValues("currency_code"),
            currency_description: getValues("currency_description"),
            equivalence: getValues("exchange_rate"),
            partiality_number: 1,
            previous_amount: getValues("total_amount"),
            payment_amount: 0,
            amount_outstanding: getValues("total_amount"),
            tax_object_id: null,
            tax_object_code: null,
            tax_object_description: null,
            tax_id: null,
            tax_code: null,
            tax_description: null,
            tax_percent: 0,
            retention_id: null,
            retention_code: null,
            retention_description: null,
            retention_percent: 0,
            total_tax_amount: getValues("tax_amount") ?? 0,
            total_retention_amount: getValues("retention_amount") ?? 0,
          }
          myPaymentDetail.related_total_tax_amount = getValues("tax_amount") ?? 0;
          myPaymentDetail.related_total_retention_amount = getValues("retention_amount") ?? 0;
          let myPromises = [
            GenericPostResource(`/relatedpaymentsdetails`, myRelatedPaymentDetail),
            GenericPutResource(`/paymentsdetails/${response.data.payment_detail_id}`, myPaymentDetail),
          ];

          await Promise.all(myPromises)
            .then(async (responses) => {
              let myNewStatus = {
                new_payment_status_id: 1,
                previous_payment_status_id: 1,
                payment_detail_id: response.data.payment_detail_id,
              }
              await GenericPostResource(`/paymentdetailstatuses`, myNewStatus)
                .catch(async (error) => {
                  await GenericDeleteResource(`/relatedpaymentsdetails/${responses[0].data.related_payment_detail_id}`);
                  throw error;
                });
            })
            .catch(async (error) => {
              await GenericDeleteResource(`/paymentsdetails/${response.data.payment_detail_id}`)
              showSnackBar(error.message, 'error');
              setValue("is_accounted", false);
              await onPut(getValues());
              reset({
                is_accounted: false,
              }, { keepValues: true })
              setIsAccounted((prev) => getValues("is_accounted") ?? false);
            })
        })
    }
    catch (error) {
      throw error
    }
  }

  const onSendEmail = () => {
    if (purchaseInvoiceLocal) {
      let salesInvoicesData = []
      salesInvoicesData.push({
        data: purchaseInvoiceLocal,
        status: (purchaseInvoiceLocal.supplier_email) ? "Waiting" : "Error",
        status_description: (purchaseInvoiceLocal.supplier_email) ? "generic.send_email.waiting" :
          "generic.send_email.no_email"
      })
      setDataPurchaseInvoiceSelected(salesInvoicesData);
      setOpenEmail(true);
    }
  };

  const determineIsEdit = (responsesUpdatePermission: boolean) => {
    const isDeleted = purchaseInvoiceLocal?.invoice_doc_status_id === 2;
    const hasUpdatePermissions = responsesUpdatePermission;
    const isAccounted = purchaseInvoiceLocal?.is_accounted;

    if (isDeleted) {
      return true;
    } else if (isAccounted) {
      return true;
    } else if (!hasUpdatePermissions) {
      return true;
    } else {
      return false;
    }
  };

  const GetLocalParamsValues = () => {
    let purchaseInvoiceId;
    let supplierId;
    if (location.search.length > 0 && location.state === null) {
      const myParams = GetParamsFromBase64();
      purchaseInvoiceId = myParams.get("purchase_invoice_id");
      supplierId = myParams.get("supplier_id");
    }
    return { purchaseInvoiceId, supplierId };
  }

  useEffect(() => {
    // Get permission screen
    GetResourceByUrl(`/purchaseinvoices`).then((responsePermission) => setResourceScreen(responsePermission));
  }, []);

  useEffect(() => {
    const LoadPurchaseData = async () => {
      const { purchaseInvoiceId } = GetLocalParamsValues();
      const responsePurchaseInvoice = await GenericGetResource(`/purchaseinvoices/${(location.state && location.state.row.purchase_invoice_id) ?? purchaseInvoiceId}`).catch((error) => showSnackBar(error.message, 'error'));
      const myPurchaseInvoice: PurchaseInvoice = responsePurchaseInvoice.data
      await reset({
        ...myPurchaseInvoice,
        payment_method_id: (myPurchaseInvoice.payment_method_code) ? 0 : undefined,
        payment_mode_id: (myPurchaseInvoice.payment_mode_code) ? 0 : undefined,
        cfdi_id: (myPurchaseInvoice.cfdi_code) ? 0 : undefined,
        supplier_bank_account_id: (myPurchaseInvoice.supplier_bank_name) ? myPurchaseInvoice.supplier_bank_account_id ?? 0 : 0,
        invoice_doc_type_id: myPurchaseInvoice.invoice_doc_type_id
      });
      setMyConsecutive(myPurchaseInvoice.purchase_invoice_name ?? "");
      setMyLocalConsecutive(myPurchaseInvoice.purchase_invoice_name ?? "");
      setMyLocalInvoiceDocType(myPurchaseInvoice.invoice_doc_type_id ?? 0);
      if (myPurchaseInvoice.currency_code) {
        let myCurrency: Currency = {
          currency_id: myPurchaseInvoice.currency_id ?? 0,
          currency_code: myPurchaseInvoice.currency_code ?? '',
          currency_description: myPurchaseInvoice.currency_description ?? '',
        }
        await reset({ currency_id: 0 }, { keepValues: true });
        setDefaultCurrency(myCurrency);
      }
      if (myPurchaseInvoice.intermediary_bank_name) {
        let myBankAccount: BankAccount = {
          bank_account_id: 0,
          account_alias: myPurchaseInvoice.intermediary_account_alias,
          bank_name: myPurchaseInvoice.intermediary_bank_name,
          card_number: myPurchaseInvoice.intermediary_bank_card_number,
          account_number: myPurchaseInvoice.intermediary_account_number,
          interbank_key: myPurchaseInvoice.intermediary_bank_interbank_key,
          swift_code: myPurchaseInvoice.intermediary_bank_swift_code,
          phone_number: myPurchaseInvoice.intermediary_bank_phone_number,
          currency_id: myPurchaseInvoice.intermediary_bank_currency_id,
          currency_code: myPurchaseInvoice.intermediary_bank_currency_code,
          currency_description: myPurchaseInvoice.intermediary_bank_currency_description,
          bank_references: myPurchaseInvoice.intermediary_bank_references,
        }
        await reset({ intermediary_bank_account_id: 0 }, { keepValues: true });
        setDefaultBankAccountFinal(myBankAccount);
      }
      if (myPurchaseInvoice.payment_method_code) {
        let myPaymentMethod: PaymentMethod = {
          payment_method_id: 0,
          code: myPurchaseInvoice.payment_method_code,
          name: myPurchaseInvoice.payment_method_name
        }
        await reset({ payment_method_id: 0 }, { keepValues: true });
        setDefaultPaymentMethod(myPaymentMethod);
      }
      if (myPurchaseInvoice.payment_mode_code) {
        let myPaymentMode: PaymentMode = {
          payment_mode_id: 0,
          code: myPurchaseInvoice.payment_mode_code,
          description: myPurchaseInvoice.payment_mode_description
        }
        await reset({ payment_mode_id: 0 }, { keepValues: true });
        setDefaultPaymentMode(myPaymentMode);
      }
      if (myPurchaseInvoice.supplier_street_name) {
        let mySupplierLocation: SupplierLocation = {
          supplier_id: 0,
          business_name: myPurchaseInvoice.supplier_business_name,
          supplier_rfc: myPurchaseInvoice.supplier_rfc,
          street: myPurchaseInvoice.supplier_street_name,
          city_name: myPurchaseInvoice.supplier_city_name,
          state_name: myPurchaseInvoice.supplier_state_name,
          state_abbr: myPurchaseInvoice.supplier_state_abbr,
          country_name: myPurchaseInvoice.supplier_country_name,
          country_code: myPurchaseInvoice.supplier_country_code
        }
        setDefaultSupplierLocation(mySupplierLocation);
      }
      if (myPurchaseInvoice.cfdi_code) {
        let myCFDI: CFDI = {
          cfdi_id: 0,
          code: myPurchaseInvoice.cfdi_code ?? '',
          description: myPurchaseInvoice.cfdi_description ?? '',
        }
        await reset({ cfdi_id: 0 }, { keepValues: true });
        setDefaultCFDI(myCFDI);
      }
      if (myPurchaseInvoice.payment_condition_days) {
        let myPaymentCondition: PaymentCondition = {
          payment_condition_id: 0,
          payment_condition_days: myPurchaseInvoice.payment_condition_days ?? 0,
          payment_condition_description: myPurchaseInvoice.payment_condition_description ?? '',
          payment_condition_name: myPurchaseInvoice.payment_condition_name ?? '',
        }
        await reset({ payment_condition_id: 0 }, { keepValues: true });
        setDefaultPaymentConditions(myPaymentCondition);
      }
      if (myPurchaseInvoice.supplier_bank_name) {
        let mySupplierBankAccount: SupplierBankAccount = {
          supplier_bank_account_id: 0,
          account_alias: myPurchaseInvoice.supplier_account_alias,
          account_number: myPurchaseInvoice.supplier_account_number,
          bank_name: myPurchaseInvoice.supplier_bank_name,
          interbank_key: myPurchaseInvoice.supplier_interbank_key,
          swift_code: myPurchaseInvoice.supplier_swift_code,
          currency_code: myPurchaseInvoice.supplier_currency_code,
          currency_description: myPurchaseInvoice.supplier_currency_description,
        }
        setDefaultSupplierBankAccount(mySupplierBankAccount);
        await reset({ supplier_bank_account_id: myPurchaseInvoice.supplier_bank_account_id ?? 0 }, { keepValues: true });
      }
      let myInvoiceDocumentType: InvoiceDocumentType = { invoice_doc_type_id: 0, name: myPurchaseInvoice.invoice_doc_type_name };
      setDefaultInvoiceDocumentType(myInvoiceDocumentType);
      let myProject: Project = { project_id: myPurchaseInvoice.project_id, project_name: myPurchaseInvoice.project_name ?? "" };
      setDefaultProject(myProject);
      setDefaultDate(myPurchaseInvoice.purchase_invoice_date ? new Date(myPurchaseInvoice.purchase_invoice_date) : new Date);
      setDefaultDateSupplier(myPurchaseInvoice.supplier_sale_invoice_date ? new Date(myPurchaseInvoice.supplier_sale_invoice_date) : undefined);
      setDefaultExpirationDate(myPurchaseInvoice.expiration_date ? new Date(myPurchaseInvoice.expiration_date) : undefined);

      setPurchaseInvoiceLocal(myPurchaseInvoice);
    }

    if (resourceScreen?.read) {
      LoadPurchaseData();
    }
  }, [resourceScreen]);

  useEffect(() => {
    if (purchaseInvoiceLocal && resourceScreen) {
      //permissions for actions
      let myActionsPermissions = [
        GetResourceByUrl("/purchaseinvoices"),
        GetResourceByUrl("/purchaseinvoices/copy"),
        GetResourceByUrl("/purchaseinvoices/isaccounted"),
        GetResourceByUrl("/purchaseinvoices/sendemail"),
      ];

      Promise.all(myActionsPermissions)
        .then((responses1) => {
          responses1[0] && setResourceScreen(responses1[0]);
          responses1[1] && setResourceCopySalesInvoice(responses1[1]);
          responses1[2] && setResourceAccounted(responses1[2]);
          responses1[3] && setResourceSendEmail(responses1[3]);

          // if has invoice access
          setIsEdit(determineIsEdit(resourceScreen.update));
        });

      if (purchaseInvoiceLocal.invoice_doc_status_id === 2) {
        setIsDeletedInvoice(true);
      }

      if (purchaseInvoiceLocal.is_accounted) {
        setIsAccounted((prev) => true);
      }
      setDataLoaded(true);
    }
  }, [purchaseInvoiceLocal, resourceScreen]);

  useEffect(() => {
    // Verificamos si no está accounted y tiene permisos para actualizar
    if (!isAccounted && resourceScreen && resourceScreen.update && dataloaded) {
      const { supplierId } = GetLocalParamsValues();

      // Activamos el backdrop
      setIsBackdrop(true);

      // Definimos las peticiones solo si los estados correspondientes están vacíos
      let myPromises = [];
      if (comboCurrencies.length === 0) myPromises.push(GenericGetResource("/currencies"));
      if (comboPaymentMethods.length === 0) myPromises.push(GenericGetResource("/paymentmethods"));
      if (comboPaymentModes.length === 0) myPromises.push(GenericGetResource("/paymentmodes"));
      if (comboCFDIs.length === 0) myPromises.push(GenericGetResource("/cfdi"));
      if (comboInvoiceDocumentTypes.length === 0) myPromises.push(GenericGet(`/invoicedocumenttypes?filter=${utf8ToBase64("is_type_for_sale=False")}`));
      if (comboSupplierLocations.length === 0) myPromises.push(GenericGetResource(`/supplierlocations/bysupplierid/${(location.state && location.state.row.supplier_id) ?? supplierId}`));
      if (comboPaymentConditions.length === 0) myPromises.push(GenericGetResource("/paymentconditions"));
      if (comboSuppliers.length === 0) myPromises.push(GenericGetResource("/suppliers"));
      if (comboLanguages.length === 0) myPromises.push(GenericGetResource("/languages"));
      if (comboProjects.length === 0) myPromises.push(GenericGetResource("/projects"));
      if (comboSupplierBankAccounts.length === 0) myPromises.push(GenericGetResource(`/supplierbankaccounts/bysupplierid/${(location.state && location.state.row.supplier_id) ?? supplierId}`));
      if (comboBankAccountsGeneral.length === 0) myPromises.push(GenericGetResource("/bankaccounts"));

      Promise.allSettled(myPromises)
        .then((results) => {
          results.forEach((result, index) => {
            if (result.status === "fulfilled") {
              const response = result.value;
              switch (index) {
                case 0:
                  if (comboCurrencies.length === 0) setComboCurrencies(response.data.data);
                  break;
                case 1:
                  if (comboPaymentMethods.length === 0) setComboPaymentMethods(response.data.data);
                  break;
                case 2:
                  if (comboPaymentModes.length === 0) setComboPaymentModes(response.data.data);
                  break;
                case 3:
                  if (comboCFDIs.length === 0) setComboCFDIs(response.data.data);
                  break;
                case 4:
                  if (comboInvoiceDocumentTypes.length === 0) setComboInvoiceDocumentTypes(response.data.data);
                  break;
                case 5:
                  if (comboSupplierLocations.length === 0) setComboSupplierLocations(response.data.data);
                  break;
                case 6:
                  if (comboPaymentConditions.length === 0) setComboPaymentConditions(response.data.data);
                  break;
                case 7:
                  if (comboSuppliers.length === 0) setComboSuppliers(response.data.data);
                  break;
                case 8:
                  if (comboLanguages.length === 0) setComboLanguages(response.data.data);
                  break;
                case 9:
                  if (comboProjects.length === 0) setComboProjects(response.data.data);
                  break;
                case 10:
                  if (comboSupplierBankAccounts.length === 0) setComboSupplierBankAccounts(response.data.data);
                  break;
                case 11:
                  if (comboBankAccountsGeneral.length === 0) setComboBankAccountsGeneral(response.data.data);
                  break;
                default:
                  break;
              }
            } else {
              showSnackBar(`Error al cargar los datos de la petición ${index}: ${result.reason.message}`, "error");
            }
          });
        })
        .finally(() => {
          setIsBackdrop(false);
        });
    }
  }, [isAccounted, resourceScreen, dataloaded]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("purchaseinvoices.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button variant='outlined' aria-describedby={id1} onClick={handleClick1} disabled={loadingPut}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { setOpenDialogCopyPurchaseInvoice(true); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceCopySalesInvoice?.create}>
                      {t("salesinvoices.actions.copy")}<ContentCopyIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { onSendEmail(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={(!resourceSendEmail?.create)}>
                      {t("salesinvoices.actions.sendemail")}<EmailIcon />
                    </ListItemButton>
                    <ListItemButton sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={true}>
                      {t("salesinvoices.actions.pay")}<RequestQuoteIcon />
                    </ListItemButton>
                    <ListItemButton onClick={async () => {
                      handleClose1();
                      await setIsBackdrop((prev) => true);
                      await onPut(getValues())
                      await onAccounted();
                    }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}
                      disabled={resourceAccounted?.update ? (isAccounted ? true : (loadingPut ?? isEdit)) : true}>
                      {t("salesinvoices.actions.accounted")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                    <ListItemButton onClick={async () => { handleClose1(); await setIsBackdrop((prev) => true); await onDeaccounted(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}
                      disabled={resourceAccounted?.update ? (!isAccounted ? true : (loadingPut ?? isEdit)) : true}>
                      {t("salesinvoices.actions.deaccount")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='px-1 pt-1'>
                  <Button variant='outlined' aria-describedby={id2} onClick={handleClick1} disabled>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableScrollLock={false}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton onClick={() => { onGeneratePDF(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }} disabled={true}>
                      {t("purchaseinvoices.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='ps-2'>
                  {resourceScreen?.delete &&
                    <ButtonStyled disabled={isDeletedInvoice || loadingPut || (isAccounted) ? true : isEdit} variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                  }
                </div>
                <div className='pt-1'>
                  <Button disabled={loadingPut} variant="outlined" onClick={() => { navigate("/purchaseinvoices", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Arriba de la card */}
                <div className='d-flex w-100 m-2 flex-row-reverse'>
                  <div className="w-75   d-flex flex-row-reverse mx-2 ">
                    <div className='w-25'>
                      <Controller
                        name="invoice_doc_type_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboInvoiceDocumentTypes}
                            getOptionLabel={(option) => option.name ?? ""}
                            defaultValue={defaultInvoiceDocumentType}
                            renderOption={(props, option: InvoiceDocumentType) => (
                              <div key={option.invoice_doc_type_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
                            onChange={async (_, values) => {
                              if (values?.invoice_doc_type_id !== myLocalInvoiceDocType) {
                                await setMyLocalInvoiceDocType(values?.invoice_doc_type_id || 0);
                                await GetAndSetConsecutive(values?.consecutive_id);
                              }
                              else {
                                await GetAndSetConsecutive(undefined);
                              }
                              field.onChange(values?.invoice_doc_type_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("invoicedocumenttypes.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.invoice_doc_type_id}
                                helperText={GetError(errors.invoice_doc_type_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("purchaseinvoices.fields.purchase_invoice_name")}`}
                        value={myConsecutive}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                    <div className='w-25'>
                      <Controller
                        name="sales_order"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("purchaseinvoices.fields.sales_order")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.sales_order}
                            helperText={GetError(errors.sales_order?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="project_id"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboProjects}
                            getOptionLabel={(option) => `${option.project_name}`}
                            defaultValue={defaultProject}
                            renderOption={(props, option: Project) => (
                              <div key={option.project_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.project_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.project_id === value.project_id}
                            onChange={(_, values) => {
                              field.onChange(values?.project_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("projects.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.project_id}
                                helperText={GetError(errors.project_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-card-view'>
                  <div>
                    <UpdatePurchaseInvoicesHeader
                      loading={loadingPut}
                      control={control}
                      errors={errors}
                      disableKeyboardEntry={disableKeyboardEntry}
                      defaultDate={defaultDate}
                      defaultDateSupplier={defaultDateSupplier}
                      defaultSupplierBankAccount={defaultSupplierBankAccount}
                      comboSupplierBankAccounts={comboSupplierBankAccounts}
                      purchaseInvoiceLocal={purchaseInvoiceLocal}
                      defaultSupplierLocation={defaultSupplierLocation}
                      comboSupplierLocations={comboSupplierLocations}
                      getValues={getValues}
                      isEdit={(isAccounted) ? true : isEdit}
                      locationEdit={locationEdit}
                      setLocationEdit={setLocationEdit}
                      isNotExchange={isNotExchange}
                      setIsNotExchange={setIsNotExchange}
                      GetExchangeRateFromDates={GetExchangeRateFromDates}
                      comboBankAccountsGeneral={comboBankAccountsGeneral}
                      defaultBankAccountFinal={defaultBankAccountFinal}
                      setComboBankAccountsGeneral={setComboBankAccountsGeneral}
                      resourceScreen={resourceScreen}
                      showSnackBar={showSnackBar}
                      setComboSupplierBankAccounts={setComboSupplierBankAccounts}
                      onPut={onPut}
                      setHasFinalPaymentBankAccount={setHasFinalPaymentBankAccount}
                    />
                  </div>
                </div>
              </form>
              <div>
                <InvoiceDetail
                  Update={
                    <UpdatePurchaseInvoices
                      rows={rows}
                      setRows={setRows}
                      myLocalInvoiceDocType={myLocalInvoiceDocType}
                      control={control}
                      reset={reset}
                      purchaseInvoicePayload={purchaseInvoiceLocal}
                      isEdit={isAccounted}
                      onPut={onPut}
                      setIsChangedTotalValue={setIsChangedTotalValue}
                      setValue={setValue}
                      trigger={trigger}
                      getValues={getValues}
                      dataLoaded={dataloaded}
                      loadingPut={loadingPut}
                    />
                  }
                  Annotations={
                    <AnnotationsPurchaseInvoices
                      control={control}
                      errors={errors}
                      isEdit={(isAccounted) ? true : isEdit}
                    />
                  }
                  Financial={
                    <FinancialPurchaseInvoices
                      defaultPaymentMethod={defaultPaymentMethod}
                      defaultPaymentMode={defaultPaymentMode}
                      defaultCurrency={defaultCurrency}
                      defaultCFDI={defaultCFDI}
                      defaultPaymentConditions={defaultPaymentConditions}
                      setDefaultPaymentMethod={setDefaultPaymentMethod}
                      setDefaultPaymentMode={setDefaultPaymentMode}
                      setDefaultCurrency={setDefaultCurrency}
                      setDefaultCFDI={setDefaultCFDI}
                      setDefaultPaymentConditions={setDefaultPaymentConditions}
                      comboCurrencies={comboCurrencies}
                      comboPaymentMethods={comboPaymentMethods}
                      comboPaymentModes={comboPaymentModes}
                      comboCFDIs={comboCFDIs}
                      comboPaymentConditions={comboPaymentConditions}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      isEdit={(isAccounted) ? true : isEdit}
                      getValues={getValues}
                      defaultExpirationDate={defaultExpirationDate}
                      GetExchangeRateFromDates={GetExchangeRateFromDates}
                    />
                  }
                />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("purchaseinvoices.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogSendEmailSingular
        open={openEmail}
        setOpen={setOpenEmail}
        data={dataPurchaseInvoiceSelected}
        getValues={getValues}
        setValue={setValue}
        onPut={onPut}
      />
      <DialogEntity
        open={openDialogCopyPurchaseInvoice}
        setOpen={setOpenDialogCopyPurchaseInvoice}
        title={<Header title={t("purchaseinvoices.actions.copy")} size='sm' />}
        content={
          <>
            <CopyPurchaseInvoice
              setOpenDialogCopyPurchaseInvoice={setOpenDialogCopyPurchaseInvoice}
              comboInvoiceDocumentTypes={comboInvoiceDocumentTypes}
              setComboInvoiceDocumentTypes={setComboInvoiceDocumentTypes}
              defaultInvoiceDocumentType={comboInvoiceDocumentTypes.find((item) => item.invoice_doc_type_id === (getValues("invoice_doc_type_id") ?? 0))}
              purchaseInvoiceLocal={purchaseInvoiceLocal}
            />
          </>
        }
      />
      <SnackbarComponent />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
