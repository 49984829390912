import { GridColDef, GridInputRowSelectionModel, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid"
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableApplyReceipt } from "./table";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { Customer, CustomerBankAccount, CustomerLocation } from "../../../../interfaces/Sales/Catalogs/Customers/customers";
import { Company, CompanyBankAccount, CompanyConfiguration, CompanyLocation } from "../../../../interfaces/Security/companies";
import { Receipt } from "../../../../interfaces/Sales/Receipts/receipts";
import { Currency } from "../../../../interfaces/Commons/currencies";
import { PaymentMethod } from "../../../../interfaces/Sales/Catalogs/paymentMethods";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { Divider } from "../../../../components/Commons/Divider";
import { myStylePickers, PrimaryButton } from "../../../../theme/buttons";
import { Stamping } from "../commons/stamping";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import DialogReceipts from "./tabsDetail";
import { CFDI } from "../../../../interfaces/Sales/Catalogs/CFDIs";
import { File } from '../../../../interfaces/Commons/files';
import Swal from "sweetalert2";
import { useFiles } from "../../../../hooks/useFiles";
import { ReceiptsPDF } from "../../../../reports/receiptsPDF/ReceiptsPDF";
import { useCurrencies } from "../../../../hooks/useCurrencies";
import { numberWithTwoDecimalsRegex } from "../../../../constants/Regex";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { ConfirmDialog } from "./confirmDialog";
import InfoIcon from '@mui/icons-material/Info';
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";

export type DialogReceiptProps = {
  rows: readonly GridValidRowModel[],
  myRfcCustomer: string,
  isSelectedUuid: boolean,
  customer_id: number,
  myCompanyLocation: CompanyLocation | undefined,
  selectedRows?: GridRowsProp,
  setSelectedRows?: Dispatch<SetStateAction<readonly GridValidRowModel[]>>,
  setDialogPayment: Dispatch<SetStateAction<boolean>>,
  setClickedButtonGoBack: Dispatch<SetStateAction<boolean>>,
}

interface MyTableCurrencyBanks {
  balance: number,
  balancePay: number,
  balanceFree: number,
  currency_id: number,
  currency_code: string
}

export const DialogReceipt = ({
  rows,
  customer_id,
  selectedRows,
  myRfcCustomer,
  isSelectedUuid,
  setDialogPayment,
  setClickedButtonGoBack,
  myCompanyLocation,
}: DialogReceiptProps) => {
  const [t] = useTranslation("global");
  const myConsecutiveId = 3;
  const { GenericGetResource, GenericGetResourceGeneric, GenericPostResource, GenericGetReport, GenericPost, GenericPutResource } = GenericPromises();
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const { GetError } = FormsErrors();
  const { GetFiles } = useFiles();
  const theme = useTheme();
  const { GetResourceByUrl } = usePermissions();
  const { setFormatNumber } = useFormatNumber();
  const { GetExchangeRateFromCurrencyCode, GetOperatorToGetDivisa } = useCurrencies();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [hasErrorPost, setHasErrorPost] = useState(false);
  const [hasPositiveDifference, setHasPositiveDifference] = useState(false);
  const [dialogConfirmApplyReceipt, setDialogConfirmApplyReceipt] = useState(false);
  const [hasAdvanceSalesInvoice, setHasAdvanceSalesInvoice] = useState(false);
  const [myCustomerLocal, setMyCustomerLocal] = useState<Customer>();
  const [preSelectedRows, setPreSelectedRows] = useState<GridInputRowSelectionModel>();
  const [defaultCustomerBankAccount, setDefaultCustomerBankAccount] = useState<CustomerBankAccount>();
  const [defaultCompanyBankAccount, setDefaultCompanyBankAccount] = useState<CompanyBankAccount>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [comboCustomerBankAccounts, setComboCustomerBankAccounts] = useState<CustomerBankAccount[]>([]);
  const [comboCompanyBankAccounts, setComboCompanyBankAccounts] = useState<CompanyBankAccount[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [mySelectedRows, setMySelectedRows] = useState<GridRowsProp>([]);
  const [resourceFiscalSignature, setResourceFiscalSignature] = useState<Menuitem>();
  const [fiscalXML, setFiscalXML] = useState<File>();
  const [fiscalQr, setFiscalQr] = useState<File>();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingCurrenciesValues, setIsLoadingCurrenciesValues] = useState(false);
  const [companyBalance, setCompanyBalance] = useState({ balance: 0, currency_id: 0, currency_code: "" })
  const [rowsCompanyBalance, setRowsCompanyBalance] = useState<MyTableCurrencyBanks[]>([{ balance: 0, balancePay: 0, balanceFree: 0, currency_id: 0, currency_code: "" }])
  const [spinnerBalance, setSpinnerBalance] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1);
  const { setFormatNumberFromTable } = useFormatNumber();
  const [comboCustomerLocations, setComboCustomerLocations] = useState<CustomerLocation[]>([])
  const [canPayWithouUUID, setCanPayWithouUUID] = useState(false);
  const [columnsCurrency, setColumnsCurrency] = useState<GridColDef<MyTableCurrencyBanks>[]>([
    {
      field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <>
          <Typography>
            {params.value}
          </Typography>
        </>
      }
    },
    {
      field: 'balance', headerName: t("receipts.balance-table.balance"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        let value = params.value;
        if (typeof value === 'string')
          value = numberWithNotFormat(value);
        return <>
          <Typography sx={{ color: typeof value === 'number' && value < 0 ? 'red' : 'inherit', }}>
            {setFormatNumberFromTable(params.value ?? 0)}
          </Typography>
        </>
      },
    },
    {
      field: 'balancePay', headerName: t("receipts.balance-table.pay_balance"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        let value = params.value;
        if (typeof value === 'string')
          value = numberWithNotFormat(value);
        return <>
          <Typography sx={{ color: typeof value === 'number' && value < 0 ? 'red' : 'inherit', }}>
            {setFormatNumberFromTable(params.value ?? 0)}
          </Typography>
        </>
      },
    },
    {
      field: 'balanceFree', headerName: t("receipts.balance-table.free_balance"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        let value = params.value;
        if (typeof value === 'string')
          value = numberWithNotFormat(value);
        return <>
          <Typography sx={{ color: typeof value === 'number' && value < 0 ? 'red' : 'inherit', }}>
            {setFormatNumberFromTable(params.value ?? 0)}
          </Typography>
        </>
      },
    },
  ])
  const { GetFileById } = useFiles();
  const { generatePDF } = ReceiptsPDF();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm<Receipt>({
    defaultValues: {
      total_amount: 0,
      selected_amount: 0,
      difference_amount: 0,
    }
  });
  const onSubmit: SubmitHandler<Receipt> = (data) => onPost(data);

  const onPost = (data: Receipt) => {
    trigger()
      .then(async (responseTrigger) => {
        let myReceiptsDetails = mySelectedRows.map(item => {
          if (!item.product_service_key) {
            return item.receipt_detail_id;
          }
        }).filter(id => id !== undefined && id !== null);
        let myAdvanceInvoices = mySelectedRows.map(item => {
          if (item.product_service_key) {
            return item.receipt_detail_id;
          }
        }).filter(id => id !== undefined && id !== null);
        if (responseTrigger) {
          setIsEdit(true);
          setLoadingPost(true);
          setHasErrorPost(false);

          // ordenar las fechas de los id desde la mas antigua a la mas reciente
          const receiptDetailsMap = mySelectedRows.reduce((map, detail) => {
            map[detail.receipt_detail_id] = detail.payment_deadline;
            return map;
          }, {} as { [key: number]: Date | null | undefined });

          const sortedReceiptsDetails = myReceiptsDetails.sort((a, b) => {
            const dateStrA = receiptDetailsMap[a];
            const dateStrB = receiptDetailsMap[b];

            if (dateStrA === null || dateStrA === undefined) {
              return 1; // Mover dateStrA al final
            }
            if (dateStrB === null || dateStrB === undefined) {
              return -1; // Mover dateStrB al final
            }

            const dateA = new Date(dateStrA);
            const dateB = new Date(dateStrB);

            return dateA.getTime() - dateB.getTime();
          });

          let myCurrency = comboCurrencies.find((item) => item.currency_code === getValues("currency_code"));

          if (exchangeRate === -1) {
            Swal.fire({
              customClass: { container: "swalfire" },
              title: `${t("salesinvoices.info.add-exchangeratehistory")}`,
              icon: "warning",
              confirmButtonColor: theme.palette.primary.main,
              allowOutsideClick: false
            });
            setHasErrorPost((prev) => true);
            setIsEdit(false);
            setLoadingPost(false);
            setDialogConfirmApplyReceipt(false);
          }
          else {
            const responseCustomerLocations: CustomerLocation[] = await GenericGetResource(`/customerlocations/bycustomerid/${myCustomerLocal?.customer_id}`)
              .then(responseInterCustomer => {
                return responseInterCustomer.data.data;
              })
              .catch((error) => showSnackBar(error.message, 'error'));
            const myCustomerLocation = responseCustomerLocations.find(item => item.fiscal_address === true);

            const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myConsecutiveId}`)
              .catch((error) => showSnackBar(error.message, 'error'));

            const responseCFDIs: CFDI[] = await GenericGetResource(`/cfdi`)
              .then((responseInterCFDIs) => responseInterCFDIs.data.data)
              .catch((error) => showSnackBar(error.message, 'error'));
            const myCFDI = await responseCFDIs.find((item) => item.code === "CP01")

            let myCustomerBankAccount = comboCustomerBankAccounts.find((item) => item.customer_bank_account_id === data.customer_bank_account_id);
            let myCompanyBankAccount = comboCompanyBankAccounts.find((item) => item.company_bank_account_id === data.company_bank_account_id);
            let myPaymentMethod = comboPaymentMethods.find((item) => item.payment_method_id === data.payment_method_id);

            let myData = {
              receipt_name: responseConsecutive.data.data[0].Consecutive ?? (data.receipt_name ?? null),
              consecutive_id: myConsecutiveId ?? (data.consecutive_id ?? null),
              receipt_date: ((typeof data.receipt_date) === 'object' ? data.receipt_date : data.receipt_date?.toDateString()) ?? null,
              signing_date: data.signing_date ?? null,
              customer_id: myCustomerLocal?.customer_id ?? (data.customer_id ?? null),
              customer_business_name: myCustomerLocation?.business_name ?? (data.customer_business_name ?? null),
              customer_comercial_name: myCustomerLocal?.comercial_name ?? (data.customer_comercial_name ?? null),
              customer_rfc: myCustomerLocation?.customer_rfc ?? (data.customer_rfc ?? null),
              payment_method_id: myPaymentMethod?.payment_method_id ?? (data.payment_method_id ?? null),
              payment_method_code: myPaymentMethod?.code ?? (data.payment_method_code ?? null),
              payment_method_name: myPaymentMethod?.name ?? (data.payment_method_name ?? null),
              payment_method_description: myPaymentMethod?.description ?? (data.payment_method_description ?? null),
              customer_tax_regime_id: myCustomerLocal?.tax_regime_id ?? (data.customer_tax_regime_id ?? null),
              customer_tax_regime_code: myCustomerLocal?.tax_regime_code ?? (data.customer_tax_regime_code ?? null),
              customer_tax_regime_description: myCustomerLocal?.tax_regime_description ?? (data.customer_tax_regime_description ?? null),
              cfdi_id: myCFDI?.cfdi_id ?? (data.cfdi_id ?? null),
              cfdi_code: myCFDI?.code ?? (data.cfdi_code ?? null),
              cfdi_description: myCFDI?.description ?? (data.cfdi_description ?? null),
              customer_bank_account_id: myCustomerBankAccount?.customer_bank_account_id ?? (data.customer_bank_account_id ?? null),
              customer_account_alias: myCustomerBankAccount?.account_alias ?? (data.customer_account_alias ?? null),
              customer_bank_name: myCustomerBankAccount?.bank_name ?? (data.customer_bank_name ?? null),
              customer_bank_references: myCustomerBankAccount?.bank_references ?? (data.customer_bank_references ?? null),
              customer_account_number: myCustomerBankAccount?.account_number ?? (data.customer_account_number ?? null),
              customer_bank_phone_number: myCustomerBankAccount?.phone_number ?? (data.customer_bank_phone_number ?? null),
              customer_bank_card_number: myCustomerBankAccount?.card_number ?? (data.customer_bank_card_number ?? null),
              customer_bank_interbank_key: myCustomerBankAccount?.interbank_key ?? (data.customer_bank_interbank_key ?? null),
              customer_bank_swift_code: myCustomerBankAccount?.swift_code ?? (data.customer_bank_swift_code ?? null),
              customer_bank_currency_id: myCustomerBankAccount?.currency_id ?? (data.customer_bank_currency_id ?? null),
              customer_bank_currency_code: myCustomerBankAccount?.currency_code ?? (data.customer_bank_currency_code ?? null),
              customer_bank_currency_description: myCustomerBankAccount?.currency_description ?? (data.customer_bank_currency_description ?? null),
              customer_street: myCustomerLocation?.street ?? (data.customer_street ?? null),
              customer_city_id: myCustomerLocation?.city_id ?? (data.customer_city_id ?? null),
              customer_city_name: myCustomerLocation?.city_name ?? (data.customer_city_name ?? null),
              customer_state_id: myCustomerLocation?.state_id ?? (data.customer_state_id ?? null),
              customer_state_name: myCustomerLocation?.state_name ?? (data.customer_state_name ?? null),
              customer_state_abbr: myCustomerLocation?.state_abbr ?? (data.customer_state_abbr ?? null),
              customer_country_id: myCustomerLocation?.country_id ?? (data.customer_country_id ?? null),
              customer_country_code: myCustomerLocation?.country_code ?? (data.customer_country_code ?? null),
              customer_country_name: myCustomerLocation?.country_name ?? (data.customer_country_name ?? null),
              customer_postal_code: myCustomerLocation?.postal_code ?? (data.customer_postal_code ?? null),
              customer_language: myCustomerLocal?.language_code ?? "ES",
              company_bank_account_id: myCompanyBankAccount?.company_bank_account_id ?? (data.company_bank_account_id ?? null),
              company_account_alias: myCompanyBankAccount?.account_alias ?? (data.company_account_alias ?? null),
              company_bank_name: myCompanyBankAccount?.bank_name ?? (data.company_bank_name ?? null),
              company_bank_references: myCompanyBankAccount?.bank_references ?? (data.company_bank_references ?? null),
              company_account_number: myCompanyBankAccount?.account_number ?? (data.company_account_number ?? null),
              company_bank_phone_number: myCompanyBankAccount?.phone_number ?? (data.company_bank_phone_number ?? null),
              company_bank_card_number: myCompanyBankAccount?.card_number ?? (data.company_bank_card_number ?? null),
              company_bank_interbank_key: myCompanyBankAccount?.interbank_key ?? (data.company_bank_interbank_key ?? null),
              company_bank_swift_code: myCompanyBankAccount?.swift_code ?? (data.company_bank_swift_code ?? null),
              company_bank_currency_id: myCompanyBankAccount?.currency_id ?? (data.company_bank_currency_id ?? null),
              company_bank_currency_code: myCompanyBankAccount?.currency_code ?? (data.company_bank_currency_code ?? null),
              company_bank_currency_description: myCompanyBankAccount?.currency_description ?? (data.company_bank_currency_description ?? null),
              company_street: myCompanyLocation?.street ?? (data.company_street ?? null),
              company_city_id: myCompanyLocation?.city_id ?? (data.company_city_id ?? null),
              company_city_name: myCompanyLocation?.city_name ?? (data.company_city_name ?? null),
              company_state_id: myCompanyLocation?.state_id ?? (data.company_state_id ?? null),
              company_state_name: myCompanyLocation?.state_name ?? (data.company_state_name ?? null),
              company_state_abbr: myCompanyLocation?.state_abbr ?? (data.company_state_abbr ?? null),
              company_country_id: myCompanyLocation?.country_id ?? (data.company_country_id ?? null),
              company_country_name: myCompanyLocation?.country_name ?? (data.company_country_name ?? null),
              company_postal_code: myCompanyLocation?.postal_code ?? (data.company_postal_code ?? null),
              company_rfc: myCompanyLocation?.company_rfc ?? (data.company_rfc ?? null),
              company_business_name: myCompanyLocation?.business_name ?? null,
              company_tax_regime_code: myCompanyLocation?.tax_regime_code ?? null,
              company_tax_regime_description: myCompanyLocation?.tax_regime_description ?? null,
              currency_id: myCurrency?.currency_id ?? (data.currency_id ?? null),
              currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
              currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
              subtotal_tax_amount: data.subtotal_tax_amount ?? 0,
              total_tax_amount: data.total_tax_amount ?? 0,
              subtotal_retention_amount: data.subtotal_retention_amount ?? 0,
              total_retention_amount: data.total_retention_amount ?? 0,
              subtotal_amount: data.subtotal_amount ?? 0,
              total_amount: data.total_amount ?? 0,
              original_string_SAT: data.original_string_SAT ?? null,
              certificate_number_SAT: data.certificate_number_SAT ?? null,
              certificate_number_CFDI: data.certificate_number_CFDI ?? null,
              uuid: data.uuid ?? null,
              seal_SAT: data.seal_SAT ?? null,
              seal_CFDI: data.seal_CFDI ?? null,
              xml_file_id: data.xml_file_id ?? null,
              qr_file: data.qr_file ?? null,
              report_file_id: data.report_file_id ?? null,
              cancel_xml_file_id: data.cancel_xml_file_id ?? null,
              date_mail_send: data.date_mail_send ?? null,
              user_mail_send: data.user_mail_send ?? null,
              cancellation_date: data.cancellation_date ?? null,
              cancellation_reason: data.cancellation_reason ?? null,
              cancellation_response_code: data.cancellation_response_code ?? null,
              cancellation_reason_description: data.cancellation_reason_description ?? null,
              receiptsdetails: sortedReceiptsDetails,
              exchange_rate: exchangeRate,
              advanceInvoices: myAdvanceInvoices,
              isSelectedUuid: isSelectedUuid
            }

            if ((getValues("difference_amount") ?? 0) < 0) {
              showSnackBar(t("receipts.info.amount-does-not-cover-all-amounts"), "info");
            }

            GenericPostResource(`/receipts`, myData)
              .then(async (response) => {
                if (response.data.error) {
                  Swal.fire({
                    customClass: { container: "swalfire" },
                    icon: "error",
                    confirmButtonColor: theme.palette.primary.main,
                    title: "Error",
                    html: `${response.data.error}${(response.data.details) != null ? '<br> <br>' + response.data.details : ""}`,
                  });
                  if (!response.data.data) {
                    setHasErrorPost((prev) => true);
                    setIsEdit(false);
                    setLoadingPost(false);
                    setDialogConfirmApplyReceipt(false);
                  }
                  else {
                    if (response.data.data && response.data.data.uuid) {
                      setValue("receipt_id", response.data.data.receipt_id)
                      setValue("original_string_SAT", response.data.data.original_string_SAT);
                      setValue("certificate_number_SAT", response.data.data.certificate_number_SAT);
                      setValue("certificate_number_CFDI", response.data.data.certificate_number_CFDI);
                      setValue("uuid", response.data.data.uuid);
                      setValue("seal_SAT", response.data.data.seal_SAT);
                      setValue("seal_CFDI", response.data.data.seal_CFDI);
                      setValue("signing_date", response.data.data.signing_date);
                    }
                    else if (response.data.uuid) {
                      setValue("receipt_id", response.data.receipt_id)
                      setValue("original_string_SAT", response.data.original_string_SAT);
                      setValue("certificate_number_SAT", response.data.certificate_number_SAT);
                      setValue("certificate_number_CFDI", response.data.certificate_number_CFDI);
                      setValue("uuid", response.data.uuid);
                      setValue("seal_SAT", response.data.seal_SAT);
                      setValue("seal_CFDI", response.data.seal_CFDI);
                      setValue("signing_date", response.data.signing_date);
                      setLoadingPost(false);
                    }
                    setLoadingPost(false);
                    setDialogConfirmApplyReceipt(false);
                  }
                }
                else {
                  if (response.data.XML !== undefined && response.data.QR !== undefined) {
                    setValue("xml_file_id", response.data.XML)
                    setValue("qr_file", response.data.QR)
                    await GetFiles(response.data.receipt_id, "Receipts").then((responsefiles) => {
                      if (responsefiles && responsefiles.length > 0) {
                        for (let i = 0; i < responsefiles.length; i++) {
                          const element: File = responsefiles[i];
                          if (element.file_id === (((getValues && getValues("qr_file")) ?? response.data.QR))) {
                            setFiscalQr(() => element);
                          }
                          if (element.file_id === (((getValues && getValues("xml_file_id")) ?? response.data.XML))) {
                            setFiscalXML(() => element);
                          }
                        }
                      }
                    });
                  }
                  if (response.data.data && response.data.data.uuid) {
                    setValue("receipt_id", response.data.data.receipt_id)
                    setValue("original_string_SAT", response.data.data.original_string_SAT);
                    setValue("certificate_number_SAT", response.data.data.certificate_number_SAT);
                    setValue("certificate_number_CFDI", response.data.data.certificate_number_CFDI);
                    setValue("uuid", response.data.data.uuid);
                    setValue("seal_SAT", response.data.data.seal_SAT);
                    setValue("seal_CFDI", response.data.data.seal_CFDI);
                    setValue("signing_date", response.data.data.signing_date);
                  }
                  else if (response.data.uuid) {
                    setValue("receipt_id", response.data.receipt_id)
                    setValue("original_string_SAT", response.data.original_string_SAT);
                    setValue("certificate_number_SAT", response.data.certificate_number_SAT);
                    setValue("certificate_number_CFDI", response.data.certificate_number_CFDI);
                    setValue("uuid", response.data.uuid);
                    setValue("seal_SAT", response.data.seal_SAT);
                    setValue("seal_CFDI", response.data.seal_CFDI);
                    setValue("signing_date", response.data.signing_date);
                    await onGeneratePDF(response.data.receipt_id);
                    await GenericPutResource(`/receipts/${response.data.receipt_id}`, { report_file_id: getValues("report_file_id") })
                    setLoadingPost(false);
                  }
                  if (response.data.notUuid) {
                    setLoadingPost(false);
                    setValue("receipt_id", response.data.receipt_id);
                  }
                  else if (!response.data.uuid) {
                    await onGeneratePDF(response.data.receiptInternalData.receipt_id);
                    await GenericPutResource(`/receipts/${response.data.receiptInternalData.receipt_id}`, { report_file_id: getValues("report_file_id") })
                  }

                  setLoadingPost(false);
                  setDialogConfirmApplyReceipt(false);
                  Swal.fire({
                    customClass: { container: "swalfire" },
                    title: `${t("receipts.info.stamped")}`,
                    icon: "success",
                    confirmButtonColor: theme.palette.primary.main,
                    allowOutsideClick: false
                  });
                }
              })
              .catch(async (error) => {
                if (error.response.data.errorMessage) {
                  await showSnackBar(t(error.response.data.errorMessage), "error");
                }
                else {
                  showSnackBar(error.message, 'error');
                }
                setHasErrorPost((prev) => true);
                setIsEdit(false);
                setLoadingPost(false);
                setDialogConfirmApplyReceipt(false)
              });
          }
        }
      });
  }

  const getDataAndGeneratePDF = async (receiptsId: number) => {
    try {
      const response = await GenericGetReport(`/receipts/pdf/${receiptsId}`, false);

      if (response.data.dataIssuingCompany.rpt_image) {
        const rptImageFile = await GenericGetResource(`/filessql/` + response.data.dataIssuingCompany.rpt_image);
        response.data.dataReceiptHeader.logoImage = rptImageFile?.data.data_file ?? undefined;
      }

      if (response.data.dataReceiptHeader.qr_file) {
        const responseQR = await GetFileById(response.data.dataReceiptHeader.qr_file);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataReceiptHeader.fiscalQr = newBase64 ?? undefined;
      }

      let pdfDoc = await generatePDF(response);
      return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const savePDFToDatabase = async (receiptId: number, pdfDoc: any) => {
    try {
      const startIndex = pdfDoc.indexOf(',') + 1;
      const pdfInBase64 = pdfDoc.substring(startIndex);
      const myFilePDF = {
        entity: "Receipts",
        recordId: receiptId,
        fileName: getValues("receipt_name"),
        typeOfFile: "application/pdf",
        schema_name: localStorage.getItem("schemaName"),
        Content: pdfInBase64
      };
      const responsePdf = await GenericPost(`/files`, myFilePDF);
      return responsePdf.file_id;
    } catch (error) {
      throw error;
    }
  };

  const numberWithNotFormat = (value: string): number => {
    return parseFloat(value.replace(/[$,]/g, ''));
  }

  const onGeneratePDF = async (receipt_id: number) => {
    try {
      const pdfDoc = await getDataAndGeneratePDF(receipt_id);
      const fileId = await savePDFToDatabase(receipt_id, pdfDoc);
      setValue("report_file_id", fileId);
      showSnackBar(t("salesinvoices.reports.generated-PDF"), "success");
    } catch (error: any) {
      showSnackBar('Error to generate pdf', "error");
    }
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const onChangeTotals = async (myRows: readonly GridValidRowModel[]) => {
    trigger();
    setIsLoadingCurrenciesValues(true);
    if (getValues("currency_id")) {
      let amountsAreAdded = true;
      let amountsInSourceCurrency = await Promise.all(
        myRows?.map(async (row) => {
          if (row?.related_currency_code !== "MXN" || getValues("currency_code") !== "MXN") {
            let myComboCurrency: Currency[] = [];
            if (comboCurrencies.length <= 0) {
              await GenericGetResource(`/currencies`).then((response) => {
                myComboCurrency = response.data.data
              })
            }
            else
              myComboCurrency = comboCurrencies;
            let myCurrency = await myComboCurrency.find((item) => item.currency_code === (row.related_currency_code === "MXN" ? getValues("currency_code") : row.related_currency_code));
            const receiptDate = getValues('receipt_date');
            let date = new Date(receiptDate ?? new Date());
            date?.setDate(date.getDate() + 1)
            let myAuxExchange = await GetExchangeRateFromCurrencyCode(myCurrency?.currency_id ?? 0, date ?? undefined);
            setExchangeRate(myAuxExchange?.rate ?? 1)
            if (myAuxExchange?.rate === -1) {
              showSnackBar(`${t("receipts.info.no-rate-from-currency")} ${row?.related_currency_code}`, "warning");
              amountsAreAdded = false;
              setExchangeRate(-1)
            }
            let myOperator = GetOperatorToGetDivisa(getValues("currency_code") ?? "", row?.related_currency_code);
            switch (myOperator) {
              case "multiply": {
                let amountInSourceCurrency = row.amount_outstanding * ((myAuxExchange?.rate === -1 ? 0 : myAuxExchange?.rate) ?? 0);
                return row?.product_service_key ? (amountInSourceCurrency * -1) : amountInSourceCurrency;
              }
              case "divide": {
                let amountInSourceCurrency = row.amount_outstanding / ((myAuxExchange?.rate === -1 ? 0 : myAuxExchange?.rate) ?? 0);
                return row?.product_service_key ? (amountInSourceCurrency * -1) : amountInSourceCurrency;
              }
            }
            return row?.product_service_key ? (row.amount_outstanding * -1) : row.amount_outstanding;
          }
          else {
            setExchangeRate(1);
            return row?.product_service_key ? (row.amount_outstanding * -1) : row.amount_outstanding;
          }
        })
      );
      if (amountsAreAdded) {
        let myTotalAmount = amountsInSourceCurrency.reduce((acc, amount) => acc + amount, 0);
        setValue("selected_amount", myTotalAmount);
        setValue("difference_amount", (getValues("total_amount") ?? 0) - (getValues("selected_amount") ?? 0));
        if ((getValues("difference_amount") ?? 0.009) > 0.009) {
          setHasPositiveDifference(true);
        }
        else {
          setHasPositiveDifference(false);
        }
      }
      else {
        setValue("selected_amount", 0);
        setValue("difference_amount", 0);
      }
    }
    else {
      setValue("selected_amount", 0);
      setValue("difference_amount", 0);
    }
    // Validate advance sales invoices selected
    let myAdvanceSalesInvoice = (myRows.findIndex((item) => item.product_service_key)) ?? -1;
    if (myAdvanceSalesInvoice > -1) {
      setHasAdvanceSalesInvoice(true);
    }
    else {
      setHasAdvanceSalesInvoice(false);
    }
    setIsLoadingCurrenciesValues(false);
  }

  useEffect(() => {

    let myPromises = [
      GenericGetResource(`/customers/${customer_id}`),
      GenericGetResource(`/customerbankaccounts/bycustomerid/${customer_id}`),
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResourceGeneric("/companybankaccounts", "/gcompanybankaccounts"),
      GenericGetResource(`/paymentmethods`),
      GenericGetResource(`/currencies`),
      GenericGetResource(`/consecutives/byConsecutiveId/${myConsecutiveId}`),
      GenericGetResource(`/customerlocations/bycustomerid/${customer_id}`),
      GenericGetResourceGeneric(`/companyconfigurations`, '/gcompanyconfigurations'),
    ];

    Promise.all(myPromises)
      .then(async (responses) => {
        let myCustomer: Customer | undefined = responses[0].data;
        if (responses[7]) {
          setComboCustomerLocations(responses[7].data.data);
          let myLocationCustomer = responses[7].data.data.find((element: CustomerLocation) => element.customer_rfc === myRfcCustomer)
          if (myCustomer) {
            myCustomer.business_name = myLocationCustomer.business_name;
            myCustomer.customer_rfc = myLocationCustomer.customer_rfc;
            myCustomer.tax_regime_id = myLocationCustomer.tax_regime_id;
            myCustomer.tax_regime = myLocationCustomer.tax_regime;
            myCustomer.tax_regime_code = myLocationCustomer.tax_regime_code;
            myCustomer.tax_regime_description = myLocationCustomer.tax_regime_description;
            myCustomer.customer_email = myLocationCustomer.customer_email;
            myCustomer.customer_phone_number = myLocationCustomer.customer_phone_number;
            myCustomer.customer_web = myLocationCustomer.customer_web;
          }
        }
        setMyCustomerLocal(myCustomer);

        if (responses[8]) {
          setCanPayWithouUUID((responses[8].data.data[0].is_non_fiscal === true))
        }

        await responses[1] && setComboCustomerBankAccounts(responses[1].data.data);
        let myComboCompanyBankAccounts = responses[3].data.data.filter((element: CompanyBankAccount) => element.company_id === 1)
        await responses[3] && setComboCompanyBankAccounts(myComboCompanyBankAccounts);
        await responses[4] && setComboPaymentMethods(responses[4].data.data);
        await responses[5] && setComboCurrencies(responses[5].data.data);
        await responses[6] && setValue("receipt_name", responses[6].data.data[0].Consecutive);
        setMySelectedRows(selectedRows ?? []);

        let myPermissions = [
          GetResourceByUrl(`/receipts/fiscalsignature`),
        ];

        Promise.all(myPermissions)
          .then((responses) => {
            responses[0] && setResourceFiscalSignature(responses[0]);
          });

        let myDefaultCustomerBankAcoount = responses[1].data.data.find((item: CustomerBankAccount) => item.customer_bank_account_id === responses[0].data.primary_bank_account);
        setDefaultCustomerBankAccount(myDefaultCustomerBankAcoount);
        if (myDefaultCustomerBankAcoount && myDefaultCustomerBankAcoount.currency_id) {
          reset({
            currency_id: myDefaultCustomerBankAcoount.currency_id ?? undefined,
          }, { keepValues: true });
          setValue("currency_id", myDefaultCustomerBankAcoount.currency_id ?? undefined);
          setValue("currency_code", myDefaultCustomerBankAcoount?.currency_code);
        }

        let myDefaultCompanyBankAccount = responses[3].data.data.find((item: CompanyBankAccount) => item.company_bank_account_id === responses[2].data.data[0].primary_bank_account);
        setDefaultCompanyBankAccount(myDefaultCompanyBankAccount);
        setCompanyBalance({
          balance: myDefaultCompanyBankAccount?.balance ?? 0,
          currency_id: myDefaultCompanyBankAccount?.currency_id ?? 0,
          currency_code: myDefaultCompanyBankAccount?.currency_code ?? "",
        })

        let myDefaultPaymentMethod = responses[4].data.data.find((item: PaymentMethod) => item.payment_method_id === responses[0].data.default_payment_method_id)
        setDefaultPaymentMethod(myDefaultPaymentMethod);

        reset({
          company_bank_account_id: myDefaultCompanyBankAccount && myDefaultCompanyBankAccount.company_bank_account_id,
          customer_bank_account_id: myDefaultCustomerBankAcoount && myDefaultCustomerBankAcoount.customer_bank_account_id,
          payment_method_id: myDefaultPaymentMethod && myDefaultPaymentMethod.payment_method_id,
          receipt_date: new Date(),
        }, { keepValues: true });

        showSnackBar(t("receipts.info.apply-receipt-filter"), "info");
        setPreSelectedRows((prev) => selectedRows?.map((row) => {
          if (row.receipt_detail_id && !row.uuid) {
            return row.receipt_detail_id
          }
        }));

        const receiptDate = getValues('receipt_date');
        let myCurrency = responses[5].data.data.find((item: Currency) => item.currency_code === "USD");
        let date = new Date(receiptDate ?? new Date());
        date?.setDate(date.getDate() + 1)
        let myAuxExchange = await GetExchangeRateFromCurrencyCode(myCurrency?.currency_id ?? 0, date)
        setExchangeRate(myAuxExchange ? myAuxExchange.rate ?? 1 : 1);
        if (getValues("currency_code") === "MXN" && !(selectedRows?.find((row) => row.related_currency_code !== "MXN"))) {
          setExchangeRate(1)
        }
        onChangeTotals(selectedRows ?? []);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });

  }, []);

  useEffect(() => {
    const calculateBalance = async () => {
      try {
        setSpinnerBalance(true)
        const strTotalAmount = getValues("total_amount") ?? 0;
        const totalAmount = typeof strTotalAmount === 'string' ?
          parseFloat((strTotalAmount === '' || strTotalAmount === null || strTotalAmount === undefined) ? "0" :
            strTotalAmount) : strTotalAmount;
        const currencyCode = getValues("currency_code") ?? "MXN"; //Customer
        const companyCurrencyCode = companyBalance.currency_code; //Company
        const balance = companyBalance.balance;

        const operator = GetOperatorToGetDivisa(companyCurrencyCode, currencyCode);
        let exchangeRate = 1;
        if (currencyCode === "USD" || companyCurrencyCode === "USD") {
          let myCurrency = comboCurrencies.find((item) => item.currency_code === "USD");
          await GetExchangeRateForReceiptDate(myCurrency?.currency_id ?? 0).then((response) => {
            exchangeRate = response?.rate ?? 1;
          });
        }

        let calculatedBalance = 0;
        let total = 0;
        if (operator === "multiply") {
          total = (totalAmount * exchangeRate)
          calculatedBalance = balance + (totalAmount * exchangeRate);
        } else if (operator === "divide") {
          total = (totalAmount / exchangeRate)
          calculatedBalance = balance + (totalAmount / exchangeRate);
        } else {
          total = totalAmount
          calculatedBalance = balance + totalAmount; // No operation
        }

        if (exchangeRate === -1) {
          Swal.fire({
            customClass: { container: "swalfire" },
            title: `${t("salesinvoices.info.add-exchangeratehistory")}`,
            icon: "warning",
            confirmButtonColor: theme.palette.primary.main,
            allowOutsideClick: false
          });
          setRowsCompanyBalance(() => [{
            currency_id: companyBalance.currency_id,
            currency_code: companyBalance.currency_code,
            balance: companyBalance.balance,
            balancePay: 0,
            balanceFree: 0,
          }])
        }
        else {
          setRowsCompanyBalance(() => [{
            currency_id: companyBalance.currency_id,
            currency_code: companyBalance.currency_code,
            balance: balance ?? 0,
            balancePay: total ?? 0,
            balanceFree: calculatedBalance ?? 0,
          }])
        }
        setSpinnerBalance(false);
      } catch (error) {
        console.error("Error calculating balance:", error);
        setSpinnerBalance(false);
      }
    };

    const GetExchangeRateForReceiptDate = async (currencyCode: number) => {
      const receiptDate = getValues('receipt_date');
      let date = new Date(receiptDate ?? new Date());
      date.setDate(date.getDate() + 1);
      const exchangeRate = await GetExchangeRateFromCurrencyCode(currencyCode, date);
      return exchangeRate;
    };

    calculateBalance();
  }, [companyBalance, isLoadingCurrenciesValues, spinnerBalance]);

  return (
    <>
      <Header
        size="no_title"
        child={
          <>
            <div className="d-flex flex-column justify-content-between align-items-center">
              <DataTable
                columns={columnsCurrency}
                setColumns={setColumnsCurrency}
                data={rowsCompanyBalance}
                entityId={"currency_id"}
                entity={`MyTableCurrencyBanks`}
                preferences={{}}
                namePreferences={"grid-currencybanks-columns"}
                nameOrderColumns={"grid-currencybanks-columns"}
                isChildren={true}
                hideFooter={true}
                hideShadow={true}
                disableColumnMenu={true}
              />
              <div className="w-100 d-flex flex-row mt-2">
                <div className="w-25 mt-2">
                  <h3>{t("receipts.title-view")}</h3>
                </div>
                <div className="w-25 mt-1">
                  <Typography variant="subtitle1"><b>{`${t("companies.title-view")}:`}</b></Typography>
                  <Typography>{`${myCompanyLocation?.business_name} - ${myCompanyLocation?.company_rfc}`}</Typography>
                </div>
                <div className="d-flex flex-row-reverse w-50">
                  <PrimaryButton
                    disabled={!resourceFiscalSignature?.create || isEdit || (!isSelectedUuid && !canPayWithouUUID)}
                    className="d-flex align-self-end w-25"
                    variant="outlined"
                    onClick={() => {
                      let hasOnlySelectedAdvanceInvoices = mySelectedRows?.findIndex((item) => !item.product_service_key) ?? -1;
                      if (mySelectedRows.length === 0) {
                        showSnackBar(t("receipts.info.add-details"), "warning");
                      }
                      else if (hasOnlySelectedAdvanceInvoices === -1 && hasAdvanceSalesInvoice) {
                        showSnackBar(t("receipts.info.select-sales-invoices"), "warning");
                      }
                      else {
                        trigger().then((responseTrigger) => {
                          if (!isLoadingCurrenciesValues && responseTrigger) { setDialogConfirmApplyReceipt(true); }
                        });
                      }
                    }}
                  >
                    {t("receiptsdetails.buttons.apply-receipt")}
                  </PrimaryButton>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => { setDialogPayment(false); setClickedButtonGoBack((prev) => !prev); }}
                    sx={{ marginTop: 1.5, marginInline: 1 }}
                    disabled={loadingPost}>
                    {t("generic.buttons.goback")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
      />
      {!dataLoaded && <Spinner isBox={false} />}
      {
        dataLoaded &&
        <>
          <Box sx={{ minWidth: 1250 }} className="d-flex flex-column my-2 w-100">
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column custom-card-view w-100">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-50">

                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <Typography style={{ fontWeight: "bold" }}>{t("customers.title-view")}:</Typography>
                      <Typography>{myCustomerLocal?.business_name}</Typography>
                      <Typography>{myCustomerLocal?.customer_rfc}</Typography>
                      <Typography>{myCustomerLocal?.tax_regime_code} - {myCustomerLocal?.tax_regime_description}</Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} sx={{ paddingRight: 1 }}>
                      {hasAdvanceSalesInvoice &&
                        <>
                          <Box sx={{ border: "solid 1px black", backgroundColor: "#FFFDE7", borderRadius: "3px" }} width={20} height={20} marginY={1} />
                          <Typography margin={1}>
                            {t("receipts.info.advance-sales-invoice-selected")}
                          </Typography>
                        </>
                      }
                      {!isSelectedUuid &&
                        <>
                          <Box>
                            <FormControlLabel
                              checked={true}
                              control={<Checkbox />}
                              disabled={true}
                              label={(canPayWithouUUID) ?
                                t("receipts.info.sales-invoice-nouuid") :
                                t("receipts.info.sales-invoice-nouuid_no_paid")
                              }
                            />
                          </Box>
                        </>
                      }
                    </Box>
                  </Box>

                  <Box sx={{ paddingRight: 1, marginBottom: 0.5 }}>
                    <Divider />
                  </Box>
                  <div className="d-flex w-100">
                    <Controller
                      name="receipt_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("receipts.fields.receipt_name")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.receipt_name}
                          helperText={GetError(errors.receipt_name?.type)}
                          size="small"
                          sx={{ width: "100%", paddingRight: 1 }}
                          InputProps={{ readOnly: true, disableUnderline: true }}
                          disabled={isEdit}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-50">
                  <div className="d-flex">
                    <Controller
                      name="total_amount"
                      control={control}
                      rules={{ required: true, min: hasAdvanceSalesInvoice ? undefined : 0.000001, max: /*!isSelectedUuid ? (*/(getValues("selected_amount") ?? 0)/* + 0.01) : undefined*/, pattern: numberWithTwoDecimalsRegex }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("receipts.fields.total_amount")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            onChangeTotals(mySelectedRows);
                          }}
                          error={!!errors.total_amount}
                          helperText={GetError(errors.total_amount?.type)}
                          size="small"
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                            "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", paddingRight: 1
                          }}
                          type="number"
                          disabled={isEdit}
                        />
                      )}
                    />
                    <Controller
                      name="currency_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t("currencies.title-view")}`}
                          ref={field.ref}
                          value={(field.value) ? comboCurrencies.find((element) => element.currency_id === field.value)?.currency_code + " - " + comboCurrencies.find((element) => element.currency_id === field.value)?.currency_description : ""}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.receipt_name}
                          helperText={GetError(errors.receipt_name?.type)}
                          size="small"
                          sx={{ width: "100%" }}
                          InputProps={{ readOnly: true, disableUnderline: true }}
                          disabled={true}
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex flex-row w-100">
                    <div className="w-50">
                      <Controller
                        name="selected_amount"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("receipts.fields.selected_amount")}`}
                            ref={field.ref}
                            value={setFormatNumber({ value: field.value ?? 0 })}
                            error={!!errors.selected_amount}
                            helperText={GetError(errors.selected_amount?.type)}
                            size="small"
                            disabled={isEdit}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1, paddingRight: 1
                            }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {isLoadingCurrenciesValues ? <CircularProgress color="primary" size={25} /> : null}
                                </>
                              )
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </div>
                    <div className="w-50">
                      <TextField
                        variant="filled"
                        label={`${t("payments.fields.exchange_rate")}`}
                        size="small"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1,
                        }}
                        value={exchangeRate}
                        type="number"
                        disabled={true}
                        error={exchangeRate <= 0}
                        helperText={exchangeRate <= 0 ? t("receipts.info.add-exchangeratehistory") : ""}
                      />
                    </div>
                  </div>
                  <Controller
                    name="difference_amount"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t("receipts.fields.difference_amount")}`}
                        ref={field.ref}
                        value={setFormatNumber({ value: field.value ?? 0 })}
                        error={!!errors.difference_amount}
                        helperText={GetError(errors.difference_amount?.type)}
                        size="small"
                        disabled={isEdit}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", }, width: "100%", marginTop: 1,
                          '& .MuiInputBase-input': {
                            color: (field.value && field.value <= 0) ? 'red' : 'black', // Cambia este valor por el color que desees
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {isLoadingCurrenciesValues ? <CircularProgress color="primary" size={25} /> : null}
                            </>
                          )
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex mt-2">
                <Controller
                  name="payment_method_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      options={comboPaymentMethods}
                      defaultValue={defaultPaymentMethod}
                      getOptionLabel={(option) => `${option.code} - ${option.name}`}
                      renderOption={(props, option: PaymentMethod) => (
                        <div key={option.payment_method_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.code} - {option.name}
                          </Box>
                        </div>
                      )}
                      sx={{ width: "50%", paddingRight: 1 }}
                      isOptionEqualToValue={(option, value) => option.payment_method_id === value.payment_method_id}
                      onChange={(_, values) => {
                        field.onChange(values?.payment_method_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("paymentmethods.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.payment_method_id}
                          helperText={GetError(errors.payment_method_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  }
                />
                <Controller
                  name="receipt_date"
                  control={control}
                  rules={{}}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      ref={field.ref}
                      label={`${t("receipts.fields.receipt_date")}`}
                      onChange={(_: any) => { field.onChange(_); onChangeTotals(mySelectedRows) }}
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          sx: { width: "50%" },
                          onClick: () => setOpenDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.receipt_date,
                          helperText: GetError(errors.receipt_date?.type),
                        }
                      }}
                      defaultValue={dayjs()}
                      format="DD/MM/YYYY"
                      disabled={isEdit}
                    />
                  }
                />
              </div>
              <Divider />
              <Box className="d-flex w-100">
                <Box sx={{ width: "50%" }} className="d-flex custom-card-view">
                  <Box className="w-100 d-flex flex-column">
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.account_alias")}:
                        </Typography>
                        {defaultCustomerBankAccount?.account_alias}
                      </Typography>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.bank_references")}:
                        </Typography>
                        {defaultCustomerBankAccount?.bank_references}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.bank_name")}:
                          </Typography>
                          {defaultCustomerBankAccount?.bank_name}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("currencies.title-view")}:
                          </Typography>
                          {defaultCustomerBankAccount?.currency_description} - {defaultCustomerBankAccount?.currency_code}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.card_number")}:
                          </Typography>
                          {defaultCustomerBankAccount?.card_number}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.account_number")}:
                          </Typography>
                          {defaultCustomerBankAccount?.account_number}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.interbank_key")}:
                          </Typography>
                          {defaultCustomerBankAccount?.interbank_key}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.swift_code")}:
                          </Typography>
                          {defaultCustomerBankAccount?.swift_code}
                        </Typography>
                      </Box>
                    </Box>
                    <Controller
                      name="customer_bank_account_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboCustomerBankAccounts}
                          defaultValue={defaultCustomerBankAccount}
                          getOptionLabel={(option) => `${option.bank_name} - ${option.account_alias} - ${option.currency_code}`}
                          renderOption={(props, option: CustomerBankAccount) => (
                            <div key={option.customer_bank_account_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.bank_name} - {option.account_alias} - {option.currency_code}
                              </Box>
                            </div>
                          )}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) => option.customer_bank_account_id === value.customer_bank_account_id}
                          onChange={async (_, values) => {
                            let currency_id = values?.currency_id;
                            if (currency_id) {
                              let myCurrency = await comboCurrencies.find((item) => item.currency_id === currency_id);
                              reset({ currency_id: currency_id }, { keepValues: true });
                              setValue("currency_id", currency_id)
                              setValue("currency_code", myCurrency?.currency_code);
                              onChangeTotals(mySelectedRows);
                            }
                            else {
                              reset({ currency_id: 0 }, { keepValues: true });
                              setValue("currency_id", 0)
                              setValue("currency_code", "");
                              onChangeTotals(mySelectedRows);
                            }
                            field.onChange(values?.customer_bank_account_id || null);
                            setDefaultCustomerBankAccount(values ?? undefined);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("receipts.fields.customerbankaccount")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.customer_bank_account_id}
                              helperText={GetError(errors.customer_bank_account_id?.type)}
                            />
                          )}
                          disabled={isEdit}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "1%" }} display={"flex"} justifyContent={"center"}>
                  <Divider />
                </Box>
                <Box sx={{ width: "50%" }} className="d-flex custom-card-view">
                  <Box className="w-100">
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.account_alias")}:
                        </Typography>
                        {defaultCompanyBankAccount?.account_alias}
                      </Typography>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.bank_references")}:
                        </Typography>
                        {defaultCompanyBankAccount?.bank_references}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.bank_name")}:
                          </Typography>
                          {defaultCompanyBankAccount?.bank_name}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("currencies.title-view")}:
                          </Typography>
                          {defaultCompanyBankAccount?.currency_description} - {defaultCompanyBankAccount?.currency_code}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.card_number")}:
                          </Typography>
                          {defaultCompanyBankAccount?.card_number}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.account_number")}:
                          </Typography>
                          {defaultCompanyBankAccount?.account_number}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.interbank_key")}:
                          </Typography>
                          {defaultCompanyBankAccount?.interbank_key}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.swift_code")}:
                          </Typography>
                          {defaultCompanyBankAccount?.swift_code}
                        </Typography>
                      </Box>
                    </Box>
                    <Controller
                      name="company_bank_account_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboCompanyBankAccounts}
                          defaultValue={defaultCompanyBankAccount}
                          getOptionLabel={(option) => `${option.bank_name} - ${option.account_alias} - ${option.currency_code}`}
                          renderOption={(props, option: CompanyBankAccount) => (
                            <div key={option.company_bank_account_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.bank_name} - {option.account_alias} - {option.currency_code}
                              </Box>
                            </div>
                          )}
                          sx={{ width: "100%", paddingRight: 1 }}
                          isOptionEqualToValue={(option, value) => option.company_bank_account_id === value.company_bank_account_id}
                          onChange={(_, values) => {
                            field.onChange(values?.company_bank_account_id || null);
                            setDefaultCompanyBankAccount(values ?? undefined);
                            setCompanyBalance({
                              balance: values?.balance ?? 0,
                              currency_id: values?.currency_id ?? 0,
                              currency_code: values?.currency_code ?? ""
                            })
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("receipts.fields.companybankaccount")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.company_bank_account_id}
                              helperText={GetError(errors.company_bank_account_id?.type)}
                            />
                          )}
                          disabled={isEdit}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </form>
            <div className="mt-2">
              <DialogReceipts
                hasFiscalSignature={!isSelectedUuid}
                Details={
                  <TableApplyReceipt
                    rows={
                      rows.filter(row => {
                        if ((isSelectedUuid &&
                          (row.uuid_related !== null && row.uuid_related !== undefined && row.uuid_related !== '' && row.receipt_detail_id && row.uuid_related && !row.uuid && !row.receipt_name)) ||
                          (!isSelectedUuid &&
                            ((row.uuid_related === null || row.uuid_related === undefined || row.uuid_related === '') && row.receipt_detail_id && !row.uuid_related && !row.uuid && !row.product_service_key && !row.receipt_name)))
                          return row;
                      })
                    }
                    getValues={getValues}
                    setSelectedRows={setMySelectedRows}
                    onChangeTotals={onChangeTotals}
                    preSelectedRows={preSelectedRows}
                    setPreSelectedRows={setPreSelectedRows}
                    loadingPost={loadingPost}
                    isEdit={isEdit}
                    hasErrorPost={hasErrorPost}
                    isLoadingCurrenciesValues={isLoadingCurrenciesValues}
                    isSelectedUuid={isSelectedUuid}
                  />
                }
                Stamping={
                  <Stamping
                    receiptLocal={getValues()}
                    getValues={getValues}
                    QR={fiscalQr}
                    XML={fiscalXML}
                  />
                }
              />

            </div>
            <DialogEntity
              open={dialogConfirmApplyReceipt}
              title={<Header title={t("receipts.title-view")} />}
              maxWidth={"sm"}
              fullWidth
              content={
                <ConfirmDialog
                  getValues={getValues}
                  isEdit={isEdit}
                  isLoadingCurrenciesValues={isLoadingCurrenciesValues}
                  loadingPost={loadingPost}
                  onPost={onPost}
                  resourceFiscalSignature={resourceFiscalSignature}
                  setDialogConfirmApplyReceipt={setDialogConfirmApplyReceipt}
                  hasPositiveDifference={hasPositiveDifference}
                />
              }
            />
          </Box>
          <SnackbarComponent />
        </>
      }
    </>
  )
}
