import { useTranslation } from "react-i18next";
import { useDates } from "../../../../hooks/useDates";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { Button } from '@mui/material';
import { GridColDef } from "@mui/x-data-grid";
import { PurchaseInvoice } from "../../../../interfaces/Purchases/Invoices/purchaseInvoices";

export const usePurchaseInvoiceColumns = () => {
  const { TimeConverter } = useDates();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const { t } = useTranslation("global");

  const columnsPurchaseinvoices: GridColDef<PurchaseInvoice>[] = [
    { field: 'purchase_invoice_name', headerName: t("purchaseinvoices.fields.purchase_invoice_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params: any) {
        return (
          <Button onClick={() => {
            const stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
            window.open(`/projects/view?${stringConverted}`, '_blank');
          }}>
            {params.row.project_name}
          </Button>
        );
      }
    },
    {
      field: 'purchase_invoice_date', headerName: t("purchaseinvoices.fields.purchase_invoice_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params: any) {
        return TimeConverter(params.value);
      },
    },
    { field: 'supplier_business_name', headerName: t("purchaseinvoices.fields.supplier_business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'sales_order', headerName: t("purchaseinvoices.fields.sales_order"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'invoice_doc_status_name', headerName: t("purchaseinvoices.fields.invoice_doc_status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("purchaseinvoices.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: "left", headerAlign: "left",
      valueGetter(params: any) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params: any) {
        return params.value ? setFormatNumberFromTable(params.value ?? 0) : undefined;
      },
    },
    { field: 'uuid', headerName: t("purchaseinvoices.fields.uuid"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'user_mail_send', headerName: t("purchaseinvoices.fields.user_mail_send"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'date_mail_send', headerName: t("purchaseinvoices.fields.date_mail_send"), headerClassName: 'header-grid-table', flex: 1, type: "date",
      valueGetter(params: any) {
        return TimeConverter(params.value)?.toLocaleString();
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params: any) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params: any) {
        return TimeConverter(params.value);
      },
    },
  ];

  return {
    columnsPurchaseinvoices,
  };
};