import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../api/GenericPromises';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Company, CompanyBankAccount } from '../../../../interfaces/Security/companies';
import { Autocomplete, TextField, Box, Divider } from '@mui/material';
import { PrimaryButton } from '../../../../theme/buttons';
import { Country } from '../../../../interfaces/Commons/Locations/countries';
import { State } from '../../../../interfaces/Commons/Locations/states';
import { City } from '../../../../interfaces/Commons/Locations/cities';
import { emailRegex, rfcRegex } from '../../../../constants/Regex';
import { TaxRegime } from '../../../../interfaces/Sales/Catalogs/taxRegimes';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { useCompanyStore } from '../../../../store/AuthStore';

export const UpdateCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const { updateCompanyState } = useCompanyStore();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboCountries, setComboCountries] = useState<Country[]>([]);
  const [defaultCountry, setDefaultCountry] = useState<Country>();
  const [comboStates, setComboStates] = useState<State[]>([]);
  const [defaultState, setDefaultState] = useState<State>();
  const [comboCities, setComboCities] = useState<City[]>([]);
  const [defaultCity, setDefaultCity] = useState<City>();
  const [comboTaxRegime, setTaxRegime] = useState<TaxRegime[]>([]);
  const [defaultTaxRegime, setDefaultTaxRegime] = useState<TaxRegime>();
  const [comboCompanyBankAccount, setComboCompanyBankAccount] = useState<CompanyBankAccount[]>([]);
  const [comboCompanyBankAccountFilter, setComboCompanyBankAccountFilter] = useState<CompanyBankAccount[]>([]);
  const [defaultPrimaryBankAccount, setDefaultPrimaryBankAccount] = useState<CompanyBankAccount>()
  const [primaryBankAccountId, setPrimaryBankAccountId] = useState<number | null>()
  const [defaultSecondaryBankAccount, setDefaultSecondaryBankAccount] = useState<CompanyBankAccount>()
  const [SecondaryBankAccountId, setSecondaryBankAccountId] = useState<number | null>()
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { companyPayload } = props;
  const isEdit = !props.isEdit;

  const [isStateSelected, setIsStateSelected] = useState(false);
  const [stateId, setStateId] = useState<number | undefined>(defaultState?.state_id);
  const [newState, setNewState] = useState(false)

  const [city, setCity] = useState<City | null>()


  const getCities = (stateId: number) => {
    GenericGetResource(`/cities/bystateid/${stateId}`)
      .then((response) => {
        setComboCities(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }



  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm<Company>({
    defaultValues: {
      business_name: '',
      comercial_name: '',
      country_id: undefined,
      state_id: undefined,
      city_id: undefined,
      postal_code: '',
      street: '',
      contact_name: '',
      phone_number: '',
      email: '',
      rfc: '',
      primary_bank_account: undefined,
      secondary_bank_account: undefined,
      tax_regime_id: undefined,
    }
  });

  const onSubmit: SubmitHandler<Company> = (data) => onPut(data);

  const onPut = (data: Company) => {

    let myData = {
      business_name: data.business_name?.trim(),
      comercial_name: data.comercial_name,
      country_id: data.country_id,
      state_id: data.state_id,
      city_id: data.city_id,
      postal_code: data.postal_code,
      street: data.street,
      contact_name: data.contact_name,
      phone_number: data.phone_number,
      email: data.email,
      rfc: data.rfc?.trim(),
      tax_regime_id: data.tax_regime_id,
      primary_bank_account: data.primary_bank_account,
      secondary_bank_account: data.secondary_bank_account
    }
    setLoadingPost(true);
    GenericPutResource(`/companies/${companyPayload.company_id}`, myData)
      .then((response) => {
        updateCompanyState(response.data);
        showSnackBar(t("generic.snackbar.update"), "success")
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/countries"),
      GenericGetResource("/states"),
      GenericGetResource("/cities"),
      GenericGetResource("/taxregimes"),
      GenericGetResource("/companybankaccounts")
    ]
    Promise.all(myPromises)
      .then((responses) => {
        setComboCountries(responses[0].data.data);
        setComboStates(responses[1].data.data);
        setComboCities(responses[2].data.data);
        setTaxRegime(responses[3].data.data);

        setDataLoaded(true);
        reset({
          country_id: companyPayload.country_id ?? undefined,
          state_id: companyPayload.state_id ?? undefined,
          city_id: companyPayload.city_id ?? undefined,
          business_name: companyPayload.business_name,
          comercial_name: companyPayload.comercial_name,
          postal_code: companyPayload.postal_code,
          street: companyPayload.street,
          contact_name: companyPayload.contact_name,
          phone_number: companyPayload.phone_number,
          email: companyPayload.email,
          rfc: companyPayload.rfc,
          tax_regime_id: companyPayload.tax_regime_id,
          primary_bank_account: companyPayload.primary_bank_account,
          secondary_bank_account: companyPayload.secondary_bank_account
        });
        setDefaultCountry(responses[0].data.data.find((country: Country) => country.country_id === companyPayload.country_id));
        setDefaultState(responses[1].data.data.find((state: State) => state.state_id === companyPayload.state_id));
        setStateId(responses[1].data.data.find((state: State) => state.state_id === companyPayload.state_id).state_id)
        setDefaultCity(responses[2].data.data.find((city: City) => city.city_id === companyPayload.city_id));
        setCity(responses[2].data.data.find((city: City) => city.city_id === companyPayload.city_id));
        setDefaultTaxRegime(responses[3].data.data.find((taxRegime: TaxRegime) => taxRegime.tax_regime_id === companyPayload.tax_regime_id));

        setDefaultPrimaryBankAccount(responses[4].data.data.find((companyBankAccount: CompanyBankAccount) => companyBankAccount.company_bank_account_id === companyPayload.primary_bank_account));
        setDefaultSecondaryBankAccount(responses[4].data.data.find((companyBankAccount: CompanyBankAccount) => companyBankAccount.company_bank_account_id === companyPayload.secondary_bank_account))

        const originalComboCompanyBankAccountData = responses[4].data.data;
        setComboCompanyBankAccount(originalComboCompanyBankAccountData);

        const primaryBankAccountId = getValues("primary_bank_account");
        const secondaryBankAccountId = getValues("secondary_bank_account");
        const filteredCombo = originalComboCompanyBankAccountData.filter((companyBankAccount: CompanyBankAccount) =>
          companyBankAccount.company_bank_account_id !== primaryBankAccountId &&
          companyBankAccount.company_bank_account_id !== secondaryBankAccountId
        );
        setComboCompanyBankAccountFilter(filteredCombo);
        setPrimaryBankAccountId(primaryBankAccountId);
        setSecondaryBankAccountId(secondaryBankAccountId)

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);


  useEffect(() => {
    if (stateId !== undefined) {
      if (newState) {
        setCity(null);
        setDefaultCity(undefined);
      }
      setIsStateSelected(true);
      getCities(stateId)
      return;
    }
    setIsStateSelected(false);
  }, [stateId])

  useEffect(() => {
    let filteredCombo = comboCompanyBankAccount.filter((companyBankAccount) =>
      companyBankAccount.company_bank_account_id !== primaryBankAccountId &&
      companyBankAccount.company_bank_account_id !== SecondaryBankAccountId
    );
    setComboCompanyBankAccountFilter(filteredCombo);

  }, [primaryBankAccountId, SecondaryBankAccountId])

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <div className='d-flex justify-content-center'>
          <div className='custom-card-view w-75'>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="business_name"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.business_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.business_name}
                            helperText={GetError(errors.business_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="comercial_name"
                        control={control}
                        rules={{ required: true, maxLength: 30 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.comercial_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.comercial_name}
                            helperText={GetError(errors.comercial_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex pt-3'>
                    <div className="w-50">
                      <Controller
                        name="country_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            defaultValue={defaultCountry}
                            options={comboCountries}
                            getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                            renderOption={(props, option: Country) => (
                              <div key={option.country_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.country_code} - {option.country_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                            onChange={(_, values) => {
                              field.onChange(values?.country_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("companies.fields.country")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.country_id}
                                helperText={GetError(errors.country_id?.type)}
                                sx={{ paddingRight: 2 }}
                                disabled={isEdit}
                              />
                            )}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="state_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            defaultValue={defaultState}
                            options={comboStates}
                            getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                            renderOption={(props, option: State) => (
                              <div key={option.state_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.state_code} - {option.state_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                            onChange={(_, values) => {
                              setValue("city_id", undefined);
                              field.onChange(values?.state_id || null);
                              setNewState(true);
                              setStateId(values?.state_id);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("companies.fields.state")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.state_id}
                                helperText={GetError(errors.state_id?.type)}
                                disabled={isEdit}
                              />
                            )}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex pt-3'>
                    <div className='w-50'>

                      {!isStateSelected &&
                        <Controller
                          name="city_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              options={comboCities}
                              getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                              renderOption={(props, option: City) => (
                                <div key={option.city_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.city_code} - {option.city_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                              onChange={(_, values) => {
                                field.onChange(values?.city_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("customerlocations.fields.city_name")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.city_id}
                                  helperText={GetError(errors.city_id?.type)}
                                  sx={{ paddingRight: 2 }}

                                />
                              )}
                              disabled
                            />
                          }
                        />
                      }
                      {isStateSelected &&
                        <Controller
                          name="city_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              options={comboCities}
                              value={city}
                              getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                              renderOption={(props, option: City) => (
                                <div key={option.city_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.city_code} - {option.city_name}
                                  </Box>
                                </div>
                              )}

                              isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                              onChange={(_, values) => {
                                setCity(values);
                                field.onChange(values?.city_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("customerlocations.fields.city_name")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.city_id}
                                  helperText={GetError(errors.city_id?.type)}
                                  sx={{ paddingRight: 2 }}
                                  disabled={isEdit}
                                />
                              )}

                            />
                          }
                        />
                      }
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="street"
                        control={control}
                        rules={{ required: true, maxLength: 60 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.street")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.street}
                            helperText={GetError(errors.street?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex pt-3'>
                    <div className='w-50'>
                      <Controller
                        name="postal_code"
                        control={control}
                        rules={{ maxLength: 5 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.postal_code")}`}
                            ref={field.ref}
                            type="number"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                            }}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.postal_code}
                            helperText={GetError(errors.postal_code?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="tax_regime_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            defaultValue={defaultTaxRegime}
                            options={comboTaxRegime}
                            getOptionLabel={(option) => `${option.tax_regime_code} - ${option.description}`}
                            renderOption={(props, option: TaxRegime) => (
                              <div key={option.tax_regime_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.tax_regime_code} - {option.description}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.tax_regime_id === value.tax_regime_id}
                            onChange={(_, values) => {
                              field.onChange(values?.tax_regime_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("taxregimes.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.state_id}
                                helperText={GetError(errors.state_id?.type)}
                                disabled={isEdit}
                              />
                            )}
                          />
                        }
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className='d-flex pt-3'>
                    <div className='w-50'>
                      <Controller
                        name="contact_name"
                        control={control}
                        rules={{ maxLength: 50 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.contact_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.contact_name}
                            helperText={GetError(errors.contact_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="phone_number"
                        control={control}
                        rules={{ maxLength: 20 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.phone_number")}`}
                            ref={field.ref}
                            value={field.value}
                            type="number"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", },
                            }}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.phone_number}
                            helperText={GetError(errors.phone_number?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className='d-flex pt-3'>
                    <div className='w-50'>
                      <Controller
                        name="email"
                        control={control}
                        rules={{ maxLength: 50, pattern: emailRegex }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.email")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.email}
                            helperText={GetError(errors.email?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="rfc"
                        control={control}
                        rules={{ pattern: rfcRegex }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("companies.fields.rfc")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.rfc}
                            helperText={GetError(errors.rfc?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex pt-3'>
                    <div className="w-50">
                      <Controller
                        name="primary_bank_account"
                        control={control}
                        rules={{}}
                        render={({ field }) =>
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            defaultValue={defaultPrimaryBankAccount}
                            options={comboCompanyBankAccountFilter}
                            getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}`}
                            renderOption={(props, option: CompanyBankAccount) => (
                              <div key={option.company_bank_account_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.account_alias} - {option.bank_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.company_bank_account_id === value.company_bank_account_id}
                            onChange={(_, values) => {
                              setPrimaryBankAccountId(values?.company_bank_account_id || null)
                              field.onChange(values?.company_bank_account_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("companies.fields.primary_bank_account")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.primary_bank_account}
                                helperText={GetError(errors.primary_bank_account?.type)}
                                sx={{ paddingRight: 2 }}
                                disabled={isEdit}
                              />
                            )}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="secondary_bank_account"
                        control={control}
                        rules={{}}
                        render={({ field }) =>
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            defaultValue={defaultSecondaryBankAccount}
                            options={comboCompanyBankAccountFilter}
                            getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}`}
                            renderOption={(props, option: CompanyBankAccount) => (
                              <div key={option.company_bank_account_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.account_alias} - {option.bank_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.company_bank_account_id === value.company_bank_account_id}
                            onChange={(_, values) => {
                              setSecondaryBankAccountId(values?.company_bank_account_id || null)
                              field.onChange(values?.company_bank_account_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("companies.fields.secondary_bank_account")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.secondary_bank_account}
                                helperText={GetError(errors.secondary_bank_account?.type)}
                                disabled={isEdit}
                              />
                            )}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!isEdit &&
                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                </div>
              }

            </form>

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}