import { Autocomplete, Box, Switch, TextField, Typography } from "@mui/material";
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { Country } from "../../../../../interfaces/Commons/Locations/countries";
import { State } from "../../../../../interfaces/Commons/Locations/states";
import { City } from "../../../../../interfaces/Commons/Locations/cities";
import { TaxRegime } from "../../../../../interfaces/Sales/Catalogs/taxRegimes";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { emailRegex, rfcRegex } from "../../../../../constants/Regex";
import { CompanyLocation } from "../../../../../interfaces/Security/companies";
import { Divider } from "../../../../../components/Commons/Divider";

type AddGeneralProps = {
    control: Control<CompanyLocation, any>;
    errors: FieldErrors<CompanyLocation>;
    comboCountries: Country[];
    comboStates: State[];
    comboCities: City[];
    getCities: Function;
    setValue: UseFormSetValue<CompanyLocation>;
    comboTaxRegimes: TaxRegime[];
    setIsStateSelected: Dispatch<SetStateAction<boolean>>;
    setStateId: Dispatch<SetStateAction<number | undefined>>;
    setCity: Dispatch<SetStateAction<City | null>>
    isStateSelected: boolean;
    stateId: number | undefined;
    city: City | null;
    isMexican: boolean;
    setDefaultTaxRegime: Dispatch<SetStateAction<TaxRegime | undefined>>;
    setDefaultCountry: Dispatch<SetStateAction<Country | undefined>>;
    defaultCountry: Country | undefined;
    defaultTaxRegime: TaxRegime | undefined;
    getValues: UseFormGetValues<CompanyLocation>;
    isEdit?: boolean;
    GetError: (errorType: any) => string
}

export const AddGeneralCompanyLocation = ({
    getValues,
    setDefaultTaxRegime,
    setDefaultCountry,
    defaultTaxRegime,
    defaultCountry,
    isMexican,
    isStateSelected,
    city,
    setCity,
    setStateId,
    setIsStateSelected,
    stateId,
    setValue,
    control,
    errors,
    comboCountries,
    comboStates,
    comboCities,
    getCities,
    comboTaxRegimes,
    isEdit = false,
    GetError,
}: AddGeneralProps) => {
    const [t] = useTranslation("global");

    const changeCity = async (stateId: number | undefined) => {
        if (stateId !== undefined) {
            await getCities(stateId)
            setIsStateSelected(true);
        }
        else {
            setIsStateSelected(false);
        }
    }

    useEffect(() => {
        changeCity(stateId);
    }, [stateId])

    useEffect(() => {
        if (stateId !== undefined) {
            setIsStateSelected(true);
            getCities(stateId)
            return;
        }
        setIsStateSelected(false);
    }, [stateId])

    useEffect(() => {
        changeCity(stateId);
    }, [])

    return (
        <>
            <div className="w-100">
                <div className='d-flex'>
                    <div className='w-100 d-flex'>
                        <div className='w-50'>
                            <Controller
                                name="country_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <Autocomplete
                                        ref={field.ref}
                                        size="small"
                                        options={comboCountries}
                                        defaultValue={defaultCountry}
                                        disabled={isEdit}
                                        getOptionLabel={(option) => `${option.country_code} - ${option.country_name}`}
                                        renderOption={(props, option: Country) => (
                                            <div key={option.country_id}>
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.country_code} - {option.country_name}
                                                </Box>
                                            </div>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.country_id === value.country_id}
                                        onChange={(_, values) => {
                                            field.onChange(values?.country_id || null);
                                            setDefaultCountry(values ?? undefined);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${t("companylocations.fields.country_name")}`}
                                                variant="filled"
                                                value={field.value}
                                                error={!!errors.country_id}
                                                helperText={GetError(errors.country_id?.type)}
                                                sx={{ paddingRight: 2 }}
                                            />
                                        )}
                                    />
                                }
                            />
                        </div>

                        <div className='w-50'>
                            <Controller
                                name="state_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <Autocomplete
                                        ref={field.ref}
                                        size="small"
                                        options={comboStates}
                                        disabled={isEdit}
                                        defaultValue={comboStates.find((state) => state.state_id === stateId)}
                                        getOptionLabel={(option) => `${option.state_code} - ${option.state_name}`}
                                        renderOption={(props, option: State) => (
                                            <div key={option.state_id}>
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.state_code} - {option.state_name}
                                                </Box>
                                            </div>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.state_id === value.state_id}
                                        onChange={(_, values) => {
                                            setValue("city_id", undefined);
                                            field.onChange(values?.state_id || null)
                                            setStateId(values?.state_id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${t("companylocations.fields.state_name")}`}
                                                variant="filled"
                                                value={field.value}
                                                error={!!errors.state_id}
                                                helperText={GetError(errors.state_id?.type)}
                                            />
                                        )}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex w-100 mt-3'>
                    <div className='w-50'>
                        {!isStateSelected &&
                            <Controller
                                name="city_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <Autocomplete
                                        ref={field.ref}
                                        size="small"
                                        options={comboCities}
                                        defaultValue={city}
                                        getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                                        renderOption={(props, option: City) => (
                                            <div key={option.city_id}>
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.city_code} - {option.city_name}
                                                </Box>
                                            </div>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                                        onChange={(_, values) => {
                                            field.onChange(values?.city_id || null)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${t("cities.title")}`}
                                                variant="filled"
                                                value={field.value}
                                                error={!!errors.city_id}
                                                helperText={GetError(errors.city_id?.type)}
                                                sx={{ paddingRight: 2 }}

                                            />
                                        )}
                                        disabled
                                    />
                                }
                            />
                        }
                        {isStateSelected &&
                            <Controller
                                name="city_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <Autocomplete
                                        ref={field.ref}
                                        size="small"
                                        options={comboCities}
                                        disabled={isEdit}
                                        defaultValue={comboCities.find((cities) => cities.city_id === city?.city_id)}
                                        value={city}
                                        getOptionLabel={(option) => `${option.city_code} - ${option.city_name}`}
                                        renderOption={(props, option: City) => (
                                            <div key={option.city_id}>
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.city_code} - {option.city_name}
                                                </Box>
                                            </div>
                                        )}

                                        isOptionEqualToValue={(option, value) => option.city_id === value.city_id}
                                        onChange={(_, values) => {
                                            setCity(values);
                                            field.onChange(values?.city_id || null)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${t("cities.title")}`}
                                                variant="filled"
                                                value={field.value}
                                                error={!!errors.city_id}
                                                helperText={GetError(errors.city_id?.type)}
                                                sx={{ paddingRight: 2 }}
                                            />
                                        )}
                                    />
                                }
                            />
                        }
                    </div>

                    <div className='w-50'>
                        <Controller
                            name="street"
                            control={control}
                            rules={{ required: true, maxLength: 60 }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    label={`${t("companylocations.fields.street_name")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    disabled={isEdit}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.street}
                                    helperText={GetError(errors.street?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                />
                            }
                        />
                    </div>
                </div>

                <div className='d-flex mt-3'>
                    <div className='w-50'>
                        <Controller
                            name="postal_code"
                            control={control}
                            rules={{ required: true, maxLength: 5 }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    disabled={isEdit}
                                    label={`${t("companylocations.fields.postal_code")}`}
                                    ref={field.ref}
                                    type="number"
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                        "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                                    }}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.postal_code}
                                    helperText={GetError(errors.postal_code?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                />
                            }
                        />
                    </div>
                    <div className='w-50'>
                        <Controller
                            name="business_name"
                            control={control}
                            rules={{ maxLength: 50, required: true }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    disabled={isEdit}
                                    label={`${t("customerlocations.fields.business_name")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.business_name}
                                    helperText={GetError(errors.business_name?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                />
                            }
                        />

                    </div>

                </div>

                <div className="d-flex mt-3">
                    <div className="w-50">
                        <Controller
                            name="company_rfc"
                            control={control}
                            rules={{
                                required: true, maxLength: 13,
                                pattern: (isMexican) ? rfcRegex : undefined
                            }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    label={`${t("companies.fields.rfc")}`}
                                    ref={field.ref}
                                    disabled={isEdit}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.company_rfc}
                                    helperText={GetError(errors.company_rfc?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{ paddingRight: 2 }}
                                />
                            }
                        />
                    </div>
                    <div className='w-50 mb-2'>
                        <Controller
                            name="tax_regime_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) =>
                                <Autocomplete
                                    ref={field.ref}
                                    size="small"
                                    options={comboTaxRegimes}
                                    disabled={isEdit}
                                    defaultValue={defaultTaxRegime}
                                    getOptionLabel={(option) => `${option.tax_regime_code} - ${option.description}`}
                                    renderOption={(props, option: TaxRegime) => (
                                        <div key={option.tax_regime_id}>
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.tax_regime_code} - {option.description}
                                            </Box>
                                        </div>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.tax_regime_id === value.tax_regime_id}
                                    onChange={(_, values) => {
                                        field.onChange(values?.tax_regime_id || null);
                                        setDefaultTaxRegime(values ?? undefined);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("taxregimes.title-view")}`}
                                            variant="filled"
                                            value={field.value}
                                            error={!!errors.tax_regime_id}
                                            helperText={GetError(errors.tax_regime_id?.type)}
                                        />
                                    )}
                                />
                            }
                        />
                    </div>
                </div>

                <Divider />

                <div className='d-flex w-100 mt-2'>
                    <div className='w-50'>
                        <Controller
                            name="contact_name"
                            control={control}
                            rules={{ maxLength: 50 }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    label={`${t("companylocations.fields.contact_name")}`}
                                    ref={field.ref}
                                    disabled={isEdit}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.contact_name}
                                    helperText={GetError(errors.contact_name?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{ paddingRight: 2 }}
                                />
                            }
                        />
                    </div>
                    <div className='w-50'>
                        <Controller
                            name="phone_number"
                            control={control}
                            rules={{ maxLength: 20 }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    label={`${t("companylocations.fields.phone_number")}`}
                                    ref={field.ref}
                                    value={field.value}
                                    disabled={isEdit}
                                    type="number"
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                        "& input[type=number]": { MozAppearance: "textfield", },
                                    }}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.phone_number}
                                    helperText={GetError(errors.phone_number?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                />
                            }
                        />
                    </div>
                </div>

                <div className='d-flex mt-3'>
                    <div className='w-50'>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ maxLength: 50, pattern: emailRegex }}
                            render={({ field }) =>
                                <TextField
                                    variant="filled"
                                    label={`${t("companylocations.fields.email")}`}
                                    ref={field.ref}
                                    disabled={isEdit}
                                    value={field.value}
                                    onChange={(event) => { field.onChange(event.target.value) }}
                                    error={!!errors.email}
                                    helperText={GetError(errors.email?.type)}
                                    size="small"
                                    style={{ width: "100%" }}
                                    sx={{ paddingRight: 2 }}
                                />
                            }
                        />
                    </div>

                </div>

                <div className='w-50 mt-3'>
                    <Controller
                        name="main_location"
                        control={control}
                        render={({ field }) =>
                            <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2, justifyContent: "space-between" }}>
                                <Typography sx={{ ml: 2 }}>{t("companylocations.fields.main_location")}</Typography>
                                <Switch ref={field.ref} onChange={(event) => {
                                    field.onChange(event.target.checked)
                                }}
                                    defaultChecked={getValues("main_location")}
                                    value={field.value}
                                    disabled={isEdit}
                                />
                            </Box>
                        }
                    />
                </div>
                <div className='w-50'>
                    <Controller
                        name="fiscal_address"
                        control={control}
                        render={({ field }) =>
                            <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2, justifyContent: "space-between" }}>
                                <Typography sx={{ ml: 2 }}>{t("customerlocations.fields.fiscal_address")}</Typography>
                                <Switch ref={field.ref}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked)
                                    }}
                                    defaultChecked={getValues("fiscal_address")}
                                    value={field.value}
                                    disabled={isEdit}
                                />
                            </Box>
                        }
                    />
                </div>
            </div>
        </>
    )
}