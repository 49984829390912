import { useEffect, useState } from 'react'
import { Header } from '../../../components/Header'
import GenericPromises from '../../../api/GenericPromises';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InvoiceDocumentType } from '../../../interfaces/Sales/Invoices/salesinvoices';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { PurchaseInvoice } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { Supplier } from '../../../interfaces/Purchases/Catalogs/suppliers';
import { PurchaseInvoicesHeader } from './invoiceHeader';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { useCompanyStore } from '../../../store/AuthStore';
import { useDates } from '../../../hooks/useDates';
import { useBase64 } from '../../../hooks/useBase64';

export const AddPurchaseInvoices = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { GetParamsFromBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericPostDocument } = GenericPromises();
  const { companyState } = useCompanyStore();
  const { utf8ToBase64 } = useBase64();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isSelectedSupplierTextfield, setIsSelectedSupplierTextfield] = useState(false);
  const [myConsecutive, setMyConsecutive] = useState("");
  const [projectId, setProjectId] = useState("");
  const [comboInvoiceDocumentTypes, setComboInvoiceDocumentTypes] = useState<InvoiceDocumentType[]>([]);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
  } = useForm<PurchaseInvoice>({
    defaultValues: { 
      sales_order: ""
    }
  });
  const onSubmit: SubmitHandler<PurchaseInvoice> = (data) => onPost(data);

  const onPost = async (data: PurchaseInvoice) => {
    try {

      if (data.supplier_id) {
        let myData = {
          supplier_id: data.supplier_id,
          sales_order: data.sales_order ?? null,
          invoice_doc_type_id: data.invoice_doc_type_id ?? null,
          expiration_date: data.expiration_date ?? null,
          supplier_sale_invoice_date: TimeConverter(data.supplier_sale_invoice_date?.toISOString()) ?? null,
          purchase_invoice_date: TimeConverter(data.purchase_invoice_date?.toISOString()) ?? TimeConverter(new Date().toISOString()),
          project_id: (projectId === "") ? null : projectId,
        }
        GenericPostDocument("/purchaseinvoices/add", myData, true)
          .then(async (response) => {
            await navigate("/purchaseinvoices/view", { state: { row: response.data } });
          })
          .catch((error) => {
            if (error.response.data.message) {
              showSnackBar(t(`${error.response.data.message}`), "error");
            }
            else {
              showSnackBar(error.message, "error");
            }
            setIsSelectedSupplierTextfield(false);
          });
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  };

  const GetInitialData = async (): Promise<{
    suppliers: Supplier[];
    invoiceDocumentTypes: InvoiceDocumentType[];
  }> => {
    try {
      const [suppliersResponse, invoiceDocumentTypesResponse] = await Promise.all([
        GenericGetResource("/suppliers"),
        GenericGetResource(`/invoicedocumenttypes?filter=${utf8ToBase64("is_type_for_sale=False")}`),
      ]);

      return {
        suppliers: suppliersResponse.data.data ?? [],
        invoiceDocumentTypes: invoiceDocumentTypesResponse.data.data ?? [],
      };
    } catch (error: unknown) {
      if (error instanceof Error) {
        showSnackBar(error.message, "error");
      } else {
        showSnackBar("An unexpected error occurred", "error");
      }
      return {
        suppliers: [],
        invoiceDocumentTypes: [],
      };
    }
  };

  const GetConsecutiveData = async (consecutiveId: number): Promise<string | undefined> => {
    try {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutiveId}`);
      return response.data.data[0].Consecutive;
    } catch (error: any) {
      showSnackBar(error.message, "error");
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      if (location.search.length > 0 && location.state === null) {
        const myParams = GetParamsFromBase64();
        const project = myParams.get("project_id");
        setProjectId(project ?? "");
      }
      document.title = `${companyState?.comercial_name} - ${t("purchaseinvoices.title")}`;

      try {
        const { suppliers, invoiceDocumentTypes } = await GetInitialData();
        if (suppliers) {
          setComboSuppliers(suppliers);
        }
        if (invoiceDocumentTypes.length > 0) {
          setComboInvoiceDocumentTypes(invoiceDocumentTypes);
          reset({
            invoice_doc_type_id: invoiceDocumentTypes[0].invoice_doc_type_id,
          }, { keepValues: true });
        }

        if (invoiceDocumentTypes) {
          const consecutive = await GetConsecutiveData(invoiceDocumentTypes[0].consecutive_id ?? 0);
          setMyConsecutive(consecutive ?? "");
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          showSnackBar(error.message, "error");
        } else {
          showSnackBar("An unexpected error occurred", "error");
        }
      }
      finally {
        setDataLoaded(true);
      }
    };

    initializeData();
  }, []);

  useEffect(() => {
    if (myConsecutive !== "") {
      reset({
        purchase_invoice_name: myConsecutive,
      }, { keepValues: true });
    }
  }, [myConsecutive]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("purchaseinvoices.title-view")} size='md' />

          <form onSubmit={handleSubmit(onSubmit)} className='custom-card-view'>

            <Grid container display={'flex'} flexDirection={'column'}>
              <Grid item>
                <PurchaseInvoicesHeader
                  control={control}
                  errors={errors}
                  isSupplierSelected={false}
                  onPost={onPost}
                  getValues={getValues}
                  comboSuppliers={comboSuppliers}
                  comboInvoiceDocumentTypes={comboInvoiceDocumentTypes}
                  setMyConsecutive={setMyConsecutive}
                  myConsecutive={myConsecutive}
                  isSelectedSupplierTextfield={isSelectedSupplierTextfield}
                  setIsSelectedSupplierTextfield={setIsSelectedSupplierTextfield}
                />
              </Grid>
              <Grid item>
              </Grid>
            </Grid>

          </form>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
